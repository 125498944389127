export const NoSearchDropdownStyles = {
    control: (styles: any) => ({
      ...styles,
      display: 'none',
    }),
    menu: (base: any) => ({
      ...base,
      position: 'relative',
      marginBottom: '5px',
      marginTop: '0px',
      boxShadow: 'none',
      borderRadius: '12px',
    }),
    indicatorSeparator: () => ({ display: "none" }),
    menuList: (base: any) => ({
      ...base,
      scrollbarColor: '#2DB49E #fff',
      scrollbarWidth: 'thin',
      "::-webkit-scrollbar": {
        width: "2px"
      },
      "::-webkit-scrollbar-track": {
        background: "#fff"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#2DB49E",
        minHeight: '40px',
        borderRadius: '20px'

      }
    }),
    option: (styles: any, { data, isDisable, isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: "#FFFFFF",
        cursor: "pointer",
        color: "#162930",
        fontWeight: isSelected ? '500' : '400',
        "&:hover": {
          backgroundColor: "#fff",
        },
      };
    },
  };
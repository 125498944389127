import { useNavigate } from "react-router-dom";
import SideBar from "../SideBar/SideBar";
import styles from "./Kyc.module.scss";
import { ReactComponent as GreenLeftArrow } from "../svg/greenLeftArrow.svg";
import { useEffect, useRef, useState } from "react";
import { useUserContext } from "../../Context/UserContext";
import { KycInfoSubmit, KycInfoSubmitPatch } from "../../Service/KycInfoSubmit";
import {
  validateBankAccountNumber,
  validateCacDocumentNumber,
  validateBusinessRcNumber,
  validateBnNumber,
} from "../../Utility/ValidationsWithJoi";
import {
  BankNameOptions,
  BusinessStatusOptions,
  RegistrationTypeOptions,
} from "../../Utility/DropdownOptions";
import { api } from "../../Service/AuthService";
import SearchableDropdown from "./SearchableDropdown";
import AnimatedDropdown from "./AnimatedDropdown";
import Dropzone, { FileWithPath } from "react-dropzone";
import { ReactComponent as DropDownIcon } from "../svg/drapDropIcon.svg";
import {
  getFormData,
  getImageUploadParams,
  getSignedUrl,
  uploadImageToS3,
} from "../../Utility/imageUtils";

function BusinessInfoPgTwo() {
  const { userProfile, kycDetails, setKycDetails } = useUserContext();

  const navigate = useNavigate();
  const [bankAccountName, setBankAccountName] = useState(false);
  const [bankNameList, setBankNameList] = useState();
  const [showError, setShowError] = useState(false);
  const [showCertifcateError, setShowCertificateError] = useState(false);
  const [showCacError, setCacShowError] = useState(false);
  const rcInputRef = useRef<any>({});
  const [utilityUploadLoader, setUtilityUploadLoader] = useState(false);
  const [url, setUrl] = useState(false);
  const [certificateUrl, setCertificateUrl] = useState(false);
  const [certificateLoader, setCertificateLoader] = useState(false);
  const [cacStatusLoader, setCacStatusLoader] = useState(false);
  const [cacStatusUrl, setCacStatusUrl] = useState(false);

  const getBusinessStatus = (status: string) => {
    if (status === "Registered") {
      return "REGISTERED";
    }

    return "UNREGISTERED";
  };

  const inverseBusinessStatus = (status: string) => {
    if (status === "REGISTERED") {
      return "Registered";
    }

    return "Not Registered";
  };

  const [inputValues, setInputValues] = useState({
    //  new kyc
    businessStatus: kycDetails?.business_status
      ? inverseBusinessStatus(kycDetails?.business_status)
      : "",
    businessType: kycDetails?.business_type || "",
    bnNumber: kycDetails?.bn_number || "",
    bankName: kycDetails?.bank_name || "",
    bankAccountNumber: kycDetails?.bank_account_number || "",
    bankAccountName: kycDetails?.bank_account_name || "",
    kyc_utility_document: kycDetails?.utility_bill_document_url || "",
    bankCode: kycDetails?.bank_code || "",
    cacDocumentNumber: kycDetails?.cac_document_number || "",
    businessRcNumber: kycDetails?.business_rc_number || "",
    kyc_certificate_document:
      kycDetails?.certificate_of_registration_document_url || "",
    kyc_cac_status_report_document: kycDetails?.cac_document_url || "",
    //  new kyc
  });
  const rejectedReason = kycDetails?.rejected_reason;
  const [errors, setErrors] = useState({
    businessRcNumber: rejectedReason?.business_rc_number || "",
    businessStatus: rejectedReason?.business_status || "",
    businessType: rejectedReason?.business_type || "",
    cacDocumentNumber: rejectedReason?.cac_document_number || "",
    bankName: rejectedReason?.bank_name || "",
    bnNumber: rejectedReason?.bn_number || "",
    bankAccountNumber: rejectedReason?.bank_account_number || "",
    bankAccountName: rejectedReason?.bank_account_name || "",
    utilityBillDocumentUrl: rejectedReason?.utility_bill_document_url || "",
    kyc_certificate_document:
      rejectedReason?.certificate_of_registration_document_url || "",
    kyc_cac_status_report_document: rejectedReason?.cac_document_url || "",
    generalErr: "",
  });

  const handleInput = (e: { target: { name: string; value: any } }) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };
  const setDropDownValues = (e: any, name: string) => {
    if (name === "businessStatus") {
      setInputValues({
        ...inputValues,
        [name]: e.value,
      });
    }

    setInputValues(() => ({
      businessStatus: inputValues.businessStatus,
      businessType: "",
      bnNumber: "",
      bankName: inputValues.bankName,
      bankAccountNumber: inputValues.bankAccountNumber,
      bankAccountName: inputValues.bankAccountName,
      kyc_utility_document: "",
      bankCode: inputValues.bankCode,
      cacDocumentNumber: "",
      businessRcNumber: "",
      kyc_certificate_document: "",
      kyc_cac_status_report_document: "",
    }));

    setInputValues({
      ...inputValues,
      [name]: e.value,
    });

    if (name === "bankName") {
      setInputValues({
        ...inputValues,
        [name]: e.label,
        bankCode: e.value,
      });
    }

    if (name === "businessStatus") {
      if (e.value === "Not Registered") {
        setInputValues(() => ({
          businessStatus: e.value,
          businessType: "",
          bnNumber: "",
          bankName: inputValues.bankName,
          bankAccountNumber: inputValues.bankAccountNumber,
          bankAccountName: inputValues.bankAccountName,
          kyc_utility_document: "",
          bankCode: inputValues.bankCode,
          cacDocumentNumber: "",
          businessRcNumber: "",
          kyc_certificate_document: "",
          kyc_cac_status_report_document: "",
        }));

        setErrors({
          ...errors,
          bnNumber: "",
          businessType: "",
          cacDocumentNumber: "",
          businessRcNumber: "",
        });
      }
    }

    errors[name] = "";
    setBankAccountName(!bankAccountName);
  };
  const formSubmit = async (e: any) => {
    e.preventDefault();

    const payload: {
      business_status: string;
      business_type?: string;
      business_rc_number?: string;
      cac_document_number?: string;
      bank_name: string;
      bank_code: string;
      bank_account_number: string;
      utility_bill_document_url: string;
      bn_number?: number;
      certificate_of_registration_document_url?: string;
      cac_document_url?: string;
    } = {
      business_status: getBusinessStatus(inputValues.businessStatus),
      cac_document_number: inputValues?.cacDocumentNumber,
      bank_name: inputValues?.bankName,
      bank_code: inputValues?.bankCode,
      bank_account_number: inputValues?.bankAccountNumber,
      utility_bill_document_url: inputValues?.kyc_utility_document,
    };

    if (inputValues.businessType === "Sole Proprietorship") {
      payload.bn_number = inputValues.bnNumber;
      payload.certificate_of_registration_document_url =
        inputValues.kyc_certificate_document;
      delete kycDetails.business_rc_number;
      delete payload.business_rc_number;
      delete payload.cac_document_url;
      delete kycDetails.cac_document_url;
    }

    if (inputValues.businessType === "Limited Liability Company") {
      payload.business_rc_number = inputValues?.businessRcNumber;
      payload.cac_document_number = inputValues?.cacDocumentNumber;
      payload.cac_document_url = inputValues?.kyc_cac_status_report_document;
      delete payload.certificate_of_registration_document_url;
      delete kycDetails.certificate_of_registration_document_url;
      delete payload.bn_number;
      delete kycDetails.bn_number;
    }

    if (
      inputValues.businessStatus &&
      inputValues.businessStatus === "Registered"
    ) {
      payload.business_type = inputValues.businessType;
    }

    if (
      inputValues.businessStatus === "Not Registered" ||
      inputValues.businessStatus === ""
    ) {
      delete payload.bn_number;
      delete kycDetails.bn_number;
      delete payload.certificate_of_registration_document_url;
      delete kycDetails.certificate_of_registration_document_url;
      delete payload.business_type;
      delete kycDetails.business_type;
      delete kycDetails.certificate_of_registration_document_url;
    }

    if (
      kycDetails.business_status === "UNREGISTERED" ||
      payload.business_status === "UNREGISTERED"
    ) {
      delete kycDetails.cac_document_url;
      delete kycDetails.business_rc_number;
      delete kycDetails.business_type;
      delete payload.business_type;
      delete payload.cac_document_url;
      delete payload.business_rc_number;
    }

    if (
      kycDetails.business_status === "REGISTERED" ||
      kycDetails.business_type === "Sole Proprietorship"
    ) {
      delete kycDetails.cac_document_url;
      delete kycDetails.business_rc_number;
    }

    const finalPayload = {};
    Object.assign(finalPayload, kycDetails, payload);

    let responseData: any = null;
    if (userProfile?.kyc_id) {
      responseData = await KycInfoSubmitPatch(finalPayload);
    } else {
      responseData = await KycInfoSubmit(finalPayload);
      if (responseData.status === 200) {
        const resObj = responseData?.data?.data;
        const kycObj = resObj?.kyc;
        userProfile.kyc_id = kycObj?.id;
      }
    }
    if (responseData.status === 200) {
      const resObj = responseData?.data?.data;
      const kycObj = resObj?.kyc;
      setKycDetails(kycObj);
      navigate("/kyc");
    } else {
      const errData = responseData?.data?.errors;
      let generalErrorMsg = "";
      if (Object.keys(errData)?.length === 0) {
        generalErrorMsg = responseData?.data?.message;
      }

      if (errData?.utility_bill_document_url) {
        setShowError(true);
      }

      if (errData?.certificate_of_registration_document_url) {
        setShowCertificateError(true);
      }

      if (errData?.cac_document_url) {
        setCacShowError(true);
      }

      setErrors({
        businessRcNumber: errData?.business_rc_number,
        businessType: errData?.business_type,
        cacDocumentNumber: errData?.cac_document_number,
        businessStatus: errData?.business_status,
        bnNumber: errData?.bn_number,
        bankName: errData?.bank_name,
        bankAccountNumber: errData?.bank_account_number,
        bankAccountName: errData?.bank_account_name,
        utilityBillDocumentUrl: errData?.utility_bill_document_url,
        kyc_certificate_document:
          errData?.certificate_of_registration_document_url,
        kyc_cac_status_report_document: errData?.cac_document_url,
        generalErr: generalErrorMsg,
      });
    }
  };
  const setS3Url = (key: string, value: string) => {
    setInputValues({ ...inputValues, [key]: value });
  };
  const validateOptions = {
    abortEarly: false,
    converse: true,
    stripUnknown: true,
  };
  const validationField = async (schema: any, value: any, field: any) => {
    const err = JSON.parse(JSON.stringify(errors));
    const res = await schema.validate(value, validateOptions);
    let errorsList: any = {};
    if (res.error) {
      res.error.details.forEach((error: any) => {
        errorsList[field] = error.message;
      });
      setErrors({
        ...errors,
        ...errorsList,
      });
    } else {
      delete err[field];
      setErrors(err);
    }
  };
  const validateInput = (e: { target: { name: string; value: any } }) => {
    if (e.target.name === "bankAccountNumber") {
      validationField(
        validateBankAccountNumber,
        e.target.value,
        "bankAccountNumber"
      );
      if (!errors?.bankAccountNumber) {
        setBankAccountName(!bankAccountName);
      }
    } else if (e.target.name === "cacDocumentNumber") {
      if (inputValues?.cacDocumentNumber === "") {
        inputValues.businessRcNumber = "";
        errors.businessRcNumber = "";
        rcInputRef.current.disabled = true;
      }
      validationField(
        validateCacDocumentNumber,
        e.target.value,
        "cacDocumentNumber"
      );
    } else if (e.target.name === "businessRcNumber") {
      validationField(
        validateBusinessRcNumber,
        e.target.value,
        "businessRcNumber"
      );
    } else if (e.target.name === "bnNumber") {
      validationField(validateBnNumber, e.target.value, "bnNumber");
    }
  };
  const getBankAccountName = () => {
    if (
      inputValues?.bankAccountNumber?.length === 10 &&
      inputValues?.bankName
    ) {
      inputValues.bankAccountName = "";
      const payload = {
        account_number:
          kycDetails?.bank_account_number || inputValues?.bankAccountNumber,
        bank_code: kycDetails?.bank_code || inputValues?.bankCode,
      };
      api
        .post("/bank-account/verify", payload)
        .then((response) => {
          const resObj = response?.data?.data;
          const accountName = resObj?.account_name;
          errors.generalErr = "";
          setInputValues({
            ...inputValues,
            bankAccountName: accountName,
          });
        })
        .catch((error) => {
          setErrors({
            ...errors,
            bankAccountNumber:
              error?.response?.data?.errors.account_number ||
              error?.response?.data?.message ||
              "",
          });
        });
    }
  };

  const getBankNameOptions = async () => {
    const bankName: any = await BankNameOptions();
    setBankNameList(bankName);
  };

  async function onFilesDrop<T extends FileWithPath>(acceptedFiles: T[]) {
    if (acceptedFiles.length === 0) {
      setErrors({
        ...errors,
        utilityBillDocumentUrl: "File type allowed is .png, .jpeg, .pdf",
      });

      setShowError(true);

      return;
    }

    if (acceptedFiles.length >= 1) {
      if (acceptedFiles[0].size / 1024 > 2000) {
        setErrors({
          ...errors,
          utilityBillDocumentUrl: "File should not be more than 2MB",
        });

        setShowError(true);

        return;
      }
    }
    const name = getImageUploadParams("kyc_utility_document", acceptedFiles[0]);

    const res = await getSignedUrl("kyc_utility_document", name);

    setUtilityUploadLoader(true);
    if (res) {
      const responseData = res?.data?.data;
      const imgData = responseData?.files?.[name];
      const postUrl = imgData?.postUrl;
      const signedURLData = imgData?.postFields;
      const s3Url = imgData?.s3Url;
      const uploaded = uploadImageToS3(
        postUrl,
        getFormData(signedURLData, acceptedFiles[0])
      );
      uploaded.then(() => {
        setS3Url("kyc_utility_document", s3Url);
        const previewUrl = imgData?.previewUrl;
        setUrl(previewUrl);
        setShowError(false);
      });
      uploaded.finally(() => {
        setUtilityUploadLoader(false);
      });
    }
  }

  async function onCertificateFilesDrop<T extends FileWithPath>(
    acceptedFiles: T[]
  ) {
    if (acceptedFiles.length === 0) {
      setErrors({
        ...errors,
        kyc_certificate_document: "File type allowed is .png, .jpeg, .pdf",
      });

      setShowCertificateError(true);

      return;
    }

    if (acceptedFiles.length >= 1) {
      if (acceptedFiles[0].size / 1024 > 2000) {
        setErrors({
          ...errors,
          kyc_certificate_document: "File should not be more than 2MB",
        });

        setShowCertificateError(true);

        return;
      }
    }

    const name = getImageUploadParams(
      "kyc_certificate_document",
      acceptedFiles[0]
    );

    const res = await getSignedUrl("kyc_certificate_document", name);

    setCertificateLoader(true);
    if (res) {
      const responseData = res?.data?.data;
      const imgData = responseData?.files?.[name];
      const postUrl = imgData?.postUrl;
      const signedURLData = imgData?.postFields;
      const s3Url = imgData?.s3Url;
      const uploaded = uploadImageToS3(
        postUrl,
        getFormData(signedURLData, acceptedFiles[0])
      );

      uploaded.then(() => {
        setS3Url("kyc_certificate_document", s3Url);
        const previewUrl = imgData?.previewUrl;
        setCertificateUrl(previewUrl);
        setShowCertificateError(false);
      });
      uploaded.finally(() => {
        setCertificateLoader(false);
      });
    }
  }

  async function onCACFilesDrop<T extends FileWithPath>(acceptedFiles: T[]) {
    if (acceptedFiles.length === 0) {
      setErrors({
        ...errors,
        kyc_cac_status_report_document:
          "File type allowed is .png, .jpeg, .pdf",
      });

      setCacShowError(true);

      return;
    }

    if (acceptedFiles.length >= 1) {
      if (acceptedFiles[0].size / 1024 > 2000) {
        setErrors({
          ...errors,
          kyc_cac_status_report_document: "File should not be more than 2MB",
        });

        setCacShowError(true);

        return;
      }
    }
    const name = getImageUploadParams(
      "kyc_cac_status_report_document",
      acceptedFiles[0]
    );

    const res = await getSignedUrl("kyc_cac_status_report_document", name);

    setCacStatusLoader(true);
    if (res) {
      const responseData = res?.data?.data;
      const imgData = responseData?.files?.[name];
      const postUrl = imgData?.postUrl;
      const signedURLData = imgData?.postFields;
      const s3Url = imgData?.s3Url;
      const uploaded = uploadImageToS3(
        postUrl,
        getFormData(signedURLData, acceptedFiles[0])
      );
      uploaded.then(() => {
        setS3Url("kyc_cac_status_report_document", s3Url);
        const previewUrl = imgData?.previewUrl;
        setCacStatusUrl(previewUrl);
        setCacShowError(false);
      });
      uploaded.finally(() => {
        setCacStatusLoader(false);
      });
    }
  }

  useEffect(() => {
    getBankNameOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBankAccountName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccountName, inputValues?.bankAccountNumber]);

  useEffect(() => {
    if (
      !errors.cacDocumentNumber &&
      inputValues.cacDocumentNumber?.length === 7
    ) {
      rcInputRef.current.disabled = false;
    }
    if (errors.cacDocumentNumber) {
      if (!kycDetails?.rejected_reason?.cac_document_number) {
        rcInputRef.current.value = "";
        inputValues.businessRcNumber = "";
        errors.businessRcNumber =
          kycDetails?.rejected_reason?.business_rc_number || "";
        rcInputRef.current.disabled = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.cacDocumentNumber]);
  return (
    <div className="md:flex md:items-center md:justify-center relative">
      <div className="hidden md:block z-50">
        <SideBar />
      </div>
      <div
        className={`w-full md:w-4/6 md:my-[50px] md:rounded-[20px] md:ml-[-100px] md:border-[#2db49e80] md:border-[1px] overflow-y-auto ${styles.pageHeight}`}
      >
        <div className="container py-[20px] md:py-[40px] md:pl-[165px] md:pr-[40px] overflow-y-auto md:min-h-[690px] relative z-10 bg-[#2db49e1a] md:bg-transparent">
          <div className="flex justify-between items-center mb-[40px]">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => {
                navigate("/kyc/business-information-1");
              }}
            >
              <div>
                <GreenLeftArrow />
              </div>
              <div className="ml-[20px] font-semibold text-[20px] leading-[25px] text-[#192850]">
                Business Information
              </div>
            </div>
            <div className="font-light text-[14px] leading-[16px] text-[#192850]">
              2 of 2
            </div>
          </div>
          <form onSubmit={(e) => formSubmit(e)}>
            {/* new kyc form */}

            <div className="md:grid md:grid-cols-2 md:gap-[20px]">
              {/* first column */}
              <div>
                <div className="mb-0">
                  <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                    Business Status
                  </div>

                  <AnimatedDropdown
                    setDropDownValues={setDropDownValues}
                    dropdownOption={BusinessStatusOptions}
                    type={"businessStatus"}
                    name="businessStatus"
                    selectedValue={inputValues?.businessStatus}
                    heading={"Select your Business Status"}
                  />

                  <div className="text-[12px] text-[#FF0000] min-h-[30px]">
                    {errors?.businessStatus}
                  </div>
                </div>

                {inputValues.businessStatus === "Registered" && (
                  <div className="mb-0">
                    <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                      Registration Type
                    </div>

                    <AnimatedDropdown
                      setDropDownValues={setDropDownValues}
                      dropdownOption={RegistrationTypeOptions}
                      type={"businessType"}
                      name="businessType"
                      selectedValue={inputValues?.businessType}
                      heading={"Select your Registration Type"}
                    />

                    <div className="text-[12px] text-[#FF0000] min-h-[30px]">
                      {errors?.businessType}
                    </div>
                  </div>
                )}

                {inputValues.businessStatus === "Registered" &&
                  inputValues.businessType === "Sole Proprietorship" && (
                    <div className="mb-0">
                      <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                        BN Number
                      </div>
                      <input
                        className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                        id="bnNumber"
                        type="text"
                        value={inputValues?.bnNumber}
                        name="bnNumber"
                        autoComplete="off"
                        onChange={(e) => handleInput(e)}
                        onBlur={(e) => validateInput(e)}
                        maxLength={10}
                      />
                      <div className="text-[12px] text-[#FF0000] min-h-[30px]">
                        {errors?.bnNumber}
                      </div>
                    </div>
                  )}

                {inputValues.businessStatus === "Registered" &&
                  inputValues.businessType === "Limited Liability Company" && (
                    <div className="mb-2">
                      <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                        CAC Document Number
                      </div>
                      <input
                        className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                        id="cacDocumentNumber"
                        type="text"
                        value={inputValues?.cacDocumentNumber}
                        name="cacDocumentNumber"
                        autoComplete="off"
                        onChange={(e) => handleInput(e)}
                        onBlur={(e) => validateInput(e)}
                        maxLength={10}
                      />
                      <div className="text-[12px] text-[#FF0000] min-h-[30px]">
                        {errors?.cacDocumentNumber}
                      </div>
                    </div>
                  )}

                {inputValues.businessStatus === "Registered" &&
                  inputValues.businessType === "Limited Liability Company" && (
                    <div className="mb-2">
                      <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                        RC Number
                      </div>
                      <input
                        className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                        id="businessRcNumber"
                        type="text"
                        value={inputValues?.businessRcNumber}
                        name="businessRcNumber"
                        autoComplete="off"
                        onChange={(e) => handleInput(e)}
                        onBlur={(e) => validateInput(e)}
                        maxLength={10}
                      />
                      <div className="text-[12px] text-[#FF0000] min-h-[30px]">
                        {errors?.businessRcNumber}
                      </div>
                    </div>
                  )}

                {inputValues.businessStatus === "Registered" &&
                  inputValues.businessType === "Limited Liability Company" && (
                    <div className="mb-2">
                      <div className="font-light text-[14px] leading-[16px] text-[#192850] mb-4">
                        CAC 1.1 or Status report
                      </div>
                      <Dropzone
                        accept=".png, .jpeg, .pdf"
                        onDrop={onCACFilesDrop}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className="border border-[#DCDDE5] rounded-[5px]"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="border border-[#DCDDE5] rounded-[5px] m-4 p-5 md:grid md:grid-cols-[20%_auto] items-center">
                              <DropDownIcon width={50} height={50} />

                              {cacStatusLoader && (
                                <p className="font-[500] text-[15px] text-[#94A2AB]">
                                  Loading
                                </p>
                              )}

                              {!cacStatusLoader && !cacStatusUrl && (
                                <p className="font-[500] text-[15px] text-[#94A2AB]">
                                  Drag & drop file here or{" "}
                                  <span className="text-[#2DB49E]">browse</span>{" "}
                                  to upload (pdf, jpeg, png. Maximum file size
                                  is 2mb)
                                </p>
                              )}

                              {!cacStatusLoader && cacStatusUrl && (
                                <p className="font-[500] text-[15px] text-[#2DB49E]">
                                  File successfully uploaded
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </Dropzone>

                      <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                        {showCacError
                          ? `${errors?.kyc_cac_status_report_document}`
                          : ""}
                      </div>
                    </div>
                  )}

                {inputValues.businessType === "" && (
                  <div className="mb-0">
                    <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                      Bank Name
                    </div>
                    <SearchableDropdown
                      setDropDownValues={setDropDownValues}
                      type={"bankName"}
                      selectedValue={inputValues?.bankName}
                      dropdownOptions={bankNameList}
                      heading={"Select Bank Name"}
                      disableField={false}
                    />
                    <div className="text-[12px] text-[#FF0000] min-h-[30px]">
                      {errors?.bankName}
                    </div>
                  </div>
                )}

                {inputValues.businessType === "" && (
                  <div className="mb-2">
                    <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                      Account Number
                    </div>
                    <input
                      className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                      id="bankAccountNumber"
                      type="text"
                      value={inputValues?.bankAccountNumber}
                      name="bankAccountNumber"
                      autoComplete="off"
                      onChange={(e) => handleInput(e)}
                      onBlur={(e) => validateInput(e)}
                      maxLength={10}
                    />
                    <div className="text-[12px] text-[#FF0000] min-h-[30px]">
                      {errors?.bankAccountNumber}
                    </div>
                  </div>
                )}

                {(inputValues.businessStatus === "Not Registered" ||
                  inputValues.businessStatus === "") && (
                  <div className="mb-0">
                    <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                      Bank Account Name
                    </div>
                    <input
                      className={`border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px] ${styles.inputField}`}
                      id="bankAccountName"
                      type="text"
                      value={inputValues?.bankAccountName}
                      name="bankAccountName"
                      autoComplete="off"
                      disabled
                    />
                    <div className="text-[12px] text-[#FF0000] min-h-[30px]">
                      {errors?.bankAccountName}
                    </div>
                  </div>
                )}

                {inputValues.businessStatus === "Registered" &&
                  inputValues.businessType === "Sole Proprietorship" && (
                    <div>
                      <div className="font-light text-[14px] leading-[16px] text-[#192850] mb-4">
                        Certificate of registration
                      </div>
                      <Dropzone
                        accept=".png, .jpeg, .pdf"
                        onDrop={onCertificateFilesDrop}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className="border border-[#DCDDE5] rounded-[5px]"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="border border-[#DCDDE5] rounded-[5px] m-4 p-5 md:grid md:grid-cols-[20%_auto] items-center">
                              <DropDownIcon width={50} height={50} />

                              {certificateLoader && (
                                <p className="font-[500] text-[15px] text-[#94A2AB]">
                                  Loading
                                </p>
                              )}

                              {!certificateLoader && !certificateUrl && (
                                <p className="font-[500] text-[15px] text-[#94A2AB]">
                                  Drag & drop file here or{" "}
                                  <span className="text-[#2DB49E]">browse</span>{" "}
                                  to upload (pdf, jpeg, png. Maximum file size
                                  is 2mb)
                                </p>
                              )}

                              {!certificateLoader && certificateUrl && (
                                <p className="font-[500] text-[15px] text-[#2DB49E]">
                                  File successfully uploaded
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </Dropzone>

                      <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                        {showCertifcateError
                          ? `${errors?.kyc_certificate_document}`
                          : ""}
                      </div>
                    </div>
                  )}
              </div>
              {/* first column */}

              {/* Second column */}
              <div>
                {inputValues.businessType !== "" && (
                  <div className="mb-0">
                    <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                      Bank Name
                    </div>
                    <SearchableDropdown
                      setDropDownValues={setDropDownValues}
                      type={"bankName"}
                      selectedValue={inputValues?.bankName}
                      dropdownOptions={bankNameList}
                      heading={"Select Bank Name"}
                      disableField={false}
                    />
                    <div className="text-[12px] text-[#FF0000] min-h-[30px]">
                      {errors?.bankName}
                    </div>
                  </div>
                )}

                {inputValues.businessType !== "" && (
                  <div className="mb-0">
                    <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                      Account Number
                    </div>
                    <input
                      className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                      id="bankAccountNumber"
                      type="text"
                      value={inputValues?.bankAccountNumber}
                      name="bankAccountNumber"
                      autoComplete="off"
                      onChange={(e) => handleInput(e)}
                      onBlur={(e) => validateInput(e)}
                      maxLength={10}
                    />
                    <div className="text-[12px] text-[#FF0000] min-h-[30px]">
                      {errors?.bankAccountNumber}
                    </div>
                  </div>
                )}

                {inputValues.businessStatus === "Registered" && (
                  <div className="mb-0">
                    <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                      Bank Account Name
                    </div>
                    <input
                      className={`border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px] ${styles.inputField}`}
                      id="bankAccountName"
                      type="text"
                      value={inputValues?.bankAccountName}
                      name="bankAccountName"
                      autoComplete="off"
                      disabled
                    />
                    <div className="text-[12px] text-[#FF0000] min-h-[30px]">
                      {errors?.bankAccountName}
                    </div>
                  </div>
                )}

                <div>
                  <div className="font-light text-[14px] leading-[16px] text-[#192850] mb-4">
                    Utility Bill(water bill, electricity bill, sanitation bill)
                  </div>
                  <Dropzone accept=".png, .jpeg, .pdf" onDrop={onFilesDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <div
                        className="border border-[#DCDDE5] rounded-[5px]"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="border border-[#DCDDE5] rounded-[5px] m-4 p-5 md:grid md:grid-cols-[20%_auto] items-center">
                          <DropDownIcon width={50} height={50} />
                          {utilityUploadLoader && (
                            <p className="font-[500] text-[15px] text-[#94A2AB]">
                              Loading
                            </p>
                          )}

                          {!utilityUploadLoader && !url && (
                            <p className="font-[500] text-[15px] text-[#94A2AB]">
                              Drag & drop file here or{" "}
                              <span className="text-[#2DB49E]">browse</span> to
                              upload (pdf, jpeg, png. Maximum file size is 2mb)
                            </p>
                          )}

                          {!utilityUploadLoader && url && (
                            <p className="font-[500] text-[15px] text-[#2DB49E]">
                              File successfully uploaded
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </Dropzone>

                  <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                    {showError ? `${errors?.utilityBillDocumentUrl}` : ""}
                  </div>
                </div>
              </div>
              {/* Second column */}
            </div>

            {/* new kyc form */}

            <div className="flex justify-center mt-[55px]">
              <button
                className="bg-[#2DB49E] rounded-[5px] font-semibold text-[14px] leading-[16px] text-[#fff] w-full md:w-1/2 py-[12px]"
                type="submit"
              >
                Complete
              </button>
            </div>
            <div className="text-[16px] font-bold text-[#FF0000] flex justify-center mt-[10px] min-h-[24px]">
              {errors.generalErr}
            </div>
          </form>
        </div>
      </div>
      <div
        className={`absolute bottom-[160px] md:bottom-[105px] right-[10px] md:right-[-10px] ${styles.duploLogoOpaque} z-[-1]`}
      >
        <img
          src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
          alt="DuploLogo"
        />
      </div>
    </div>
  );
}

export default BusinessInfoPgTwo;

import { ReactComponent as DuploLogo } from "../../svg/duploLogo.svg";
import { ReactComponent as BnplLogo } from "../../svg/bnplLogo.svg";
import { ReactComponent as GreenRightArrow } from "../../svg/greenRightArrow.svg";
import { useCheckoutContext } from "../../../Context/CheckoutRef";
import { getDDMMYYYFormat } from "../../../Utility/DateFormats";
import { CurrencyFormat } from "../../../Utility/CurrencyFormat";
import { useLocation } from "react-router-dom";

function PaymentSuccessConfirmation() {
  const { state } = useLocation();
  const { checkoutDetails } = useCheckoutContext();
  return (
    <div
      className="container md:px-[35px]"
      style={{ minHeight: "calc(100vh - 55px)" }}
    >
      <div className="flex items-start justify-between mt-[20px] md:pb-[20px] md:border-b-[1px] md:border-b-[#0000001a]">
        <div className="flex items-center">
          <span>
            <DuploLogo />
          </span>
          <span className="ml-[9px] text-[20px] leading-[25px] font-medium text-[#5C696E]">
            Duplo
          </span>
        </div>
        <div className="text-right">
          <div className="text-[20px] font-medium leading-[25px] text-[#003F34] text-right">
            {CurrencyFormat(checkoutDetails?.amount)}
          </div>
          <div className="text-end font-light text-[14px] leading-[22px] text-[#5C696E] mt-[6px]">
            {checkoutDetails?.email}
          </div>
        </div>
      </div>

      <div className="mx-[-15px] bg-[#103129] flex md:hidden items-center justify-between px-[15px] py-[17px] mt-[45px] cursor-pointer">
        <div className="flex items-center">
          <span>
            <BnplLogo />
          </span>
          <span className="font-normal text-[#fff] text-[14px] leading-[14px] ml-[14px]">
            Buy Now Pay Later
          </span>
        </div>
        <div className="flex items-center">
          <span className="font-normal text-[14px] leading-[22px] text-[#5C696E] mr-[6px]">
            Change payment method
          </span>
          <span>
            <GreenRightArrow />
          </span>
        </div>
      </div>
      <div className="flex justify-center mt-[30px] mb-[20px]">
        <div className="w-[180px] h-[180px] flex justify-center">
          <img
            src="https://d1f8g8i172sxu4.cloudfront.net/images/successIcon.gif"
            alt="successfull"
          />
        </div>
      </div>
      <div className="mt-[8px] text-center font-medium text-[16px] leading-[20px] text-[#000]">
        Congratulations {checkoutDetails?.first_name}!!
      </div>
      <div className="mt-[30px] text-center font-light text-[14px] leading-[22px] text-[#5C696E]">
        Your loan request has now been approved. See the <br /> details below:
      </div>
      <div className="mt-[36px] bg-[#2db49e14] py-[22px] rounded-[10px]">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-[15px]">
          <div className="justify-self-center text-start">
            <div className="font-light text-[10px] leading-[16px] text-[#5C696E]">
              Payment due date
            </div>
            <div className="mt-[8px] font-medium text-[18px] leading-[23px] text-[#003F34]">
              {state?.due_date_timestamp &&
                `${getDDMMYYYFormat(state?.due_date_timestamp)}`}
            </div>
          </div>
          <div className="justify-self-center text-start">
            <div className="font-light text-[10px] leading-[16px] text-[#5C696E]">
              Amount borrowed
            </div>
            <div className="mt-[8px] font-medium text-[18px] leading-[23px] text-[#003F34]">
              {CurrencyFormat(state?.loan_amount)}
            </div>
          </div>
          <div className="justify-self-center text-start">
            <div className="font-light text-[10px] leading-[16px] text-[#5C696E]">
              Interest rate
            </div>
            <div className="mt-[8px] font-medium text-[18px] leading-[23px] text-[#003F34]">
              {state?.interest_rate}
              {state?.interest_rate ? "%" : ""}
            </div>
          </div>
          <div className="justify-self-center text-start">
            <div className="font-bold text-[10px] leading-[16px] text-[#5C696E]">
              Total amount payable
            </div>
            <div className="mt-[8px] font-medium text-[18px] leading-[23px] text-[#003F34]">
              {CurrencyFormat(state?.amount_due_on_due_date)}
            </div>
          </div>
        </div>
      </div>
      <div className="font-light text-[10px] leading-[16px] text-[#5C696E]">
        * repayment is locked for 30 days
      </div>
      <div className="flex justify-center my-[47px]">
        <a
          href={checkoutDetails.redirectUrl}
          className="text-[#fff] bg-[#2DB49E] rounded-[5px] font-medium text-[14px] leading-[14px] px-[35px] py-[13px]"
        >
          Complete your purchase
        </a>
      </div>
    </div>
  );
}

export default PaymentSuccessConfirmation;

import { useLocation } from "react-router";
import WebBackground from "../Background/WebBackground";
import styles from "../Background/WebBackground.module.scss";
import LoggedInBottomBar from "../BottomBar/LoggedInBottomBar";
import LoggedOutBottomBar from "../BottomBar/LoggedOutBottomBar";
import SideBar from "../SideBar/SideBar";
import OtpVerificationForm from "./VerifyOtpForm";
import style from "./VerifyOtp.module.scss";

function VerifyOtp() {
  const { state } = useLocation();
  return (
    <>
      {(window.innerWidth < 768 ||
        state?.isForgotPassword ||
        state?.isSignUp) && (
        <div className="relative">
          <div
            className="md:container md:py-[40px]"
            style={{ minHeight: "calc(100vh - 58px)" }}
          >
            <WebBackground />
            <div
              className={`${styles.formElement} ${styles.inputField} flex justify-center item-center`}
            >
              <OtpVerificationForm />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 md:container py-[14px] w-full">
            <LoggedOutBottomBar />
          </div>
          <div
            className={`absolute bottom-[135px] md:bottom-[110px] right-[-4px] md:right-[-10px] ${styles.duploLogoOpaque}`}
          >
            <img
              src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
              alt="DuploLogo"
            />
          </div>
        </div>
      )}

      {window.innerWidth >= 768 && state?.isResetPassword && (
        <div className="md:flex md:items-center md:justify-center relative">
          <div className="hidden md:block z-50">
            <SideBar />
          </div>
          <div
            className={`w-full md:w-4/6 md:my-[50px] md:rounded-[20px] md:ml-[-100px] md:border-[#2db49e80] md:border-[1px] ${styles.pageHeight}`}
          >
            <div
              className={`py-[20px] md:py-[40px] md:pl-[165px] md:pr-[40px] md:mt-[64px]`}
            >
              <div className={style.formBackground}>
                <OtpVerificationForm />
              </div>
            </div>
          </div>
          <div className="md:hidden absolute bottom-0 w-full">
            <LoggedInBottomBar />
          </div>
          <div
            className={`absolute bottom-[160px] md:bottom-[105px] right-[10px] md:right-[-10px] ${style.duploLogoOpaque}`}
          >
            <img
              src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
              alt="DuploLogo"
            />
          </div>
        </div>
      )}
    </>
  );
}
export default VerifyOtp;

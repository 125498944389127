import { useState } from "react";
import { CurrencyFormat } from "../../Utility/CurrencyFormat";
import { DDMMYYWithTime } from "../../Utility/DateFormats";
import TransactionDetailsModal from "./TransactionDetailsModal/TransactionDetailsModal";

const paymentTypeMap = {
  LOAN_REPAYMENT: "Loan repayment",
  BUSINESS_PAYMENT: "Business payment",
};

const TransactionHistoryCell = ({ transactionsData }: any) => {
  const [modalShown, setModalShown] = useState(false);
  const [modalData, setModalData] = useState({});
  if (window.innerWidth <= 767) {
    return (
      <>
        {transactionsData.map((item: any, index: number) => (
          <div
            className='relative border border-[#EEEEEE] rounded-[5px] bg-[#ffffff] py-[20px] px-[10px] my-[10px] z-[4]'
            onClick={() => {
              setModalData(transactionsData[index]);
              setModalShown(!modalShown);
            }}
          >
            <div className='text-[#94A2AB] text-[12px] leading-[14px] font-normal'>
              {item?.payment_date && DDMMYYWithTime(item?.payment_date)}
            </div>
            <div className='flex justify-between mt-[12px]'>
              <div className='font-normal text-[16px] leading-[14px] text-[#162930]'>
                {paymentTypeMap[item?.payment_type]}
              </div>
              <div className='font-medium text-[20px] leading-[14px] text-[#162930]'>
                {CurrencyFormat(item?.amount)}
              </div>
            </div>
          </div>
        ))}
        <TransactionDetailsModal
          shown={modalShown}
          transactionsData={modalData}
          close={() => {
            setModalShown(false);
          }}
        />
      </>
    );
  } else {
    return (
      <div className='md:h-[325px] overflow-y-auto'>
        {transactionsData.map((item: any, index: number) => (
          <div
            className='grid grid-cols-8 items-center my-[20px] justify-center'
            key={index}
          >
            <div className='col-span-3 text-[14px] leading-[14px] font-normal text-[#162930]'>
              {item?.payment_date && DDMMYYWithTime(item?.payment_date)}
            </div>
            <div className='col-span-3 text-[14px] leading-[14px] font-normal text-[#162930]'>
              {paymentTypeMap[item?.payment_type]}
            </div>
            <div className='col-span-1 text-[14px] leading-[14px] font-bold text-[#162930] text-right'>
              {CurrencyFormat(item?.amount)}
            </div>
            <div className='flex items-center justify-end cursor-pointer text-[#2DB49E]'>
              <div
                className={`rounded-[4px] px-[10px] py-[2px] text-[14px] leading-[14px]`}
                onClick={() => {
                  setModalData(transactionsData[index]);
                  setModalShown(!modalShown);
                }}
              >
                View
              </div>
              <div>
                <svg
                  width='8'
                  height='12'
                  viewBox='0 0 8 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1.99984 0L0.589844 1.41L5.16984 6L0.589844 10.59L1.99984 12L7.99984 6L1.99984 0Z'
                    fill='#2DB49E'
                  />
                </svg>
              </div>
            </div>
          </div>
        ))}
        <TransactionDetailsModal
          shown={modalShown}
          transactionsData={modalData}
          close={() => {
            setModalShown(false);
          }}
        />
      </div>
    );
  }
};

export default TransactionHistoryCell;

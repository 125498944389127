import WebBackground from "../Background/WebBackground";
import LoggedOutBottomBar from "../BottomBar/LoggedOutBottomBar";
import styles from "../Background/WebBackground.module.scss";
import InsufficientSales from "./InsufficientSales";

function InsufficientSalesHistory() {
  return (
    <div className="relative">
      <div
        className="md:container md:py-[40px]"
        style={{ minHeight: "calc(100vh - 55px)" }}
      >
        <WebBackground />
        <div
          className={`${styles.formElement} ${styles.inputField} flex justify-center item-center`}
        >
          <InsufficientSales />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 md:container py-[14px] w-full">
        <LoggedOutBottomBar />
      </div>
      <div
        className={`absolute bottom-[135px] md:bottom-[110px] right-[-4px] md:right-[-10px] ${styles.duploLogoOpaque}`}
      >
        <img
          src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
          alt="DuploLogo"
        />
      </div>
    </div>
  );
}

export default InsufficientSalesHistory;

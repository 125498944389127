export const getDaysLeft = (timestamp: any) => {
  let date_1 = new Date(timestamp);
  let date_2 = new Date();
  let difference = date_1.getTime() - date_2.getTime();
  let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  let daysleft = ""
  if( TotalDays > 1) {
    daysleft = `${TotalDays} days left`;
  } else if (TotalDays === 1) {
    daysleft = `${TotalDays} day left`;
  } else {
    daysleft = `Expired`;
  }

  return daysleft;
};

export const getDDMMFormat = (timestamp: any) => {
  let options: object = {
    month: "long",
    day: "numeric",
  };
  const formatedDate = new Intl.DateTimeFormat("en-GB", options).format(
    new Date(timestamp)
  );
  return formatedDate;
};

export const getDDMMYYYFormat = (timestamp: any) => {
  const convertedDate = new Date(timestamp * 1000);
  const formatedDate = new Intl.DateTimeFormat("en-GB").format(convertedDate);
  return formatedDate;
};

export const DDMMYYWithoutTime = (timestamp: any) => {
  const convertedDate = new Date(timestamp);
  const formatedDate = new Intl.DateTimeFormat("en-GB").format(convertedDate);


  return `${formatedDate}`;
}

export const DDMMYYWithTime = (timestamp: any) => {
  const convertedDate = new Date(timestamp);
  const formatedDate = new Intl.DateTimeFormat("en-GB").format(convertedDate);

  const options: object = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const formatedTime = new Intl.DateTimeFormat("en-US", options).format(
    convertedDate
  );

  return `${formatedDate} ${formatedTime}`;
};

// February 20, 2021
export const getMMDDYYYYFormat = (timestamp: any) => {
  const options: object = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formatedDate = new Intl.DateTimeFormat("en-US", options).format(timestamp);

  return formatedDate;
}
import styles from './History.module.scss'

const NoTransactionHistory = ({historyKind} : any) => {
  return (
    <>
      {window.innerWidth >= 768 ? (
        <div className={`${historyKind === 'LOAN_HISTORY'? 'mt-[50px] md:mt-[60px]' : ' mt-[130px] md:mt-[160px]'}`}>
          <div className="grid grid-cols-1 place-items-center text-center">
            <img
              src="https://d1f8g8i172sxu4.cloudfront.net/images/history-empty-state.png"
              alt="No History"
              height={100}
              width={100}
            />
            <div className="text-[14px] leading-[19px] font-bold text-[#162930] mt-[25px] opacity-50">
              {historyKind === 'LOAN_HISTORY' ? 'You have no active loan' : 'You haven’t made any transactions'}
              <div className="font-light text-[#00181299]">
                {historyKind === 'LOAN_HISTORY' ? ' Your loan history will be shown here' : 'All your transaction history will show here'}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${historyKind === 'LOAN_HISTORY'? 'mt-[10px] md:mt-[60px]' : ' mt-[130px] md:mt-[160px]'} bg-[#fff] px-[20px] py-[60px] mt-[25px] ${styles.noHistory}`}
        >
          <div className="grid grid-cols-1 place-items-center text-center">
            <img
              src="https://d1f8g8i172sxu4.cloudfront.net/images/history-empty-state.png"
              alt="No History"
              height={100}
              width={100}
            />
            <div className="text-[14px] leading-[19px] font-bold text-[#162930] mt-[25px] opacity-50">
              {historyKind === 'LOAN_HISTORY' ? 'You have no active loan' : 'You haven’t made any transactions'}
              <div className="font-normal text-[#00181299]">
                {historyKind === 'LOAN_HISTORY' ? ' Your loan history will be shown here' : 'All your transaction history will show here'}
              </div>
            </div>
        </div>
        </div>
      )}
    </>
  )
}

export default NoTransactionHistory

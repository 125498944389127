import { useEffect, useRef, useState } from "react";
import { api } from "../../Service/AuthService";
import { CurrencyFormat } from "../../Utility/CurrencyFormat";
import { getDaysLeft, getDDMMFormat } from "../../Utility/DateFormats";
import ConfirmRepaymentModal from "./ConfirmRepaymentModal";
import NoActiveLoans from "./NoActiveLoans";
import styles from "./LoanHistory.module.scss";
import RepaymentSuccessModal from "./RepaymentSuccessModal";
import Loader from "../Loader";
import StampdutyModal from "./StampdutyModal";

const ActiveLoans = () => {
  const dataFetchedRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [showActiveLoans, setShowActiveLoans] = useState(false);
  const [repaymentModalShown, toggleRepaymentModal] = useState(false);
  const [stampDutyModalShown, toggleStampDutyModal] = useState(false);
  const [repaymentSuccessModalShown, toggleRepaymentSuccessModal] =
    useState(false);
  const [virtualAcc, setVirtualAcc] = useState();
  const [showNoLoans, setShowNoLoans] = useState(false);

  const [loansData, setLoansData] = useState({
    timestamp: "",
    interestRate: "",
    totalBorrowedAmount: 0,
    amountDueOnDueDate: 0,
  });

  const getActiveLoans = () => {
    setLoading(true);
    api
      .get("/active-loan")
      .then((response) => {
        const responseData = response?.data?.data;
        const loans = responseData?.loans;

        if (loans?.length >= 1) {
          setShowActiveLoans(true);
        }
        if (loans?.length === 0) {
          setShowNoLoans(true);
        }
        setLoansData({
          timestamp: loans[0]?.due_date_timestamp,
          interestRate: loans[0]?.interest_rate,
          totalBorrowedAmount: loans[0]?.total_borrowed_amount,
          amountDueOnDueDate: loans[0]?.amount_due_on_due_date,
        });
      })
      .catch((error) => {
        setShowNoLoans(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getRepaymentAccount = async () => {
    await api.get("repayment-account").then((response) => {
      const responseData = response?.data?.data;
      const virtualAccount = responseData?.virtual_account;
      setVirtualAcc(virtualAccount);
    });
  };

  const handleStampDutySubmit = () => {
    toggleStampDutyModal(false);
    toggleRepaymentModal(true);
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getActiveLoans();
    getRepaymentAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {loading && (
        <div style={{ marginBottom: "30px", paddingBottom: "30px" }}>
          <Loader />
        </div>
      )}
      {showActiveLoans && !loading ? (
        <div className={`py-[35px] w-full ${styles.loanDetails}`}>
          <div className="px-[25px] grid grid-cols-2 md:grid-cols-3 pb-[40px] border-b-[5px] border-b-[#F4F9F5]">
            <div>
              <div className="font-normal text-[#103129] text-[14px] leading-[14px]">
                Total Repayment Amount
              </div>
              <div className="font-medium text-[30px] leading-[21px] text-[#103129] mt-[20px]">
                {CurrencyFormat(loansData?.totalBorrowedAmount)}
              </div>
            </div>
            <div className="md:ml-0 ml-[50px]">
              <div className="font-light text-[12px] leading-[16px] text-[#5C696E]">
                Interest incurred
              </div>
              <div className="font-semibold text-[16px] leading-[20px] text-[#003F34] mt-[10px]">
                {loansData?.interestRate}%
              </div>
            </div>
          </div>
          <div className="px-[25px] grid grid-cols-2 md:grid-cols-3 pt-[40px]">
            <div>
              <div className="font-semibold text-[16px] leading-[20px] text-[#003F34]">
                Next repayment
              </div>
              <div className="flex items-center mt-[10px] font-light text-[12px] leading-[16px] text-[#5C696E]">
                <div>
                  {loansData?.timestamp &&
                    `Due ${getDDMMFormat(loansData?.timestamp)}`}
                </div>
                <span className="mx-[7px] h-[4px] w-[4px] rounded-full bg-[#D9D9D9]"></span>
                <div>
                  {loansData?.timestamp &&
                    `${getDaysLeft(loansData?.timestamp)}`}{" "}
                  days left
                </div>
              </div>

              <div className="flex items-center mt-[10px] font-light text-[12px] leading-[16px] text-[#5C696E]">
                Repayment can be made before the due date
              </div>
            </div>
            <div className="grid content-center font-semibold text-[20px] leading-[25px] text-[#003F34] md:ml-0 ml-[50px]">
              {CurrencyFormat(loansData?.amountDueOnDueDate)}
            </div>

            <button
              className={`${
                !showActiveLoans ||
                (loansData && loansData?.totalBorrowedAmount === 0)
                  ? "opacity-50"
                  : ""
              } border-[1px] border-[#2DB49E] rounded-[5px] text-[#2DB49E] text-[14px] leading-[18px] font-medium py-[10px] mt-[10px] md:mt-0 col-span-2 md:col-span-1 h-[50px]`}
              disabled={
                !showActiveLoans ||
                (loansData && loansData?.totalBorrowedAmount === 0)
              }
              onClick={() => {
                toggleStampDutyModal(true);
              }}
            >
              Repay now
            </button>
            <StampdutyModal
              shown={stampDutyModalShown}
              close={() => {
                toggleStampDutyModal(false);
              }}
              toggleStampDutyModal={handleStampDutySubmit}
            />
            <ConfirmRepaymentModal
              shown={repaymentModalShown}
              amount={loansData?.amountDueOnDueDate || 0}
              toggleRepaymentSuccessModal={toggleRepaymentSuccessModal}
              virtualAcc={virtualAcc}
              close={() => {
                toggleRepaymentModal(false);
              }}
            />
            <RepaymentSuccessModal
              shown={repaymentSuccessModalShown}
              close={() => {
                toggleRepaymentSuccessModal(false);
              }}
            />
          </div>
        </div>
      ) : null}
      {showNoLoans ? <NoActiveLoans /> : null}
    </>
  );
};

export default ActiveLoans;

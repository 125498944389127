import { hotjar } from "react-hotjar";

/**
 *
 * @param id
 * @param version
 * @returns {Undefined}
 */
function initializeHotjar(id: number, version: number): void {
  return hotjar.initialize(id, version);
}

/**
 *
 * @param pathName
 * @returns {Undefined}
 */
function pageChangeStatusForHotJar(pathName: string): void {
  if (hotjar.initialized()) {
    hotjar.stateChange(pathName);
  }
}

/**
 *
 * @param eventName
 * @returns {Undefined}
 */
function trackEventForHotjar(eventName: string): void {
  if (hotjar.initialized()) {
    hotjar.event(eventName);
  }
}

/**
 *
 * @param userId
 * @param userAttributes
 * @returns {Undefined}
 */
function identifyOnHotjar(
  userId: string,
  userAttributes: Record<string, unknown>
): void {
  if (hotjar.initialized()) {
    hotjar.identify(userId, userAttributes);
  }
}

export {
  initializeHotjar,
  pageChangeStatusForHotJar,
  trackEventForHotjar,
  identifyOnHotjar,
};

import styles from "./ConfirmDelivery.module.scss";
import { ReactComponent as ModalCloseIcon } from "../svg/modalCloseIcon.svg";
import { useNavigate } from "react-router";

function ConfirmDeliveryModal({ shown, close }: any) {
  const navigate = useNavigate();
  return shown ? (
    <div
      className={styles.modalBackdrop}
      onClick={() => {
        close();
      }}
    >
      <div
        className={styles.modalContent}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button
          className="text-[#000] absolute top-[10px] right-[8px]"
          onClick={() => {navigate('/profile')}}
        >
          <ModalCloseIcon />
        </button>
        <div className="mt-[65px] flex justify-center">
          <div className="w-[180px] h-[180px] flex justify-center">
            <img
              src="https://d1f8g8i172sxu4.cloudfront.net/images/successIcon.gif"
              alt="successfull"
            />
          </div>
        </div>
        <div className="mt-[32px] text-center font-semibold text-[20px] leading-[25px] text-[#192850]">Response received</div>
        <div className="mt-[14px] text-center font-light text-[16px] leading-[24px] text-[#192850]">Your response has been received.</div>
        <div className="flex justify-center mt-[70px] mb-[15px]" onClick={() => navigate('/profile')}>
            <button className="bg-[#2DB49E] px-[45px] py-[13px] text-[#fff] font-medium text-[14px] leading-[14px] rounded-[5px]">Okay</button>
        </div>
      </div>
    </div>
  ) : null;
}

export default ConfirmDeliveryModal;

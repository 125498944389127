import { useNavigate } from "react-router";
import SideBar from "../SideBar/SideBar";
import styles from "./Kyc.module.scss";
import { ReactComponent as GreenLeftArrow } from "../svg/greenLeftArrow.svg";

function KycCompleted() {
  const navigate = useNavigate();
  return (
    <div className="md:flex md:items-center md:justify-center relative">
      <div className="hidden md:block z-50">
        <SideBar />
      </div>
      <div
        className={`w-full md:w-4/6 md:my-[50px] md:rounded-[20px] md:ml-[-100px] md:border-[#2db49e80] md:border-[1px] overflow-y-auto ${styles.pageHeight} bg-[#2db49e1a] md:bg-transparent`}
      >
        <div className="py-[40px] md:pl-[165px] md:pr-[40px] md:min-h-[690px]">
          <div
            className="flex items-center px-[15px] cursor-pointer"
            onClick={() => {
              navigate("/profile");
            }}
          >
            <div>
              <GreenLeftArrow />
            </div>
            <div className="ml-[30px] mr-[20px] text-[#192850] font-semibold text-[20px] leading-[25px]">
              KYC
            </div>
            <div className="font-normal text-[16px] leading-[14px] text-[#FFFFFF] p-[10px] bg-[#000000b3] rounded-[10px]">
              Completed
            </div>
          </div>
          <div className="bg-[#fff] py-[32px] mt-[20px]">
            <div className="flex justify-center">
              <div className="w-[180px] h-[180px] flex justify-center">
                <img
                  src="https://d1f8g8i172sxu4.cloudfront.net/images/successIcon.gif"
                  alt="successfull"
                />
              </div>
            </div>
            <div className="font-semibold text-[20px] leading-[25px] text-[#192850] text-center mt-[45px]">
              Successful
            </div>
            <div className="text-center mt-[10px] font-normal text-[17px] leading-[29px] text-[#5C696E]">
              You are eligible for the BNPL <br /> option. Repay on time to
              increase <br /> your credit score.
            </div>
            <div className="flex justify-center mt-[188px] md:mt-[70px] mb-[20px]">
              <button
                className="bg-[#2DB49E] rounded-[5px] px-[60px] py-[13px] font-medium text-[14px] leading-[14px] text-[#fff]"
                onClick={() => {
                  navigate("/home");
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`absolute bottom-[160px] md:bottom-[105px] right-[10px] md:right-[-10px] ${styles.duploLogoOpaque} z-[-1]`}
      >
        <img
          src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
          alt="DuploLogo"
        />
      </div>
    </div>
  );
}

export default KycCompleted;

import React from "react";
import styles from "./Loader.module.scss";

const Loader = () => {
  return (
    <div className={styles.shimmer}>
      <div className={styles.wrapper}>
        <div
          className={`${styles.stroke} ${styles.animate} ${styles.title}`}
        ></div>
        <div
          className={`${styles.stroke} ${styles.animate} ${styles.link}`}
        ></div>
        <div
          className={`${styles.stroke}  ${styles.animate} ${styles.description}`}
        ></div>
      </div>
    </div>
  );
};

export default Loader;

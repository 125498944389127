import { useState } from "react";
import { useLocation } from "react-router-dom";
import { validate } from "../../Utility/Validations";
import { api } from "../../Service/AuthService";
import ResetSuccessModal from "./ResetSuccessModal";
import { ReactComponent as DisplayPassword } from "../svg/passwordShow.svg";
import { ReactComponent as HidePassword } from "../svg/passwordHide.svg";

function ForgotPassword() {
  const { state } = useLocation();
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const [isForgotPasswordSuccess, setIsForgotPasswordSuccess] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<Boolean>(false);

  const [errors, setErrors] = useState({
    password: "",
    cPassword: "",
  });

  const [inputValues, setInputValues] = useState({
    password: "",
    cPassword: "",
  });

  const validateInput = (e: { target: { name: string; value: any } }) => {
    if (e.target.name === "cPassword") {
      setErrors({
        ...errors,
        [e.target.name]: validate(
          e.target.name,
          e.target.value,
          inputValues.password
        ),
      });
    } else {
      setErrors({
        ...errors,
        [e.target.name]: validate(e.target.name, e.target.value),
      });
    }
  };

  const handleInput = (e: { target: { name: string; value: any } }) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const forgotPassword = () => {
    setErrors({
      password: "",
      cPassword: "",
    });

    api
      .post(
        `/phone/forgot-password`,
        {
          password: inputValues.password,
          confirm_password: inputValues.cPassword,
          phone_number: state?.phoneNumber,
        },
        {
          headers: {
            dpotpauth: state?.otpToken,
          },
        }
      )
      .then(() => {
        setIsForgotPasswordSuccess(true);
      })
      .catch((err) => {
        const responseErrorsData = err?.response?.data?.errors;

        setErrors({
          password: responseErrorsData?.password,
          cPassword: responseErrorsData?.confirm_password,
        });
      });
  }

  return (
    <>
      {!isForgotPasswordSuccess ? (
        <div className="pt-[40px] px-[27px] w-full md:w-10/12 rounded-t-[30px] md:rounded-[30px] relative z-10 bg-transparent">
          <div className="flex justify-center text-[24px] leading-[30px] font-semibold text-[#103129] mb-[40px]">
            Reset Password
          </div>
          <div className="mb-[5px] formGrp">
            <div className="text-[14px] text-[#192850] mb-[10px]">
              {" "}
              New Password
            </div>
            <div className="flex items-center">
              <input
                className="border rounded w-full py-2 pl-3 pr-8 h-[40px] leading-tight focus:outline-none"
                name="password"
                id="password"
                type={showPassword ? "text" : "password"}
                autoComplete="off"
                value={inputValues.password}
                onChange={(e) => handleInput(e)}
                onBlur={(e) => validateInput(e)}
                required
              />
              <div
                className="ml-[-35px] cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <HidePassword /> : <DisplayPassword />}
              </div>
            </div>
            <div className="text-[12px] text-[#FF0000] min-h-[20px]">{errors.password}</div>
          </div>
          <div className="mb-[5px] formGrp">
            <div className="text-[14px] text-[#192850] mb-[10px]">
              Confirm New Password
            </div>
            <div className="flex items-center">
              <input
                className="border rounded w-full py-2 pl-3 pr-8 h-[40px] leading-tight focus:outline-none"
                id="confirmPassword"
                name="cPassword"
                type={showConfirmPassword ? "text" : "password"}
                autoComplete="off"
                value={inputValues.cPassword}
                onChange={(e) => handleInput(e)}
                onBlur={(e) => validateInput(e)}
                required
              />
              <div
                className="ml-[-35px] cursor-pointer"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <HidePassword /> : <DisplayPassword />}
              </div>
            </div>
            <div className="text-[12px] text-[#FF0000] min-h-[20px]">{errors.cPassword}</div>
          </div>
          <div className="my-[30px]">
            <button
              className={`w-full rounded-md font-bold text-[14px] text-[#fff] py-[12px] bg-[#2DB49E]`}
              onClick={() => forgotPassword()}
            >
              Reset Password
            </button>
          </div>
        </div>
      ) : (
        <ResetSuccessModal />
      )}
    </>
  );
}

export default ForgotPassword;

import ReactPaginate from 'react-paginate'
const Pagination = (props: any) => {
  const { pageCount, handlePaginationChange } = props
  return (
    <div className='z-10'>
      <ReactPaginate
        previousLabel={
          <div>
            <svg
              width="5"
              height="10"
              viewBox="0 0 5 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 8.5L1 5L4 1.5"
                stroke="#ABB7BF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        }
        nextLabel={
          <div>
            <svg
              width="5"
              height="10"
              viewBox="0 0 5 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.5L4 5L1 8.5"
                stroke="#ABB7BF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        }
        onPageChange={handlePaginationChange}
        pageCount={pageCount}
        marginPagesDisplayed={0}
        pageRangeDisplayed={4}
        disabledClassName={'text-[#5C696E99]'}
        containerClassName={
          'pagination flex gap-[10px] items-center font-medium text-[14px] leading-[18px] text-[#5C696E99]'
        }
        activeClassName={'active text-[#5C696E]'}
      />
    </div>
  )
}

export default Pagination

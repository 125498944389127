import React from "react";
import { ReactComponent as Warning } from "../../svg/warning.svg";
import { ReactComponent as DuploLogo } from "../../svg/duploLogo.svg";
import { ReactComponent as BnplLogo } from "../../svg/bnplLogo.svg";
import { ReactComponent as GreenRightArrow } from "../../svg/greenRightArrow.svg";
import styles from './PaymentError.module.scss';
import { useCheckoutContext } from "../../../Context/CheckoutRef";
import { CurrencyFormat } from "../../../Utility/CurrencyFormat";

function NotEligibleError() {
  const { checkoutDetails } = useCheckoutContext()
  return (
    <>
      <div
        className="container md:px-[35px]"
        style={{ minHeight: "calc(100vh - 55px)" }}
      >
        <div className="flex items-start justify-between mt-[20px] md:pb-[20px] md:border-b-[1px] md:border-b-[#0000001a]">
          <div className="flex items-center">
            <span>
              <DuploLogo />
            </span>
            <span className="ml-[9px] text-[20px] leading-[25px] font-medium text-[#5C696E]">
              Duplo
            </span>
          </div>
          <div>
            <div className="text-[20px] font-medium leading-[25px] text-[#003F34] text-right">
              { CurrencyFormat(checkoutDetails?.amount) }
            </div>
            <div className="text-end font-light text-[14px] leading-[22px] text-[#5C696E] mt-[6px]">
              {checkoutDetails?.email}
            </div>
          </div>
        </div>

        <div className="mx-[-15px] bg-[#103129] flex md:hidden items-center justify-between px-[15px] py-[17px] mt-[45px] cursor-pointer">
          <div className="flex items-center">
            <span>
              <BnplLogo />
            </span>
            <span className="font-normal text-[#fff] text-[14px] leading-[14px] ml-[14px]">
              Buy Now Pay Later
            </span>
          </div>
          <div className="flex items-center">
            <span className="font-normal text-[14px] leading-[22px] text-[#5C696E] mr-[6px]">
              Change payment method
            </span>
            <span>
              <GreenRightArrow />
            </span>
          </div>
        </div>
        <div className="flex justify-center mt-[42px] md:mt-[63px]">
          <Warning />
        </div>
        <div className="flex justify-center mt-[22px] font-medium text-[16px] leading-[20px] text-[#000000]">
          Unfortunately {checkoutDetails?.first_name}
        </div>
        <div
          className={`mt-[30px] mb-[50px] text-center font-light text-[14px] leading-[22px] text-[#5C696E] ${styles.lineBreak}`}
        >
          You are not eligible for this loan offer from us based on your <br />{" "}
          credit score, please use another payment method to <br /> complete
          your transaction
        </div>
      </div>
    </>
  );
}

export default NotEligibleError;

import React from "react";
import styles from "./LoanHistory.module.scss";
import { ReactComponent as ModalCloseIcon } from "../svg/modalCloseIcon.svg";
import { ReactComponent as Caution } from "../svg/caution.svg";

interface StampdutyModalProps {
  shown: boolean;
  close: () => void;
  toggleStampDutyModal: Function;
}

const StampdutyModal: React.FC<StampdutyModalProps> = ({
  shown,
  close,
  toggleStampDutyModal,
}) => {
  return shown ? (
    <div
      className={`${styles.modalBackdrop}`}
      onClick={() => {
        close();
      }}
    >
      <div
        className={`${styles.modalContent}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <div className="flex items-center justify-between bg-[#F9F9F9] w-full rounded-t-[10px] py-[30px] px-[20px]">
            <div className="font-semibold text-[18px] leading-[14px] text-[#162930]">
              Important Update
            </div>
            <button className="text-[#000]" onClick={close}>
              <ModalCloseIcon />
            </button>
          </div>

          <div className="flex justify-center my-4">
            <Caution width={60} />
          </div>
          <div className="max-w-[350px] text-center flex mx-auto mb-5">
            <p className="text-[15px] leading-[24px]">
              In line with the Electronic Money Transfer Levy Regulations 2022,
              a fixed levy of N50 will be added to your repayment amount as it
              is statutorily required on every transfer of N10,000 or more.
            </p>
          </div>
          <div className="bg-[#F9F9F9] rounded-b-[10px] pt-[28px] pb-[36px] flex justify-center">
            <button
              className="text-[#FFFFFF] text-[14px] leading-[14px] font-normal bg-[#2DB49E] py-[13px] px-[44px] rounded-[5px]"
              onClick={() => toggleStampDutyModal()}
            >
              I understand
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default StampdutyModal;

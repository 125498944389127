import styles from "./HomePage.module.scss";
import { ReactComponent as ModalCloseIcon } from "../svg/modalCloseIcon.svg";
import { useState } from "react";

const CurrentBusinessDropdown = ({ businessList, setAccountRef }: any) => {
  const [isCurrentBusinessModal, setIsCurrentBusinessModal] = useState(false);
  const [selected, setIsSelected] = useState("");
  const getTruncatedString = (business: string) => {
    if (business?.length <= 13) {
      return business;
    } else {
      return business?.substring(0, 13)?.concat("...");
    }
  };
  return (
    <>
      <div>
        <div
          className="min-w-[180px] border-[3px] border-[#1A4230] rounded-[8px] bg-[#103129]"
          onClick={() => {
            setIsCurrentBusinessModal(true);
          }}
        >
          <div className="flex items-center justify-between px-[10px] py-[20px] text-[#fff]">
            <div className="text-[18px] font-medium leading-[14px] min-h-[15px]">
              {selected ? (
                <div className="text-[#fff]">
                  {" "}
                  {getTruncatedString(selected)}{" "}
                </div>
              ) : (
                <div className="text-[#fff]">
                  {getTruncatedString(businessList?.[0]?.label)}
                </div>
              )}
            </div>
            <div>
              {businessList && businessList.length > 1 && (
                <svg
                  width="14"
                  height="7"
                  viewBox="0 0 14 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 0L7 7L6.11959e-07 -1.22392e-06L14 0Z"
                    fill="#05CD99"
                  />
                </svg>
              )}
            </div>
          </div>
        </div>
        {isCurrentBusinessModal && (
          <div>
            <div
              className={styles.modalBackdrop}
              onClick={() => {
                setIsCurrentBusinessModal(false);
              }}
            >
              <div
                className={`${styles.currentBusinessModal}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className="bg-[#C5C5C5] w-full rounded-t-[10px] flex justify-between px-[10px] py-[20px] items-center">
                  <div className="font-semibold text-[18px] leading-[18px]">
                    Select Business
                  </div>
                  <div
                    onClick={() => {
                      setIsCurrentBusinessModal(false);
                    }}
                  >
                    <ModalCloseIcon />
                  </div>
                </div>
                {businessList && businessList.length > 1 && (
                  <div className="max-h-[250px] overflow-y-auto">
                    {businessList?.length === 0 ? (
                      <div className="py-[30px] px-[30px]">
                        No business available
                      </div>
                    ) : (
                      businessList.map((item: any) => (
                        <div
                          className="flex justify-between px-[10px] py-[35px] border-b-[#0000001a] border-b-[1px]"
                          onClick={() => {
                            setIsSelected(item.label);
                            setAccountRef(item.value);
                            setIsCurrentBusinessModal(false);
                          }}
                        >
                          <div
                            className={`text-[18px] leading-[14px] ${
                              selected === item.label
                                ? "font-medium"
                                : "font-normal"
                            }`}
                          >
                            {item.label}
                          </div>
                          <div>
                            <svg
                              width="8"
                              height="12"
                              viewBox="0 0 8 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.99997 0L0.589966 1.41L5.16997 6L0.589966 10.59L1.99997 12L7.99997 6L1.99997 0Z"
                                fill="#2DB49E"
                              />
                            </svg>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CurrentBusinessDropdown;

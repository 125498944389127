import styles from './NumberVerificationLoader.module.scss';
import { ReactComponent as DuploTextLogo } from '../../svg/duploTextLogo.svg';
import { ReactComponent as DuploLogoNoBg } from '../../svg/duploLogoNoBg.svg';

function NumberVerificationLoading() {
  return (
    <div className={styles.modalBackdrop}>
      <div className={`flex items-center justify-center ${styles.modalContent}`}>
        <div className="flex items-center">
          <DuploTextLogo />
          <div className="ml-[5px]">
            <DuploLogoNoBg />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NumberVerificationLoading;

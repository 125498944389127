import React from 'react';
import SideBar from '../SideBar/SideBar';
import PaymentSuccessConfirmation from './PaymentSuccess';

function PaymentSuccess() {
    return (
        <div className="min-h-screen flex items-center w-full">
            <div className="hidden md:block basis-[30%]">
                <SideBar />
            </div>
            <div className="w-full">
                <PaymentSuccessConfirmation />
            </div>
        </div>
    );
}

export default PaymentSuccess;
import { useNavigate } from "react-router";
import SideBar from "../SideBar/SideBar";
import styles from "./Kyc.module.scss";
import { useUserContext } from "../../Context/UserContext";

function KycRejected() {
  const navigate = useNavigate();
  const userContextUtil = useUserContext();
  const kycDetails = userContextUtil.kycDetails;

  return (
    <div className="md:flex md:items-center md:justify-center relative">
      <div className="hidden md:block z-50">
        <SideBar />
      </div>
      <div
        className={`w-full md:w-4/6 md:my-[50px] md:rounded-[20px] md:ml-[-100px] md:border-[#2db49e80] md:border-[1px] overflow-y-auto ${styles.pageHeight}  bg-[#2db49e1a] md:bg-transparent`}
      >
        <div className="py-[20px] md:py-[30px] md:pl-[165px] md:pr-[40px] md:min-h-[690px]">
          <div
            className="flex items-center px-[15px]  cursor-pointer"
            onClick={() => {
              navigate("/profile");
            }}
          >
            <div>
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.58984 12L7.99984 10.59L3.41984 6L7.99984 1.41L6.58984 0L0.589844 6L6.58984 12Z"
                  fill="#2DB49E"
                />
              </svg>
            </div>
            <div className="ml-[30px] mr-[10px] text-[#192850] font-semibold text-[20px] leading-[25px]">
              KYC
            </div>
            <div className="font-normal text-[16px] text-[#ED5B5C] p-[10px] bg-[#ed5b5c29] rounded-[10px]">
              Rejected
            </div>
          </div>
          <div className="flex justify-center mt-[70px]">
            <div
              className="min-w-full md:min-w-[450px] min-h-[234px] flex justify-center items-center border-[1px] border-[#EEEEEE] 
          rounded-[5px] text-center font-normal text-[18px] leading-[29px] text-[#5C696E] bg-[#fff]"
            >
              <div className="max-w-[400px]">
                {kycDetails && kycDetails?.rejection_message ? (
                  kycDetails?.rejection_message
                ) : (
                  <>
                    We are sorry we could not approve your business for loan
                    access.
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`absolute bottom-[160px] md:bottom-[105px] right-[10px] md:right-[-10px] ${styles.duploLogoOpaque} z-[-1]`}
      >
        <img
          src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
          alt="DuploLogo"
        />
      </div>
    </div>
  );
}

export default KycRejected;

import { useEffect, useRef, useState } from "react";
import { api } from "../../Service/AuthService";
import NextRepayment from "./NextRepayment";
import NoActiveLoans from "./NoActiveLoans";
import Loader from "../Loader";
const ActiveLoans = () => {
  const dataFetchedRef = useRef(false);
  const [loans, setLoans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getActiveLoans = async () => {
    await api
      .get("/active-loan")
      .then((response) => {
        const responseData = response?.data?.data;
        const loans = responseData?.loans;
        setLoans(loans);
        setIsLoading(true);
      })
      .finally(() => {
        setIsLoading(true);
      });
  };
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getActiveLoans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {isLoading ? (
        <>
          {loans?.length > 0 ? (
            <>
              <div className="mt-[20px] flex justify-between items-center"></div>
              {<NextRepayment loans={loans[0]} />}
            </>
          ) : (
            <NoActiveLoans />
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ActiveLoans;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DuploLogo } from "../../svg/duploLogo.svg";
import { ReactComponent as BnplLogo } from "../../svg/bnplLogo.svg";
import { ReactComponent as GreenRightArrow } from "../../svg/greenRightArrow.svg";
import { ReactComponent as GreenCheck } from "../../svg/greenCheck.svg";
import NumberVerificationLoading from "../NumberVerificationLoader/NumberVerificationLoading";
import { api } from "../../../Service/AuthService";
import { useCheckoutContext } from "../../../Context/CheckoutRef";
import CheckoutFooter from "../../CheckoutFooter";
import { CurrencyFormat } from "../../../Utility/CurrencyFormat";

function CheckoutVerifyPhoneNumber() {
  const navigate = useNavigate();
  const { setCheckoutRef, setCheckoutDetails, checkoutDetails } =
    useCheckoutContext();

  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  function handleInput(e: { target: { name: string; value: any } }) {
    setInputValue(e.target.value);
  }

  const [showLoader, setShowLoader] = useState<Boolean>(false);

  function validateNumber() {
    api
      .post(`/phone/validate`, {
        phone_number: inputValue,
      })
      .then((response) => {
        const responseData = response?.data?.data;
        const isPhoneNumberExists = responseData?.exists;

        const payload = {
          isPhoneNumberExists: isPhoneNumberExists ? true : false,
          phoneNumber: inputValue,
          isLanding: true,
        };

        if (isPhoneNumberExists) {
          navigate("/sign-in", {
            state: payload,
          });
        } else {
          navigate("/sign-up", {
            state: payload,
          });
        }
      })
      .catch((err) => {
        const responseErrorsData =
          err && err.response && err.response.data && err.response.data.errors;
        setError(responseErrorsData?.phone_number);
      });
  }

  useEffect(() => {
    const initiateCheckout = async () => {
      setShowLoader(true);
      const url = window.location.href;
      const convertedUrl = new URL(url);
      const checkoutRefParam = convertedUrl.searchParams.get("reference");

      await api
        .get(`/checkout`, {
          params: {
            checkout_token: checkoutRefParam,
          },
        })
        .then((response) => {
          const responseData = response?.data?.data;
          const checkout = responseData?.checkout;
          const ref = checkout?.checkout_ref;
          setCheckoutDetails({
            first_name: checkout?.customer_firstname,
            last_name: checkout?.customer_lastname,
            email: checkout?.customer_email,
            amount: checkout?.amount,
            currency: checkout?.currency,
            redirectUrl: checkout?.redirect_url,
          });
          setCheckoutRef(ref);
        })
        .catch(() => {
          navigate("/checkout/bnpl/error", {
            state: {
              CHECKOUT_ERROR: true,
            },
          });
        })
        .finally(() => {
          setShowLoader(false);
        });
    };

    initiateCheckout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const amountPayable = CurrencyFormat(checkoutDetails?.amount);
  return (
    <div className=''>
      <div
        className='container md:px-[35px]'
        style={{ minHeight: "calc(100vh - 105px)" }}
      >
        {showLoader && <NumberVerificationLoading />}
        <div className='flex items-start justify-between mt-[20px] md:pb-[21px] md:border-b-[1px] md:border-b-[#0000001a]'>
          <div className='flex items-center'>
            <span>
              <DuploLogo />
            </span>
            <span className='ml-[9px] text-[20px] leading-[25px] font-medium text-[#5C696E]'>
              Duplo
            </span>
          </div>
          <div>
            {amountPayable && (
              <div className='text-[20px] font-medium leading-[25px] text-[#003F34] text-right'>
                {amountPayable}
              </div>
            )}
            <div className='text-end font-light text-[14px] leading-[22px] text-[#5C696E] mt-[6px]'>
              {checkoutDetails?.email}
            </div>
          </div>
        </div>

        <div className='mx-[-15px] bg-[#103129] flex md:hidden items-center justify-between px-[15px] py-[17px] mt-[45px] cursor-pointer'>
          <div className='flex items-center'>
            <span>
              <BnplLogo />
            </span>
            <span className='font-normal text-[#fff] text-[14px] leading-[14px] ml-[14px]'>
              Buy Now Pay Later
            </span>
          </div>
          <div className='flex items-center'>
            <span className='font-normal text-[14px] leading-[22px] text-[#5C696E] mr-[6px]'>
              Change payment method
            </span>
            <span>
              <GreenRightArrow />
            </span>
          </div>
        </div>
        <div className='hidden md:block font-medium text-[#000] text-[16px] leading-[14px] mt-[16px]'>
          Buy Now Pay Later
        </div>
        <div className='text-center md:text-start mt-[37px] md:mt-[16px] font-light text-[14px] leading-[22px] text-[#5C696E]'>
          Input your phone number to start your credit process.
        </div>
        <div className='my-[28px]'>
          <div className='flex items-center md:my-[10px] border-[1px] border-[#DCDDE6] rounded-[5px] w-full md:w-2/6'>
            <div className='h-[40px] p-[11px] font-light text-[12px] leading-[18px] text-[#5C696E] bg-[#DCDDE5] rounded-l-[3px]'>
              NGN
            </div>
            <input
              className='py-2 px-3 h-[40px] leading-tight focus:outline-none'
              id='CheckoutPhoneNumber'
              type='text'
              name='number'
              placeholder='Phone Number'
              autoComplete='off'
              maxLength={11}
              onChange={(e) => handleInput(e)}
              required
            />
          </div>
          <div className='text-[12px] text-[#FF0000] min-h-[20px]'>{error}</div>
        </div>
        <div className='flex justify-center md:justify-start'>
          <button
            className='text-[#fff] bg-[#2DB49E] px-[62px] py-[13px] border-[1px] rounded-[5px]'
            onClick={() => validateNumber()}
          >
            Continue
          </button>
        </div>
        <div className='mt-[37px] bg-[#2db49e14] px-[10px] py-[20px] rounded-[10px]'>
          <div className='font-medium	text-[14px] leading-[14px] text-[#000000]'>
            Steps
          </div>
          <div className='flex items-start md:items-center mt-[15px]'>
            <span>
              <GreenCheck />
            </span>
            <span className='font-light text-[12px] leading-[18px] text-[#5C696E] ml-[8px]'>
              Input your phone number
            </span>
          </div>
          <div className='flex items-start md:items-center my-[12px]'>
            <span>
              <GreenCheck />
            </span>
            <span className='font-light text-[12px] leading-[18px] text-[#5C696E] ml-[8px]'>
              Our lending partner makes a decision based on your credit history
            </span>
          </div>
          <div className='flex items-start md:items-center'>
            <span>
              <GreenCheck />
            </span>
            <span className='font-light text-[12px] leading-[18px] text-[#5C696E] ml-[8px]'>
              Complete your purchase
            </span>
          </div>
        </div>
      </div>
      <CheckoutFooter />
    </div>
  );
}

export default CheckoutVerifyPhoneNumber;

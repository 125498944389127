import React from "react";
import { useCheckoutContext } from "../../../Context/CheckoutRef";
import { ReactComponent as Warning } from '../../svg/warning.svg'
import { ReactComponent as DuploLogo } from '../../svg/duploLogo.svg'
import { ReactComponent as BnplLogo } from '../../svg/bnplLogo.svg'
import { ReactComponent as GreenRightArrow } from '../../svg/greenRightArrow.svg'
import { CurrencyFormat } from "../../../Utility/CurrencyFormat";
import SideBar from '../SideBar/SideBar'
import styles from './RejectedOffer.module.scss'

const RejectedOffer = () => {
    const { checkoutDetails} = useCheckoutContext()
  return (
    <>
      <div className="min-h-screen flex items-center w-full">
        <div className="hidden md:block basis-[30%]">
          <SideBar />
        </div>
        <div
          className="container md:px-[35px] w-full"
          style={{ minHeight: 'calc(100vh - 55px)' }}
        >
          <div className="flex items-start justify-between mt-[20px] md:pb-[20px] md:border-b-[1px] md:border-b-[#0000001a]">
            <div className="flex items-center">
              <span>
                <DuploLogo />
              </span>
              <span className="ml-[9px] text-[20px] leading-[25px] font-medium text-[#5C696E]">
                Duplo
              </span>
            </div>
            <div>
              <div className="text-[20px] font-medium leading-[25px] text-[#003F34] text-right">
                {CurrencyFormat(checkoutDetails?.amount)}
              </div>
              <div className="text-end font-light text-[14px] leading-[22px] text-[#5C696E] mt-[6px]">
                {checkoutDetails?.email}
              </div>
            </div>
          </div>

          <div className="mx-[-15px] bg-[#103129] flex md:hidden items-center justify-between px-[15px] py-[17px] mt-[45px] cursor-pointer">
            <div className="flex items-center">
              <span>
                <BnplLogo />
              </span>
              <span className="font-normal text-[#fff] text-[14px] leading-[14px] ml-[14px]">
                Buy Now Pay Later
              </span>
            </div>
            <div className="flex items-center">
              <span className="font-normal text-[14px] leading-[22px] text-[#5C696E] mr-[6px]">
                Change payment method
              </span>
              <span>
                <GreenRightArrow />
              </span>
            </div>
          </div>
          <div className="flex justify-center mt-[42px] md:mt-[63px]">
            <Warning />
          </div>
          <div className="flex justify-center mt-[22px] font-medium text-[16px] leading-[20px] text-[#000000]">
          Unable to process request
          </div>
          <div
            className={`mt-[30px] text-center font-light text-[14px] leading-[22px] text-[#5C696E] ${styles.lineBreak}`}
          >
           Unfortunately {checkoutDetails?.first_name}, we are unable to process your request any further.
          </div>
          
        </div>
      </div>
    </>
  );
};

export default RejectedOffer;

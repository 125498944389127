import { ReactComponent as DuploLogoNoBg } from '../svg/duploLogoNoBg.svg'
import { ReactComponent as DuploTextLogo } from '../svg/duploTextLogo.svg'
const Loader = () => {
    return (
        <div className="cursor-pointer border-[1px] border-[#DCDDE6] rounded-[5px] p-[20px] bg-[#fff] h-[300px] mt-[20px] bg-[#00000070]">
          <span className="text-[#192850] flex justify-center mt-[80px] z-100">
            <div className="flex items-center w-[90px] h-[90px] bg-[#fff] rounded-[100px] pl-[10px]">
              <DuploTextLogo />
              <div className="ml-[5px]">
                <DuploLogoNoBg />
              </div>
            </div>
          </span>
        </div>
    );
}

export default Loader;
import SideBar from "../SideBar/SideBar";
import PaymentError from "./PaymentError";

function InvalidLoan() {
    return (
        <div className="min-h-screen flex items-center w-full">
            <div className="hidden md:block basis-[30%]">
                <SideBar />
            </div>
            <div className="w-full">
                <PaymentError />
            </div>
        </div>
    );
}

export default InvalidLoan;
import { useUserContext } from "../../Context/UserContext";
import LoggedInBottomBar from "../BottomBar/LoggedInBottomBar";
import SideBar from "../SideBar/SideBar";
import HomeTab from "./Home";
import styles from "./HomePage.module.scss";
import InsufficientSaleHistory from "./InsufficientSaleHistory";

function Home() {
  const { kycDetails } = useUserContext();
  if (!kycDetails?.sales_data_availability_status) {
    return <InsufficientSaleHistory />;
  } else {
    return (
      <div className="md:flex md:items-center md:justify-center relative">
        <div className="hidden md:block z-[1]">
          <SideBar />
        </div>
        <div
          className={`bg-[#F4F9F5] md:bg-transparent w-full md:w-4/6 md:my-[50px] md:rounded-[20px] md:ml-[-100px] md:border-[#2db49e80] md:border-[1px] overflow-y-auto ${styles.pageHeight}`}
        >
          <HomeTab />
        </div>
        <div className="md:hidden w-full">
          <LoggedInBottomBar />
        </div>
        <div
          className={`absolute bottom-[160px] md:bottom-[105px] right-[10px] md:right-[-10px] ${styles.duploLogoOpaque} md:z-[-1] z-[2]`}
        >
          <img
            src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
            alt="DuploLogo"
          />
        </div>
      </div>
    );
  }
}

export default Home;

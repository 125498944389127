import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate, useLocation } from "react-router-dom";
import { api } from "../../Service/AuthService";
import styles from "./VerifyOtp.module.scss";
import { useUserContext } from "../../Context/UserContext";
import { useCheckoutContext } from "../../Context/CheckoutRef";

const OtpVerificationForm = () => {
  const navigate = useNavigate();
  const { checkoutRef } = useCheckoutContext();
  const { setUserProfile } = useUserContext();
  const { state } = useLocation();
  const [otp, setOtp] = useState<string>("");
  const otpInputFunction = (value: string) => {
    setOtp(value);
  };
  const [isOtpVerified, setOtpVerified] = useState<Boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  var otpLength = otp?.length;

  let encryptedPhoneNumber =
    "*** *** *" + state?.phoneNumber.substr(7, state?.phoneNumber.length);

  const time = `0${minutes}m:${seconds < 10 ? "0" : ""}${seconds}s`;

  const resendOtpTimer = () => {
    setMinutes(4);
    setSeconds(60);

    api
      .post(`/phone/get-otp`, {
        phone_number: state?.phoneNumber,
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function verifyOtp() {
    setIsLoading(true);
    if (state?.purpose === "SIGN_UP") {
      signUpApi();
    } else {
      verifyOtpApi();
    }
  }
  function signUpApi() {
    const payload = {
      ...state?.userInfo,
      otp: otp,
    };

    if (checkoutRef) {
      payload.checkout_ref = checkoutRef;
    }

    api
      .post(`/phone/signup`, payload)
      .then((response) => {
        setOtpVerified(true);
        const responseData = response?.data?.data;
        const currentUser = responseData?.current_user;

        if (
          currentUser.sales_data_status === "DATA_PRESENT" ||
          currentUser.sales_data_status === "PENDING_SALES_DATA_SYNC"
        ) {
          navigate("/processing-sales-history");
        } else {
          setUserProfile(currentUser);
          navigate("/logged-in");
        }
      })
      .catch((err) => {
        const errMessage =
          err && err.response && err.response.data && err.response.data.message;
        if (errMessage) {
          setOtpVerified(false);
          setErrorMsg(errMessage);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  function verifyOtpApi() {
    api
      .post(`/phone/verify-otp`, {
        phone_number: state?.phoneNumber,
        otp: otp,
      })
      .then((response) => {
        const otpToken = response?.data?.data?.otpToken;
        if (otpToken) {
          setOtpVerified(true);
          if (state?.isForgotPassword) {
            navigate("/forgot-password", {
              state: {
                otpToken: otpToken,
                phoneNumber: state?.phoneNumber,
                isForgotPassword: true,
              },
            });
          } else {
            navigate("/reset-password", {
              state: {
                otpToken: otpToken,
                phoneNumber: state?.phoneNumber,
              },
            });
          }
        }
      })
      .catch((err) => {
        const errMessage = err?.response?.data?.message;
        if (errMessage) {
          setOtpVerified(false);
          setErrorMsg(errMessage);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  useEffect(() => {
    setErrorMsg("");
    setOtpVerified(true);
  }, [otp]);

  return (
    <>
      <div className="rounded-t-[30px] md:rounded-[30px] pt-[40px] pb-[80px] relative z-10">
        <div className="flex justify-center">
          <div className="font-semibold text-[24px] leading-[30px] text-[#103129]">
            <span className="md:hidden block">Enter OTP</span>
            <span className="md:block hidden">ENTER OTP</span>
          </div>
        </div>
        <div className="mt-[70px] text-center font-normal text-[14px] leading-[17px] text-[#192850]">
          Please enter 6-digit OTP sent to {encryptedPhoneNumber}
        </div>
        <div
          className={`flex justify-center mt-[40px] ${
            isOtpVerified ? styles.otpVerified : styles.otpNotVerified
          }`}
        >
          <div>
            <OtpInput
              isInputNum={true}
              value={otp}
              onChange={otpInputFunction}
              numInputs={6}
              separator={<span> </span>}
            />
            <div className="min-h-[17px]">
              <div className="text-[#2DB49E] text-[10px] font-normal leading-[17px] mt-[5px] ml-[7px]">
                {isOtpVerified && otpLength === 6 && ""}
              </div>
              <div className="text-[#ED5B5C] text-[10px] font-normal leading-[17px] mt-[5px] ml-[7px]">
                {!isOtpVerified && otpLength === 6 && errorMsg}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center mt-[35px]">
          <button
            className="font-normal text-[14px] text-[#2DB49E] leading-[18px] underline underline-offset-4  py-[10px]"
            disabled={seconds > 0 || minutes > 0 ? true : false}
            onClick={resendOtpTimer}
          >
            Resend OTP {minutes >= 0 && seconds > 0 && `in ${time}`}
          </button>

          <button
            className={`w-full rounded-md font-semibold text-[14px] text-[#fff] py-[12px] mt-5 leading-[16px] ${
              errorMsg || isLoading || otp.length < 6
                ? "bg-[#BABFC1]"
                : "bg-[#2DB49E]"
            }`}
            type="submit"
            onClick={verifyOtp}
            disabled={errorMsg !== "" || isLoading || otp.length < 6}
          >
            Verify
          </button>
        </div>
      </div>
    </>
  );
};

export default OtpVerificationForm;

import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { validate } from "../../Utility/Validations";
import { api } from "../../Service/AuthService";
import { ReactComponent as DisplayPassword } from "../svg/passwordShow.svg";
import { ReactComponent as HidePassword } from "../svg/passwordHide.svg";
import styles from "./SignUp.module.scss";
import PrivacyAndTermsModal from "../PrivacyAndTermsModal/PrivacyAndTermsModal";
import { trackEventForHotjar } from "../../Utility/hotjat";

function SignUp(props: any) {
  const navigate = useNavigate();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const business_id = urlParams.get("business_id");
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<Boolean>(false);
  const [checkboxValue, setCheckboxValue] = useState<Boolean>(false);
  const [modalShown, toggleModal] = useState(false);

  const [errors, setErrors] = useState({
    number: "",
    email: "",
    password: "",
    cPassword: "",
    first_name: "",
    last_name: "",
  });

  const [inputValues, setInputValues] = useState({
    number: "",
    email: "",
    password: "",
    cPassword: "",
    first_name: "",
    last_name: "",
  });

  function handleInput(e: { target: { name: string; value: any } }) {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value.trim() });
  }

  function validateInput(e: { target: { name: string; value: any } }) {
    if (e.target.name === "cPassword") {
      setErrors({
        ...errors,
        [e.target.name]: validate(
          e.target.name,
          e.target.value,
          inputValues.password
        ),
      });
    } else {
      setErrors({
        ...errors,
        [e.target.name]: validate(e.target.name, e.target.value),
      });
    }
  }

  function validateSignUp() {
    setErrors({
      number: "",
      email: "",
      password: "",
      cPassword: "",
      first_name: "",
      last_name: "",
    });
    const userInfo: {
      phone_number: string;
      email: string;
      password: string;
      confirm_password: string;
      business_id?: string;
      first_name?: string;
      last_name?: string;
    } = {
      phone_number: inputValues.number,
      email: inputValues.email,
      password: inputValues.password,
      confirm_password: inputValues.cPassword,
    };

    if (business_id) {
      if (inputValues.first_name || inputValues.last_name) {
        if (
          validate("last_name", inputValues.last_name) !== "" ||
          validate("first_name", inputValues.first_name) !== ""
        ) {
          setErrors({
            ...errors,
            first_name: validate("first_name", inputValues.first_name),
            last_name: validate("last_name", inputValues.last_name),
          });

          return;
        }
      }
      userInfo.business_id = business_id;
      userInfo.first_name = inputValues.first_name;
      userInfo.last_name = inputValues.last_name;
    }

    api
      .post(`/phone/validate-signup`, userInfo)
      .then((response) => {
        getOtp(userInfo);
      })
      .catch((err) => {
        const signupApiResErrors =
          err && err.response && err.response.data && err.response.data.errors;
        setErrors({
          number: signupApiResErrors?.phone_number,
          email: signupApiResErrors?.email,
          password: signupApiResErrors?.password,
          cPassword: signupApiResErrors?.confirm_password,
          first_name: signupApiResErrors?.first_name,
          last_name: signupApiResErrors?.last_name,
        });
      });
  }

  function getOtp(userInfo: any) {
    api
      .post(`/phone/get-otp`, {
        phone_number: inputValues.number,
      })
      .then((response) => {
        trackEventForHotjar("OTP sent");
        navigate("/verify-otp", {
          state: {
            phoneNumber: inputValues.number,
            userInfo: userInfo,
            purpose: "SIGN_UP",
            isSignUp: true,
          },
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const formSubmit = (e: any) => {
    e.preventDefault();
    validateSignUp();
  };

  useEffect(() => {
    setInputValues({
      number: props?.phoneNumber || "",
      email: "",
      password: "",
      cPassword: "",
      first_name: "",
      last_name: "",
    });
  }, [props?.phoneNumber]);

  return (
    <div className="w-full md:w-10/12 relative z-10 bg-transparent">
      <div className={`py-[40px] px-[27px]`}>
        <div className="flex justify-center text-[24px] leading-[30px] font-semibold text-[#103129] mb-[40px]">
          Lets get you started
        </div>
        <div>
          <form onSubmit={(e) => formSubmit(e)}>
            {business_id && (
              <div className="mb-[5px] formGrp">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <div className="text-[14px] text-[#192850] mb-[10px]">
                      First Name
                    </div>
                    <input
                      className="border rounded w-full py-2 px-3 h-[40px] leading-tight focus:outline-none"
                      id="firstName"
                      type="text"
                      name="first_name"
                      placeholder="First Name"
                      autoComplete="off"
                      value={inputValues.first_name}
                      onChange={(e) => handleInput(e)}
                      onBlur={(e) => validateInput(e)}
                      required
                    />
                    <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                      {errors.first_name}
                    </div>
                  </div>
                  <div>
                    <div className="text-[14px] text-[#192850] mb-[10px]">
                      Last Name
                    </div>
                    <input
                      className="border rounded w-full py-2 px-3 h-[40px] leading-tight focus:outline-none"
                      id="lastName"
                      type="text"
                      name="last_name"
                      placeholder="Last Name"
                      autoComplete="off"
                      value={inputValues.last_name}
                      onChange={(e) => handleInput(e)}
                      onBlur={(e) => validateInput(e)}
                      required
                    />
                    <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                      {errors.last_name}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="mb-[5px] formGrp">
              <div className="text-[14px] text-[#192850] mb-[10px]">
                Email Address
              </div>
              <input
                className="border rounded w-full py-2 px-3 h-[40px] leading-tight focus:outline-none"
                id="SignUpemail"
                type="email"
                name="email"
                placeholder="Email Address"
                autoComplete="off"
                value={inputValues.email}
                onChange={(e) => handleInput(e)}
                onBlur={(e) => validateInput(e)}
                required
              />
              <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                {errors.email}
              </div>
            </div>
            <div className="mb-[5px] formGrp">
              <div className="text-[14px] text-[#192850] mb-[10px]">
                Phone Number
              </div>
              <input
                className="border rounded w-full py-2 px-3 h-[40px] leading-tight focus:outline-none"
                id="SignUpPhoneNumber"
                maxLength={11}
                type="text"
                name="number"
                placeholder="Phone Number"
                autoComplete="off"
                value={inputValues.number}
                onChange={(e) => handleInput(e)}
                onBlur={(e) => validateInput(e)}
                required
              />
              <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                {errors.number}
              </div>
            </div>
            <div className="mb-[5px] formGrp">
              <div className="text-[14px] text-[#192850] mb-[10px]">
                {" "}
                Password
              </div>
              <div className="flex items-center">
                <input
                  className="border rounded w-full py-2 pl-3 pr-8 h-[40px] leading-tight focus:outline-none"
                  id="SignUppassword"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  autoComplete="off"
                  value={inputValues.password}
                  onChange={(e) => handleInput(e)}
                  onBlur={(e) => validateInput(e)}
                  required
                />
                <div
                  className="ml-[-35px] cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <HidePassword /> : <DisplayPassword />}
                </div>
              </div>
              <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                {errors.password}
              </div>
            </div>
            <div className="mb-[5px] formGrp">
              <div className="text-[14px] text-[#192850] mb-[10px]">
                Confirm Password
              </div>
              <div className="flex items-center">
                <input
                  className="border rounded w-full py-2 pl-3 pr-8 h-[40px] leading-tight focus:outline-none"
                  id="SignUpconfirmPassword"
                  name="cPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  autoComplete="off"
                  value={inputValues.cPassword}
                  onChange={(e) => handleInput(e)}
                  onBlur={(e) => validateInput(e)}
                  required
                />

                <div
                  className="ml-[-35px] cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <HidePassword /> : <DisplayPassword />}
                </div>
              </div>
              <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                {errors.cPassword}
              </div>
            </div>
            <div className="flex items-center gap-[10px]">
              <label
                className={`${styles.checkBox} flex items-center cursor-pointer`}
              >
                <input
                  type="checkbox"
                  checked={checkboxValue ? true : false}
                  onChange={() => setCheckboxValue(!checkboxValue)}
                />
                <span></span>
              </label>
              <div className="text-[14px]">
                I have read and agreed to the
                <span
                  className="text-[#2DB49E] underline underline-offset-4 cursor-pointer"
                  onClick={() => {
                    toggleModal(!modalShown);
                  }}
                >
                  {" "}
                  terms and privacy policy.
                </span>
                <PrivacyAndTermsModal
                  shown={modalShown}
                  close={() => {
                    toggleModal(false);
                  }}
                ></PrivacyAndTermsModal>
              </div>
            </div>
            <div className="my-[30px]">
              <button
                className={`w-full rounded-md font-bold text-[14px] text-[#fff] py-[12px] text-opacity-[0.8] ${
                  checkboxValue ? "bg-[#2DB49E]" : "bg-[#BABFC1]"
                }`}
                disabled={checkboxValue ? false : true}
                type="submit"
              >
                Create account
              </button>
            </div>
          </form>
        </div>
        {window.innerWidth < 768 && (
          <div className="flex justify-center text-[14px] mb-[9px]">
            <div className="text-[#192850]">Already have an account?</div>
            <Link
              to={
                business_id ? `/sign-in?business_id=${business_id}` : "/sign-in"
              }
              className="pl-[10px] text-[#2DB49E] underline underline-offset-4"
            >
              {" "}
              Sign in
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default SignUp;

import {
  businessInfoValues,
  guarantorInfoValues,
  personalInfoValues,
} from "./FormValues";

export const PersonalInfoCompletionStatus = (kycDetails: any, rejectedReasons:any) => {
  let personalInfoCount = 0;
  let personalInfoRejectedValues = 0;
  if (kycDetails) {
    Object.keys(kycDetails).forEach((value: any) => {
      if (personalInfoValues.includes(value) && kycDetails[value]) {
        personalInfoCount++;
      }
    });
  }
  if (rejectedReasons) {
    Object.keys(rejectedReasons).forEach((value: any) => {
      if (personalInfoValues.includes(value) && rejectedReasons[value]) {
        personalInfoRejectedValues++;
      }
    }); 
  }  
  return personalInfoCount - personalInfoRejectedValues;
};

export const BusinessInfoCompletionStatus = (kycDetails: any,rejectedReasons:any) => {
  let businessInfocount = 0;
  let businessInfoRejectedcount = 0;

  if (kycDetails) {
    Object.keys(kycDetails).forEach((value: any) => {
      if (businessInfoValues.includes(value) && kycDetails[value]) {
        businessInfocount++;
      }
    });
    
  }
  if (rejectedReasons) {
    Object.keys(rejectedReasons).forEach((value: any) => {
      if (businessInfoValues.includes(value) && rejectedReasons[value]) {
        businessInfoRejectedcount++;
      }
    }); 
  }
  
  return businessInfocount - businessInfoRejectedcount;
};

export const GuarantorInfoCompletionStatus = (kycDetails: any,rejectedReasons:any) => {
  let guarantorInfocount = 0;
  let guarantorInfoRejectedcount = 0;

  if (kycDetails) {
    Object.keys(kycDetails).forEach((value: any) => {
      if (guarantorInfoValues.includes(value) && kycDetails[value]) {
        guarantorInfocount++;
      }
    });

    if (rejectedReasons) {
      Object.keys(rejectedReasons).forEach((value: any) => {
        if (guarantorInfoValues.includes(value) && rejectedReasons[value]) {
          guarantorInfoRejectedcount++;
        }
      }); 
    }
  }
  
  return guarantorInfocount - guarantorInfoRejectedcount;
};

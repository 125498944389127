import { useUserContext } from "../../Context/UserContext";

const NoActiveLoans = () => {
  const userContextUtil = useUserContext();
  const kycDetails = userContextUtil.kycDetails;
  return (
    <div>
      <div className="bg-[#eef8f4] rounded-[20px] mt-[30px] border-[1.2px] border-[#8ed6c9] p-5">
        <p className="text-[#001812] text-[16px] leading-[14px]">Active Loan</p>
        <div className="max-w-[400px] flex flex-col mx-auto text-center items-center py-6">
          <img
            src="https://d1f8g8i172sxu4.cloudfront.net/images/history-empty-state.png"
            alt="No History"
            height={90}
            width={90}
          />

          <h4 className="mt-4 mb-1 font-bold text-[15px] text-[#5C696E]">
            {kycDetails &&
              kycDetails.status &&
              kycDetails.status === "APPROVED" &&
              "Your KYC has not been updated"}

            {kycDetails &&
              kycDetails.status &&
              kycDetails.status === "PENDING" &&
              "Your KYC has not been completed."}
            {kycDetails &&
              kycDetails.status &&
              kycDetails.status === "REJECTED_WITH_REASON" &&
              "Your KYC has not been updated."}
          </h4>

          <p className="text-[#5C696E] text-[14px]">
            {kycDetails.status === "APPROVED" &&
              "Kindly update your KYC with the link above to start your BNPL journey."}

            {kycDetails &&
              kycDetails.status &&
              kycDetails.status === "PENDING" &&
              "Kindly complete your KYC with the link above to start your BNPL journey."}

            {kycDetails &&
              kycDetails.status &&
              kycDetails.status === "REJECTED_WITH_REASON" &&
              "Kindly update your KYC with the link above to start your BNPL journey."}
          </p>
        </div>
      </div>
      {/* <div>
      <div className="mt-[30px] flex justify-between items-center">
        <div className="font-semibold text-[16px] text-[#001812] leading-[14px] opacity-60">
          Active Loans
        </div>
        <div className="flex items-center cursor-pointer">
          <button
            className={`bg-[#2DB49E] text-[#FFFFFF] h-[47px] flex justify-center items-center  w-[128px] ${styles.viewMoreBtn}`}
            onClick={() => {
              navigate("/loans");
            }}
          >
            <span className="mr-2">View more</span>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.00009 0L0.590088 1.41L5.17009 6L0.590088 10.59L2.00009 12L8.00009 6L2.00009 0Z"
                fill="#ffffff"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        className={`bg-[#fff] px-[20px] py-[30px] mt-[25px] ${styles.activeLoans}`}
      >
        <div className="grid grid-cols-1 place-items-center text-center">
          <img
            src="https://d1f8g8i172sxu4.cloudfront.net/images/history-empty-state.png"
            alt="No History"
            height={90}
            width={90}
          />
        </div>
        <div className="text-[14px]  text-center leading-[19px] font-bold text-[#162930] mt-[25px] opacity-50">
          No loans yet
          <div className="font-light text-[#00181299]  text-center mt-[5px]">
            You have not taken any loans. When you do, they will appear here
          </div>
        </div>
      </div>
    </div> */}
    </div>
  );
};

export default NoActiveLoans;

import { useLocation, useNavigate } from "react-router-dom";
import styles from "./BottomBar.module.scss";

function LoggedInBottomBar() {
  const navigate = useNavigate();
  const location = useLocation();

  const imageUrlPrefix = "https://d1f8g8i172sxu4.cloudfront.net/images/";

  const homeClick = () => {
    navigate("/home");  
  }
  const accountClick = () => {
    navigate("/profile");
  } 
  const loansClick = () => {
    navigate("/loans");
  } 
  const historyClick = () => {
    navigate("/history");
  }

  const currentLocation = location.pathname;
  return (
    <>
      <div className={`grid grid-cols-4 py-[12px] ${styles.bottomBar} relative z-10`}>
        <div className="flex justify-center">
          <div
            className="cursor-pointer flex flex-col items-center"
            onClick={() => homeClick()}
          >
            <div className="w-[24px] h-[24px]">
              <img src={`${imageUrlPrefix}${currentLocation === "/home" ? "homeGreenIcon.png" : 'homeGreyIcon.png'}`} alt='greenIcon'/>            
            </div>
            <div
              className={`text-[12px] leading-[16px] tracking-[0.4px] mt-[7px] ${
                currentLocation === "/home"
                  ? "text-[#2DB49E] font-bold"
                  : "text-[#00181299] font-normal"
              }`}
            >
              Home
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div 
            className="cursor-pointer flex flex-col items-center"
            onClick={() => loansClick()}
          >
            <div className="w-[24px] h-[24px]">
              <img src={`${imageUrlPrefix}${
                  currentLocation === "/loans"
                    ? "loanGreenIcon.png"
                    : "loanGreyIcon.png"
                }`} alt='loanIcon'/>
            </div>
            <div className={`text-[12px] leading-[16px] tracking-[0.4px] mt-[7px] ${
                currentLocation === "/loans"
                  ? "text-[#2DB49E] font-bold"
                  : "text-[#00181299] font-normal"
              }`}>
              Loans
            </div>
          </div>
        </div>
        <div className="flex justify-center" onClick={() => historyClick()}>
          <div className="cursor-pointer flex flex-col items-center">
            <div className="w-[24px] h-[24px]">
              <img src={`${imageUrlPrefix}${
                  currentLocation === "/history"
                    ? "historyGreenIcon.png"
                    : "historyGreyIcon.png"
                }`} alt='historyIcon'/>
            </div>
            <div className={`text-[12px] leading-[16px] tracking-[0.4px] mt-[7px] ${
                currentLocation === "/history"
                  ? "text-[#2DB49E] font-bold"
                  : "text-[#00181299] font-normal"
              }`}>
              History
            </div>
          </div>
        </div>
        <div className="flex justify-center" onClick={() => accountClick()}>
          <div className="cursor-pointer flex flex-col items-center">
            <div className="w-[24px] h-[24px]">
              <img alt='historyIcon' src={`${imageUrlPrefix}${currentLocation === "/profile" || currentLocation === "/verify-otp" || currentLocation === "/notifications" || currentLocation === "/reset-password" ? "accountGreenIcon.png" : "accountGreyIcon.png"}`} />
            </div>
            <div
              className={`text-[12px] leading-[16px] tracking-[0.4px] mt-[7px] ${
                currentLocation === "/profile" ||
                currentLocation === "/notifications"
                  ? "text-[#2DB49E] font-bold"
                  : "text-[#00181299] font-normal"
              }`}
            >
              Account
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoggedInBottomBar;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../Context/UserContext";
import { KycInfoSubmit, KycInfoSubmitPatch } from "../../Service/KycInfoSubmit";
import { ReactComponent as GreenLeftArrow } from "../svg/greenLeftArrow.svg";
import SideBar from "../SideBar/SideBar";
import styles from "./Kyc.module.scss";
import {
  validatePhoneNumber,
  validateEmail,
  validateAddress,
  validateBusinessName,
  validateBusinessRole,
  validateRelationship,
  validateFirstName,
  validateLastName,
} from "../../Utility/ValidationsWithJoi";
import SearchableDropdown from "./SearchableDropdown";
import { NextOfKinOptions } from "../../Utility/DropdownOptions";

function ReferencesAndGuarantor() {
  const { userProfile, kycDetails, setKycDetails } = useUserContext();
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState({
    firstName: kycDetails?.guarantor_first_name || "",
    lastName: kycDetails?.guarantor_last_name || "",
    number: kycDetails?.guarantor_phone_number || "",
    email: kycDetails?.guarantor_email || "",
    guaranterRelationship: kycDetails?.guarantor_relationship || "",
    businessName: kycDetails?.guarantor_business_name || "",
    address: kycDetails?.guarantor_business_address || "",
    businessRole: kycDetails?.guarantor_business_role || "",
  });
  const rejectedReason = kycDetails?.rejected_reason;
  const [errors, setErrors] = useState({
    firstName: rejectedReason?.guarantor_first_name || "",
    lastName: rejectedReason?.guarantor_last_name || "",
    number: rejectedReason?.guarantor_phone_number || "",
    email: rejectedReason?.guarantor_email || "",
    guaranterRelationship: rejectedReason?.guarantor_relationship || "",
    businessName: rejectedReason?.guarantor_business_name || "",
    address: rejectedReason?.guarantor_business_address || "",
    businessRole: rejectedReason?.guarantor_business_role || "",
    generalErr: "",
  });

  const validateOptions = {
    abortEarly: false,
    converse: true,
    stripUnknown: true,
  };
  const validationField = (schema: any, value: any, field: any) => {
    const err = JSON.parse(JSON.stringify(errors));
    const res = schema.validate(value, validateOptions);
    let errorsList: any = {};
    if (res.error) {
      res.error.details.forEach((error: any) => {
        errorsList[field] = error.message;
      });
      setErrors({
        ...errors,
        ...errorsList,
      });
    } else {
      delete err[field];
      setErrors(err);
    }
  };
  const validateInput = (e: { target: { name: string; value: any } }) => {
    switch (e.target.name) {
      case "firstName":
        validationField(validateFirstName, e.target.value, "firstName");
        break;
      case "lastName":
        validationField(validateLastName, e.target.value, "lastName");
        break;
      case "email":
        validationField(validateEmail, e.target.value, "email");
        break;
      case "number":
        validationField(validatePhoneNumber, e.target.value, "number");
        break;
      case "businessName":
        validationField(validateBusinessName, e.target.value, "businessName");
        break;
      case "businessRole":
        validationField(validateBusinessRole, e.target.value, "businessRole");
        break;
      case "guaranterRelationship":
        validationField(
          validateRelationship,
          e.target.value,
          "guaranterRelationship"
        );
        break;
      case "address":
        validationField(validateAddress, e.target.value, "address");
        break;
    }
  };

  const setDropDownValues = (e: any, name: string) => {
    setInputValues({
      ...inputValues,
      [name]: e.value,
    });
    errors[name] = "";
  };

  const handleInput = (e: { target: { name: string; value: any } }) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const formSubmit = async (e: any) => {
    e.preventDefault();

    const payload = {
      guarantor_first_name: inputValues?.firstName,
      guarantor_last_name: inputValues?.lastName,
      guarantor_phone_number: inputValues?.number,
      guarantor_email: inputValues?.email,
      guarantor_business_name: inputValues?.businessName,
      guarantor_business_address: inputValues?.address,
      guarantor_business_role: inputValues?.businessRole,
      guarantor_relationship: inputValues?.guaranterRelationship,
    };

    if (
      kycDetails.business_status === "REGISTERED" &&
      kycDetails.business_type === "Limited Liability Company"
    ) {
      delete kycDetails.bn_number;
      delete kycDetails.certificate_of_registration_document_url;
    }

    if (
      kycDetails.business_status === "REGISTERED" &&
      kycDetails.business_type === "Sole Proprietorship"
    ) {
      delete kycDetails.cac_document_url;
      delete kycDetails.business_rc_number;
    }

    if (
      kycDetails.business_status === "UNREGISTERED" ||
      kycDetails.business_status === ""
    ) {
      delete kycDetails.business_type;
      delete kycDetails.bn_number;
      delete kycDetails.certificate_of_registration_document_url;
      delete kycDetails.business_type;
      delete kycDetails.business_rc_number;
      delete kycDetails.cac_document_url;
    }

    const finalPayload = {};
    Object.assign(finalPayload, kycDetails, payload);

    let responseData: any = null;
    if (userProfile?.kyc_id) {
      responseData = await KycInfoSubmitPatch(finalPayload);
    } else {
      responseData = await KycInfoSubmit(payload);
      if (responseData.status === 200) {
        const resObj = responseData?.data?.data;
        const kycObj = resObj?.kyc;
        userProfile.kyc_id = kycObj?.id;
      }
    }
    if (responseData.status === 200) {
      const resObj = responseData?.data?.data;
      const kycObj = resObj?.kyc;
      setKycDetails(kycObj);
      navigate("/kyc");
    } else {
      const errData = responseData?.data?.errors;
      let generalErrorMsg = "";
      if (Object.keys(errData)?.length === 0) {
        generalErrorMsg = responseData?.data?.message;
      }
      setErrors({
        firstName: errData?.guarantor_first_name,
        lastName: errData?.guarantor_last_name,
        number: errData?.guarantor_phone_number,
        email: errData?.guarantor_email,
        guaranterRelationship: errData?.guarantor_relationship,
        businessName: errData?.guarantor_business_name,
        address: errData?.guarantor_business_address,
        businessRole: errData?.guarantor_business_role,
        generalErr: generalErrorMsg,
      });
    }
  };

  return (
    <div className="md:flex md:items-center md:justify-center relative">
      <div className="hidden md:block z-50">
        <SideBar />
      </div>
      <div
        className={`w-full md:w-4/6 md:my-[50px] md:rounded-[20px] md:ml-[-100px] md:border-[#2db49e80] md:border-[1px] overflow-y-auto ${styles.pageHeight}`}
      >
        <div className="container py-[20px] md:py-[40px] md:pl-[165px] md:pr-[40px] overflow-y-auto md:min-h-[690px] relative z-10  bg-[#2db49e1a] md:bg-transparent">
          <div
            className="flex items-center mb-[40px] cursor-pointer"
            onClick={() => {
              navigate("/kyc");
            }}
          >
            <div>
              <GreenLeftArrow />
            </div>
            <div className="ml-[20px] font-semibold text-[20px] leading-[25px] text-[#192850]">
              References and Guarantors
            </div>
          </div>
          <form onSubmit={(e) => formSubmit(e)}>
            <div className="md:grid md:grid-cols-2 md:gap-[20px]">
              <div>
                <div className="md:grid md:grid-cols-2 md:gap-[20px]">
                  <div>
                    <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                      First Name
                    </div>
                    <input
                      className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                      id="firstName"
                      type="text"
                      name="firstName"
                      value={inputValues?.firstName}
                      autoComplete="off"
                      onChange={(e) => handleInput(e)}
                      onBlur={(e) => validateInput(e)}
                    />
                    <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                      {errors.firstName}
                    </div>
                  </div>
                  <div>
                    <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                      Last Name
                    </div>
                    <input
                      className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                      id="lastName"
                      type="text"
                      name="lastName"
                      value={inputValues?.lastName}
                      autoComplete="off"
                      onChange={(e) => handleInput(e)}
                      onBlur={(e) => validateInput(e)}
                    />
                    <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                      {errors.lastName}
                    </div>
                  </div>
                </div>
                <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px]">
                  Phone Number
                </div>
                <input
                  className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                  type="text"
                  value={inputValues?.number}
                  onChange={(e) => handleInput(e)}
                  name="number"
                  autoComplete="off"
                  maxLength={11}
                  onBlur={(e) => validateInput(e)}
                />
                <div className="text-[12px] text-[#192850] min-h-[20px]">
                  Please enter NGN format. E.g 08122934834
                </div>
                <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                  {errors?.number}
                </div>
                <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px]">
                  Email
                </div>
                <input
                  className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                  type="text"
                  onChange={(e) => handleInput(e)}
                  name="email"
                  value={inputValues?.email}
                  autoComplete="off"
                  onBlur={(e) => validateInput(e)}
                />
                <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                  {errors?.email}
                </div>
                <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px]">
                  Relationship
                </div>
                <SearchableDropdown
                  setDropDownValues={setDropDownValues}
                  type={"guaranterRelationship"}
                  selectedValue={inputValues?.guaranterRelationship}
                  dropdownOptions={NextOfKinOptions}
                  heading={"Select your relationship"}
                  disableField={false}
                />
                <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                  {errors?.guaranterRelationship}
                </div>
                <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px]">
                  Business Name
                </div>
                <input
                  className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                  type="text"
                  value={inputValues?.businessName}
                  onChange={(e) => handleInput(e)}
                  name="businessName"
                  autoComplete="off"
                  onBlur={(e) => validateInput(e)}
                />
                <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                  {errors?.businessName}
                </div>
              </div>
              <div>
                <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px] md:mt-0">
                  Business Address
                </div>
                <textarea
                  className="border rounded w-full px-3 h-[75px] leading-tight focus:outline-none mt-[10px] resize-none py-[5px]"
                  rows={2}
                  onChange={(e) => handleInput(e)}
                  name="address"
                  value={inputValues?.address}
                  autoComplete="off"
                  onBlur={(e) => validateInput(e)}
                />
                <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                  {errors?.address}
                </div>
                <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px]">
                  Business Role
                </div>
                <input
                  className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                  id="businessRole"
                  type="text"
                  value={inputValues?.businessRole}
                  onChange={(e) => handleInput(e)}
                  name="businessRole"
                  autoComplete="off"
                  onBlur={(e) => validateInput(e)}
                />
                <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                  {errors?.businessRole}
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-[65px]">
              <button
                className="bg-[#2DB49E] rounded-[5px] font-semibold text-[14px] leading-[16px] text-[#fff] w-full md:w-1/2 py-[12px]"
                type="submit"
              >
                Complete
              </button>
            </div>
            <div className="text-[16px] font-bold text-[#FF0000] flex justify-center mt-[10px] min-h-[24px]">
              {errors.generalErr}
            </div>
          </form>
        </div>
      </div>
      <div
        className={`absolute bottom-[160px] md:bottom-[105px] right-[10px] md:right-[-10px] ${styles.duploLogoOpaque} z-[-1]`}
      >
        <img
          src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
          alt="DuploLogo"
        />
      </div>
    </div>
  );
}

export default ReferencesAndGuarantor;

import { useNavigate } from "react-router-dom";
import { ReactComponent as Celebration } from "./../svg/celebration.svg";

const KycApprovedBanner = () => {
  const navigate = useNavigate();
  return (
    <div
      className={`flex items-center kyc-box-shadow bg-[#ffffff] px-[20px] md:px-[50px] py-[15px] md:py-[30px] mt-[25px] rounded-[20px]`}
    >
      <Celebration />
      <div className="pl-[10px] md:pl-[35px]">
        <div className="text-[14px] font-bold text-[#333333]">
          Congratulations
        </div>
        <div className="font-normal text-[14px] leading-[18px] text-[#333333]">
          Your KYC details have been approved.{" "}
          <span
            className="text-[#2DB49E] cursor-pointer"
            onClick={() => {
              navigate("/kyc-approved");
            }}
          >
            Click here to activate your account.
          </span>
        </div>
      </div>
    </div>
  );
};

export default KycApprovedBanner;

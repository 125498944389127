import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SignInForm from "../SignIn/SignInForm";
import SignUpForm from "../SignUp/SignUpForm";
import styles from "./Landing.module.scss";
import WebBackgroundLanding from "./WebBackgroundLanding";
import { ReactComponent as DuploLogo } from "../svg/duploWebLogo.svg";
import LoggedOutBottomBar from "../BottomBar/LoggedOutBottomBar";
import { Link } from "react-router-dom";

function Landingpage() {
  const { state } = useLocation();
  const [isSignIn, setIsSignIn] = useState(false);
  const [isSignOut, setIsSignOut] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const business_id = urlParams.get("business_id");

  useEffect(() => {
    if (window.innerWidth <= 576) {
      setTimeout(() => {
        if (state?.isPhoneNumberExists) {
          setIsSignIn(true);
        } else {
          setIsSignOut(true);
        }
      }, 2000);
    } else {
      if (state?.isPhoneNumberExists) {
        setIsSignIn(true);
      } else {
        setIsSignOut(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {window.innerWidth < 768 ? (
        <div>
          <div
            className={`${styles.backgroundImg} relative overflow-y-auto md:hidden block`}
          >
            <div className={`${styles.duploLandingPageLogo}`}>
              <DuploLogo />
            </div>
            {isSignIn && (
              <div
                className={`${styles.duploLandingPageForm} ${styles.formHeight} ${styles.inputField}`}
              >
                <SignInForm phoneNumber={state?.phoneNumber} />
                <div
                  className={`absolute bottom-[135px] md:bottom-[110px] right-[-4px] md:right-[-10px] ${styles.duploLogoOpaque}`}
                >
                  <img
                    src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
                    alt="DuploLogo"
                  />
                </div>
                <div className="py-[14px] w-full relative z-10">
                  <LoggedOutBottomBar />
                </div>
              </div>
            )}
            {isSignOut && (
              <div
                className={`${styles.duploLandingPageForm} ${styles.inputField}`}
              >
                <SignUpForm phoneNumber={state?.phoneNumber} />
                <div
                  className={`absolute bottom-[135px] md:bottom-[110px] right-[-4px] md:right-[-10px] ${styles.duploLogoOpaque}`}
                >
                  <img
                    src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
                    alt="DuploLogo"
                  />
                </div>
                <div className="py-[14px] w-full relative z-10">
                  <LoggedOutBottomBar />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="relative">
          <div
            className="md:container py-[60px]"
            style={{ minHeight: "calc(100vh - 55px)" }}
          >
            <WebBackgroundLanding />
            <div
              className={`${styles.formElement}  ${styles.inputField} flex justify-center item-center bg-[#fff]`}
            >
              {isSignIn && <SignInForm phoneNumber={state?.phoneNumber} />}
              {isSignOut && <SignUpForm phoneNumber={state?.phoneNumber} />}
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 md:container py-[14px] w-full relative z-10">
            <LoggedOutBottomBar />
            {window.innerWidth >= 768 && (
              <div>
                {isSignIn && (
                  <div className="flex justify-center text-[14px] w-full">
                    <div className="text-[#192850]">Don't have an account?</div>
                    <Link
                      to={
                        business_id
                          ? `/sign-up?business_id=${business_id}`
                          : "/sign-up"
                      }
                      className="pl-[10px] text-[#2DB49E] underline underline-offset-4"
                    >
                      {" "}
                      Sign up
                    </Link>
                  </div>
                )}
                {isSignOut && (
                  <div className="flex justify-center text-[14px] w-full">
                    <div className="text-[#192850]">
                      Already have an account?
                    </div>
                    <Link
                      to={"/sign-in"}
                      className="pl-[10px] text-[#2DB49E] underline underline-offset-4"
                    >
                      {" "}
                      Sign in
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            className={`absolute bottom-[135px] md:bottom-[110px] right-[-4px] md:right-[-10px] ${styles.duploLogoOpaque}`}
          >
            <img
              src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
              alt="DuploLogo"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Landingpage;

const StateWithLgas = [
  {
    name: "Abia",
    short_name: "AB",
    capital: "Umuahia",
    local_governments: [
      "Aba North",
      "Aba South",
      "Arochukwu",
      "Bende",
      "Ikwuano",
      "Isiala Ngwa North",
      "Isiala Ngwa South",
      "Isuikwuato",
      "Obi Ngwa",
      "Ohafia",
      "Osisioma Ngwa",
      "Ugwunagbo",
      "Ukwa East",
      "Ukwa West",
      "Umuahia North",
      "Umuahia South",
      "Umu Nneochi",
    ],
  },
  {
    name: "Adamawa",
    short_name: "AD",
    capital: "Yola",
    local_governments: [
      "Demsa",
      "Fufore",
      "Ganye",
      "Girei",
      "Gombi",
      "Guyuk",
      "Hong",
      "Jada",
      "Lamurde",
      "Madagali",
      "Maiha",
      "Mayo-Belwa",
      "Michika",
      "Mubi North",
      "Mubi South",
      "Numan",
      "Shelleng",
      "Song",
      "Toungo",
      "Yola North",
      "Yola South",
    ],
  },
  {
    name: "Anambra",
    short_name: "AN",
    capital: "Awka",
    local_governments: [
      "Aguata",
      "Awka North",
      "Awka South",
      "Anambra East",
      "Anambra West",
      "Anaocha",
      "Ayamelum",
      "Dunukofia",
      "Ekwusigo",
      "Idemili North",
      "Idemili South",
      "Ihiala",
      "Njikoka",
      "Nnewi North",
      "Nnewi South",
      "Ogbaru",
      "Onitsha North",
      "Onitsha South",
      "Orumba North",
      "Orumba South",
      "Oyi",
    ],
  },
  {
    name: "Akwa Ibom",
    short_name: "AK",
    capital: "Uyo",
    local_governments: [
      "Abak",
      "Eastern Obolo",
      "Eket",
      "Esit-Eket",
      "Essien Udim",
      "Etim-Ekpo",
      "Etinan",
      "Ibeno",
      "Ibesikpo-Asutan",
      "Ibiono-Ibom",
      "Ika",
      "Ikono",
      "Ikot Abasi",
      "Ikot Ekpene",
      "Ini",
      "Itu",
      "Mbo",
      "Mkpat-Enin",
      "Nsit-Atai",
      "Nsit-Ibom",
      "Nsit-Ubium",
      "Obot-Akara",
      "Okobo",
      "Onna",
      "Oron",
      "Oruk Anam",
      "Ukanafun",
      "Udung-Uko",
      "Uruan",
      "Urue-Offong/Oruko",
      "Uyo",
    ],
  },
  {
    name: "Bauchi",
    short_name: "BA",
    capital: "Bauchi",
    local_governments: [
      "Bauchi",
      "Tafawa Balewa",
      "Dass",
      "Toro",
      "Bogoro",
      "Ningi",
      "Warji",
      "Ganjuwa",
      "Kirfi",
      "Alkaleri",
      "Darazo",
      "Misau",
      "Giade",
      "Shira",
      "Jama'are",
      "Katagum",
      "Itas/Gadau",
      "Zaki",
      "Gamawa",
      "Damban",
    ],
  },
  {
    name: "Bayelsa",
    short_name: "BY",
    capital: "Yenegoa",
    local_governments: [
      "Brass",
      "Ekeremor",
      "Kolokuma/Opokuma",
      "Nembe",
      "Ogbia",
      "Sagbama",
      "Southern Ijaw",
      "Yenagoa",
    ],
  },
  {
    name: "Benue",
    short_name: "BE",
    capital: "Markudi",
    local_governments: [
      "Ado",
      "Agatu",
      "Apa",
      "Buruku",
      "Gboko",
      "Guma",
      "Gwer East",
      "Gwer West",
      "Katsina-Ala",
      "Konshisha",
      "Kwande",
      "Logo",
      "Makurdi",
      "Obi",
      "Ogbadibo",
      "Ohimini",
      "Oju",
      "Okpokwu",
      "Otukpo",
      "Tarka",
      "Ukum",
      "Ushongo",
      "Vandeikya",
    ],
  },
  {
    name: "Borno",
    short_name: "BO",
    capital: "Maiduguri",
    local_governments: [
      "Abadan",
      "Askira/Uba",
      "Bama",
      "Bayo",
      "Biu",
      "Chibok",
      "Damboa",
      "Dikwagubio",
      "Guzamala",
      "Gwoza",
      "Hawul",
      "Jere",
      "Kaga",
      "Kalka/Balge",
      "Konduga",
      "Kukawa",
      "Kwaya-ku",
      "Mafa",
      "Magumeri",
      "Maiduguri",
      "Marte",
      "Mobbar",
      "Monguno",
      "Ngala",
      "Nganzai",
      "Shani",
    ],
  },
  {
    name: "Cross River",
    short_name: "CR",
    capital: "Calabar",
    local_governments: [
      "Abi",
      "Akamkpa",
      "Akpabuyo",
      "Bakassi",
      "Bekwarra",
      "Biase",
      "Boki",
      "Calabar Municipal",
      "Calabar South",
      "Etung",
      "Ikom",
      "Obanliku",
      "Obubra",
      "Obudu",
      "Odukpani",
      "Ogoja",
      "Yakuur",
      "Yala",
    ],
  },
  {
    name: "Delta",
    short_name: "DE",
    capital: "Asaba",
    local_governments: [
      "Aniocha South",
      "Anioha",
      "Bomadi",
      "Burutu",
      "Ethiope West",
      "Ethiope East",
      "Ika south",
      "Ika North East",
      "Isoko South",
      "Isoko North",
      "Ndokwa East",
      "Ndokwa West",
      "Okpe",
      "Oshimili North",
      "Oshimili South",
      "Patani",
      "Sapele",
      "Udu",
      "Ughelli South",
      "Ughelli North",
      "Ukwuani",
      "Uviwie",
      "Warri Central",
      "Warri North",
      "Warri South",
    ],
  },
  {
    name: "Ebonyi",
    short_name: "EB",
    capital: "Abakaliki",
    local_governments: [
      "Abakaliki",
      "Afikpo North",
      "Afikpo South (Edda)",
      "Ebonyi",
      "Ezza North",
      "Ezza South",
      "Ikwo",
      "Ishielu",
      "Ivo",
      "Izzi",
      "Ohaozara",
      "Ohaukwu",
      "Onicha",
    ],
  },
  {
    name: "Edo",
    short_name: "ED",
    capital: "Benin City",
    local_governments: [
      "Akoko-Edo",
      "Egor",
      "Esan Central",
      "Esan North-East",
      "Esan South-East",
      "Esan West",
      "Etsako Central",
      "Etsako East",
      "Etsako West",
      "Igueben",
      "Ikpoba-Okha",
      "Oredo",
      "Orhionmwon",
      "Ovia North-East",
      "Ovia South-West",
      "Owan East",
      "Owan West",
      "Uhunmwonde",
    ],
  },
  {
    name: "Ekiti",
    short_name: "EK",
    capital: "Ado Ekiti",
    local_governments: [
      "Ado-Ekiti",
      "Ikere",
      "Oye",
      "Aiyekire (Gbonyin)",
      "Efon",
      "Ekiti East",
      "Ekiti South-West",
      "Ekiti West",
      "Emure",
      "Ido-Osi",
      "Ijero",
      "Ikole",
      "Ilejemeje",
      "Irepodun/Ifelodun",
      "Ise/Orun",
      "Moba",
    ],
  },
  {
    name: "Enugu",
    short_name: "EN",
    capital: "Enugu",
    local_governments: [
      "Aninri",
      "Awgu",
      "Enugu East",
      "Enugu North",
      "Enugu South",
      "Ezeagu",
      "Igbo Etiti",
      "Igbo Eze North",
      "Igbo Eze South",
      "Isi Uzo",
      "Nkanu East",
      "Nkanu West",
      "Nsukka",
      "Oji River",
      "Udenu",
      "Udi",
      "Uzo-Uwani",
    ],
  },
  {
    name: "Gombe",
    short_name: "GO",
    capital: "Gombe",
    local_governments: [
      "Akko",
      "Balanga",
      "Billiri",
      "Dukku",
      "Dunakaye",
      "Gombe",
      "Kaltungo",
      "Kwami",
      "Nafada/Bajoga",
      "Shomgom",
      "Yamaltu/Deba",
    ],
  },
  {
    name: "Imo",
    short_name: "IM",
    capital: "Owerri",
    local_governments: [
      "Aboh Mbaise",
      "Ahiazu Mbaise",
      "Ehime Mbano",
      "Ezinihitte Mbaise",
      "Ideato North",
      "Ideato South",
      "Ihitte/Uboma",
      "Ikeduru",
      "Isiala Mbano",
      "Isu",
      "Mbaitoli",
      "Ngor Okpala",
      "Njaba",
      "Nkwerre",
      "Nwangele",
      "Obowo",
      "Oguta",
      "Ohaji/Egbema",
      "Okigwe",
      "Onuimo",
      "Orlu",
      "Orsu",
      "Oru East",
      "Oru West",
      "Owerri Municipal",
      "Owerri North",
      "Owerri West",
    ],
  },
  {
    name: "Jigawa",
    short_name: "JI",
    capital: "Dutse",
    local_governments: [
      "Auyo",
      "Babura",
      "Biriniwa",
      "Birnin Kudu",
      "Buji",
      "Dutse",
      "Gagarawa",
      "Garki",
      "Gumel",
      "Guri",
      "Gwaram",
      "Gwiwa",
      "Hadejia",
      "Jahun",
      "Kafin Hausa",
      "Kaugama",
      "Kazaure",
      "Kiri Kasama",
      "Kiyawa",
      "Maigatari",
      "Malam Madori",
      "Miga",
      "Ringim",
      "Roni",
      "Sule Tankarkar",
      "Taura",
      "Yankwashi",
    ],
  },
  {
    name: "Kaduna",
    short_name: "KD",
    capital: "Kaduna",
    local_governments: [
      "Birnin Gwari",
      "Chikun",
      "Giwa",
      "Igabi",
      "Ikara",
      "Jaba",
      "Jema'a",
      "Kachia",
      "Kaduna North",
      "Kaduna South",
      "Kagarko",
      "Kajuru",
      "Kaura",
      "Kauru",
      "Kubau",
      "Kudan",
      "Lere",
      "Makarfi",
      "Sabon Gari",
      "Sanga",
      "Soba",
      "Zangon Kataf",
      "Zaria",
    ],
  },
  {
    name: "Kano",
    short_name: "KN",
    capital: "Kano",
    local_governments: [
      "Ajingi",
      "Albasu",
      "Bagwai",
      "Bebeji",
      "Bichi",
      "Bunkure",
      "Dala",
      "Dambatta",
      "Dawakin Kudu",
      "Dawakin Tofa",
      "Doguwa",
      "Fagge",
      "Gabasawa",
      "Garko",
      "Garun Mallam",
      "Gaya",
      "Gezawa",
      "Gwale",
      "Gwarzo",
      "Kabo",
      "Kano Municipal",
      "Karaye",
      "Kibiya",
      "Kiru",
      "Kumbtso",
      "Kunchi",
      "Kura",
      "Madobi",
      "Makoda",
      "Minjibir",
      "Nassarawa",
      "Rano",
      "Rimin Gado",
      "Rogo",
      "Shanono",
      "Sumaila",
      "Takai",
      "Tarauni",
      "Tofa",
      "Tsanyawa",
      "Tudun Wada",
      "Ungogo",
      "Warawa",
      "Wudil",
    ],
  },
  {
    name: "Kastina",
    short_name: "KT",
    capital: "Katsina",
    local_governments: [
      "Bakori",
      "Batagarawa",
      "Batsari",
      "Baure",
      "Bindawa",
      "Charanchi",
      "Dan-Musa",
      "Dandume",
      "Danja",
      "Daura",
      "Dutsi",
      "Dutsin-Ma",
      "Faskari",
      "Funtua",
      "Ingawa",
      "Jibia",
      "Kafur",
      "Kaita",
      "Kankara",
      "Kankia",
      "Katsina",
      "Kurfi",
      "Kusada",
      "Mai'Adua",
      "Malumfashi",
      "Mani",
      "Mashi",
      "Matazu",
      "Musawa",
      "Rimi",
      "Sabuwa",
      "Safana",
      "Sandamu",
      "Zango",
    ],
  },
  {
    name: "Kebbi",
    short_name: "KE",
    capital: "Birnin Kebbi",
    local_governments: [
      "Aleiro",
      "Arewa Dandi",
      "Argungu",
      "Augie",
      "Bagudo",
      "Birnin Kebbi",
      "Bunza",
      "Dandi",
      "Fakai",
      "Gwandu",
      "Jega",
      "Kalgo",
      "Koko/Besse",
      "Maiyama",
      "Ngaski",
      "Sakaba",
      "Shanga",
      "Suru",
      "Danko/Wasagu",
      "Yauri",
      "Zuru",
    ],
  },
  {
    name: "Kogi",
    short_name: "KO",
    capital: "Lokoja",
    local_governments: [
      "Adavi",
      "Ajaokuta",
      "Ankpa",
      "Bassa",
      "Dekina",
      "Ibaji",
      "Idah",
      "Igalamela-Odolu",
      "Ijumu",
      "Kabba/Bunu",
      "Koton Karfe",
      "Lokoja",
      "Mopa-Muro",
      "Ofu",
      "Ogori/Magongo",
      "Okehi",
      "Okene",
      "Olamaboro",
      "Omala",
      "Yagba East",
      "Yagba West",
    ],
  },
  {
    name: "Kwara",
    short_name: "KW",
    capital: "Ilorin",
    local_governments: [
      "Asa",
      "Baruten",
      "Edu",
      "Ekiti",
      "Ifelodun",
      "Ilorin East",
      "Ilorin South",
      "Ilorin West",
      "Irepodun",
      "Isin",
      "Kaiama",
      "Moro",
      "Offa",
      "Oke Ero",
      "Oyun",
      "Pategi",
    ],
  },
  {
    name: "Lagos",
    short_name: "LA",
    capital: "Ikeja",
    local_governments: [
      "Agege",
      "Alimosho",
      "Ajeromi-Ifelodun",
      "Amuwo-Odofin",
      "Apapa",
      "Badagry",
      "Epe",
      "Eti-Osa",
      "Ibeju-Lekki",
      "Ifako/Ijaye",
      "Ikeja",
      "Ikorodu",
      "Kosofe",
      "Lagos Island",
      "Lagos Mainland",
      "Mushin",
      "Ojo",
      "Oshodi-Isolo",
      "Shomolu",
      "Surulere",
    ],
  },
  {
    name: "Nassarawa",
    short_name: "NA",
    capital: "Lafia",
    local_governments: [
      "Akwanga",
      "Awe",
      "Doma",
      "Karu",
      "Keana",
      "Keffi",
      "Kokona",
      "Lafia",
      "Nassarawa",
      "Nassarawa/Eggon",
      "Obi",
      "Toto",
      "Wamba",
    ],
  },
  {
    name: "Niger",
    short_name: "NI",
    capital: "Minna",
    local_governments: [
      "Agaie",
      "Agwara",
      "Bida",
      "Borgu",
      "Bosso",
      "Chanchaga",
      "Edati",
      "Gbako",
      "Gurara",
      "Katcha",
      "Kontagora",
      "Lapai",
      "Lavun",
      "Magama",
      "Mariga",
      "Mashegu",
      "Mokwa",
      "Munya",
      "Paikoro",
      "Rafi",
      "Rijau",
      "Shiroro",
      "Suleja",
      "Tafa",
      "Wushishi",
    ],
  },
  {
    name: "Ogun",
    short_name: "OG",
    capital: "Abeokuta",
    local_governments: [
      "Abeokuta North",
      "Abeokuta South",
      "Ado-Odo/Ota",
      "Ewekoro",
      "Ifo",
      "Ijebu East",
      "Ijebu North",
      "Ijebu North East",
      "Ijebu Ode",
      "Ikenne",
      "Imeko Afon",
      "Ipokia",
      "Obafemi Owode",
      "Odogbolu",
      "Odeda",
      "Ogun Waterside",
      "Remo North",
      "Sagamu",
      "Yewa North",
      "Yewa South",
    ],
  },
  {
    name: "Ondo",
    short_name: "ON",
    capital: "Akure",
    local_governments: [
      "Akoko North",
      "Akoko North East",
      "Akoko South East",
      "Akoko South",
      "Akure North",
      "Akure",
      "Idanre",
      "Ifedore",
      "Ese Odo",
      "Ilaje",
      "Ile Oluji/Okeigbo",
      "Irele",
      "Odigbo",
      "Okitipupa",
      "Ondo",
      "Ondo East",
      "Ose",
      "Owo",
    ],
  },
  {
    name: "Osun",
    short_name: "OS",
    capital: "Osogbo",
    local_governments: [
      "Atakumosa West",
      "Atakumosa East",
      "Ayedaade",
      "Ayedire",
      "Bolawaduro",
      "Boripe",
      "Ede",
      "Ede North",
      "Egbedore",
      "Ejigbo",
      "Ife North",
      "Ife Central",
      "Ife South",
      "Ife East",
      "Ifedayo",
      "Ifelodun",
      "Ilesha West",
      "Ila-Orangun",
      "Ilesah East",
      "Irepodun",
      "Irewole",
      "Isokan",
      "Iwo",
      "Obokun",
      "Odo-Otin",
      "Ola Oluwa",
      "Olorunda",
      "Oriade",
      "Orolu",
      "Osogbo",
    ],
  },
  {
    name: "Oyo",
    short_name: "OY",
    capital: "Ibadan",
    local_governments: [
      "Akinyele",
      "Afijio",
      "Atiba",
      "Atisbo",
      "Egbeda",
      "Ibadan North",
      "Ibadan North-East",
      "Ibadan North-West",
      "Ibadan South-West",
      "Ibadan South-East",
      "Ibarapa Central",
      "Ibarapa East",
      "Ibarapa North",
      "Ido",
      "Irepo",
      "Iseyin",
      "Itesiwaju",
      "Iwajowa",
      "Kajola",
      "Lagelu",
      "Ogbomosho North",
      "Ogbomosho South",
      "Oyo East",
      "Oyo West",
      "Olorunsogo",
      "Oluyole",
      "Ogo Oluwa",
      "Orelope",
      "Ori Ire",
      "Ona Ara",
      "Saki West",
      "Saki East",
      "Surulere",
    ],
  },
  {
    name: "Plateau",
    short_name: "PL",
    capital: "Jos",
    local_governments: [
      "Barkin Ladi",
      "Bassa",
      "Bokkos",
      "Jos-East",
      "Jos-North",
      "Jos-South",
      "Kanam",
      "Kanke",
      "Langtang North",
      "Langtang South",
      "Mangu",
      "Mikang",
      "Pankshin",
      "Qua'an Pan",
      "Riyom",
      "Shendam",
      "Wase",
    ],
  },
  {
    name: "River",
    short_name: "RI",
    capital: "Port Harcourt",
    local_governments: [
      "Abua-Odual",
      "Ahoada East",
      "Ahoada West",
      "Akuku-Toru",
      "Andoni",
      "Asari-Toru",
      "Bonny",
      "Degema",
      "Eleme",
      "Emohua",
      "Etche",
      "Gokana",
      "Ikwerre",
      "Oyigbo",
      "Khana",
      "Obio-Akpor",
      "Ogba-Egbema-Ndoni",
      "Ogu-bolo",
      "Okrika",
      "Omumma",
      "Opobo-Nkoro",
      "Port Harcourt",
      "Tai",
    ],
  },
  {
    name: "Sokoto",
    short_name: "SO",
    capital: "Sokoto",
    local_governments: [
      "Binji",
      "Bodinga",
      "Dange Shuni",
      "Gada",
      "Goronyo",
      "Gudu",
      "Gwadabawa",
      "Illela",
      "Isa",
      "Kebbe",
      "Kware",
      "Rabah",
      "Sabon Birni",
      "Shagari",
      "Silame",
      "Sokoto North",
      "Sokoto South",
      "Tambuwal",
      "Tangaza",
      "Tureta",
      "Wamako",
      "Wurno",
      "Yabo",
    ],
  },
  {
    name: "Taraba",
    short_name: "TA",
    capital: "Jalingo",
    local_governments: [
      "Ardo Kola",
      "Bali",
      "Donga",
      "Gashaka",
      "Gassol",
      "Ibi",
      "Jalingo",
      "Karim Lamido",
      "Kurmi",
      "Lau",
      "Sardauna",
      "Takum",
      "Ussa",
      "Wukari",
      "Yorro",
      "Zing",
    ],
  },
  {
    name: "Yobe",
    short_name: "YO",
    capital: "Damaturu",
    local_governments: [
      "Bade",
      "Bursari",
      "Damaturu",
      "Geidam",
      "Gujba",
      "Gulani",
      "Fika",
      "Fune",
      "Jakusko",
      "Karasuwa",
      "Machina",
      "Nangere",
      "Nguru",
      "Potiskum",
      "Tarmuwa",
      "Yunusari",
      "Yusufari",
    ],
  },
  {
    name: "Zamfara",
    short_name: "ZA",
    capital: "Gusau",
    local_governments: [
      "Anka",
      "Bakura",
      "Birnin Magaji/Kiyaw",
      "Bukkuyum",
      "Bungudu",
      "Tsafe",
      "Gummi",
      "Gusau",
      "Kaura Namoda",
      "Maradun",
      "Maru",
      "Shinkafi",
      "Talata Mafara",
      "Zurmi",
    ],
  },
  {
    name: "ABUJA FCT",
    short_name: "FC",
    capital: "Abuja",
    local_governments: [
      "Abaji",
      "Abuja Municipal",
      "Bwari",
      "Gwagwalada",
      "Kuje",
      "Kwali",
    ],
  },
];

export const LgaOptions = (state: string) => {
  let rawLga: string | any[] = [];
  const LgaDropdownOptions: { label: any; value: any }[] = [];

  for (let cnt = 0; cnt < StateWithLgas?.length; cnt++) {
    if (StateWithLgas[cnt].name.toLowerCase() === state.toLowerCase()) {
      rawLga = StateWithLgas[cnt].local_governments;
      break;
    }
  }
  for (let cnt = 0; cnt < rawLga?.length; cnt++) {
    LgaDropdownOptions.push({
      label: rawLga[cnt],
      value: rawLga[cnt],
    });
  }
  return LgaDropdownOptions;
};

import { ReactComponent as GreenLeftArrow } from "../svg/greenLeftArrow.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { api } from "../../Service/AuthService";
import NotificationCell from "./NotificationCell";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../Loader";

const NotificationTab = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [timestamp, setTimestamp] = useState(null);
  const [emptyState, setEmptyState] = useState(false);
  const dataFetchedRef = useRef(false);
  const [loading, setLoading] = useState(false);

  const getNotifications = async () => {
    setLoading(true);
    await api
      .get("notifications", {
        params: {
          page_size: 10,
          timestamp: timestamp,
        },
      })
      .then((response) => {
        const responseData = response?.data?.data;
        const meta = responseData?.meta;
        setTimestamp(meta?.timestamp);
        setNotifications((notifications): any => [
          ...notifications,
          ...responseData?.notifications,
        ]);
        if (
          responseData?.notifications?.length === 0 &&
          notifications?.length === 0
        ) {
          setEmptyState(true);
        }
        if (!meta?.next_page) {
          setHasMore(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`container md:pr-0 py-[40px] md:pl-[165px] md:min-h-[690px] relative`}
    >
      <div className="md:pt-[44px] md:pl-[29px] flex">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            navigate("/profile");
          }}
        >
          <span>
            <GreenLeftArrow />
          </span>
          <span className="pl-[30px] font-semibold text-[20px] leading-[25px] text-[#192850]">
            Notifications
          </span>
        </div>
      </div>
      {loading && <Loader />}
      {!loading && emptyState ? (
        <>
          <div className={`mt-[130px] md:mt-[160px]`}>
            <div className="grid grid-cols-1 place-items-center text-center">
              <img
                src="https://d1f8g8i172sxu4.cloudfront.net/images/history-empty-state.png"
                alt="No History"
                height={100}
                width={100}
              />
              <div className="text-[14px] leading-[19px] font-bold text-[#162930] mt-[25px] opacity-50">
                You have no notifications
                <div className="font-normal text-[#00181299]">
                  We’ll notify you when something arrives
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <InfiniteScroll
          dataLength={notifications?.length}
          next={getNotifications}
          hasMore={hasMore}
          loader={""}
          height={620}
          className="md:max-h-[620px] overflow-y-auto mt-[30px]"
        >
          {notifications?.map((data, item) => (
            <div key={item}>
              <NotificationCell data={data} />
            </div>
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default NotificationTab;

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { validate } from "../../Utility/Validations";
import { api } from "../../Service/AuthService";
import { useUserContext } from "../../Context/UserContext";
import { ReactComponent as DisplayPassword } from "../svg/passwordShow.svg";
import { ReactComponent as HidePassword } from "../svg/passwordHide.svg";
import { useCheckoutContext } from "../../Context/CheckoutRef";
import { trackEventForHotjar } from "../../Utility/hotjat";

function SignInForm(props: any) {
  const navigate = useNavigate();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const business_id = urlParams.get("business_id");
  const { setUserProfile } = useUserContext();
  const { checkoutRef } = useCheckoutContext();

  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const [errors, setErrors] = useState({
    number: "",
    password: "",
    generalErr: "",
  });

  const [inputValues, setInputValues] = useState({
    number: "",
    password: "",
  });

  const handleInput = (e: { target: { name: string; value: any } }) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };
  const validateInput = (e: { target: { name: string; value: any } }) => {
    setErrors({
      ...errors,
      [e.target.name]: validate(e.target.name, e.target.value),
    });
  };

  const login = () => {
    setErrors({
      number: "",
      password: "",
      generalErr: "",
    });

    const payload: { [k: string]: any } = {
      phone_number: inputValues.number,
      password: inputValues.password,
    };

    if (checkoutRef) {
      payload.checkout_ref = checkoutRef;
    }

    api
      .post(`/phone/login`, payload)
      .then((response) => {
        const responseData = response?.data?.data;
        const currentUser = responseData?.current_user;
        setUserProfile(currentUser);
        trackEventForHotjar("User logged in");
        if (currentUser) {
          navigate("/logged-in");
        }
      })
      .catch((err) => {
        const responseErrorsData = err?.response?.data?.errors || {};
        let generalErrorMsg = "";
        if (Object.keys(responseErrorsData).length === 0) {
          generalErrorMsg = err && err.response && err.response.data.message;
        }

        setErrors({
          number: responseErrorsData?.phone_number,
          password: responseErrorsData?.password,
          generalErr: generalErrorMsg,
        });
      });
  };

  const formSubmit = (e: any) => {
    e.preventDefault();
    login();
  };

  useEffect(() => {
    setInputValues({
      number: props?.phoneNumber || "",
      password: "",
    });
  }, [props?.phoneNumber]);

  return (
    <div className={`w-full md:w-10/12 relative z-10 bg-transparent`}>
      <div className={`pt-[20px] px-[27px]`}>
        <div className="flex justify-center text-[24px] leading-[30px] font-semibold text-[#103129] mb-[40px]">
          Welcome Back
        </div>
        <div className="formSection">
          <form onSubmit={(e) => formSubmit(e)}>
            <div className="mb-[5px] formGrp">
              <div className="text-[14px] text-[#192850] mb-[10px] font-normal leading-[16px]">
                {" "}
                Phone Number
              </div>
              <input
                className="border rounded w-full py-2 px-3 h-[40px] leading-tight focus:outline-none"
                id="signInPhoneNumber"
                type="text"
                name="number"
                placeholder="Phone Number"
                maxLength={11}
                autoComplete="off"
                value={inputValues.number}
                onChange={(e) => handleInput(e)}
                onBlur={(e) => validateInput(e)}
                required
              />
              <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                {errors.number}
              </div>
            </div>
            <div className="mb-[5px] formGrp">
              <div className="text-[14px] text-[#192850] mb-[10px] font-normal leading-[16px]">
                {" "}
                Password
              </div>
              <div className="flex items-center">
                <input
                  className="border rounded w-full py-2 pl-3 pr-8 h-[40px] leading-tight focus:outline-none"
                  id="signInPassword"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  autoComplete="off"
                  value={inputValues.password}
                  onChange={(e) => handleInput(e)}
                  onBlur={(e) => validateInput(e)}
                  required
                />
                <div
                  className="ml-[-35px] cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <HidePassword /> : <DisplayPassword />}
                </div>
              </div>
              <div className="text-[12px] text-[#FF0000] min-h-[35px]">
                {errors.password}
              </div>
              <div className="flex justify-end text-[14px] text-[#192850] pt-[5px] font-light leading-[16px]">
                <Link
                  to={
                    business_id
                      ? `/forgot-password?business_id=${business_id}`
                      : "/forgot-password"
                  }
                  className="cursor-pointer"
                >
                  Forgot password?
                </Link>
              </div>
            </div>
            <div className="my-[30px]">
              <button
                className="w-full rounded-md font-semibold text-[14px] text-[#fff] bg-[#2DB49E] py-[12px] leading-[16px]"
                type="submit"
              >
                Login
              </button>
              <div className="text-[16px] font-bold text-[#FF0000] flex justify-center mt-[20px] min-h-[24px]">
                {errors.generalErr}
              </div>
            </div>
          </form>
        </div>
        {window.innerWidth < 768 && (
          <div className="flex justify-center text-[14px] mb-[9px]">
            <div className="text-[#192850]">Don't have an account?</div>
            <Link
              to={
                business_id ? `/sign-up?business_id=${business_id}` : "/sign-up"
              }
              className="pl-[10px] text-[#2DB49E] underline underline-offset-4"
            >
              {" "}
              Sign up
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default SignInForm;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useUserContext } from "../../Context/UserContext";
import ProgressBar from "./ProgressBar";
import {
  PersonalInfoCompletionStatus,
  BusinessInfoCompletionStatus,
  GuarantorInfoCompletionStatus,
} from "../../Utility/KycCompletedStatus";
import {
  businessInfoValues,
  guarantorInfoValues,
  personalInfoValues,
} from "../../Utility/FormValues";
import SideBar from "../SideBar/SideBar";
import styles from "./Kyc.module.scss";
import { api } from "../../Service/AuthService";
import KycSucess from "./KycSucess";

function KycInfo() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isPersonalInfoCompleted, setIsPersonalInfoCompleted] = useState(0);
  const [isBusinessInfoCompleted, setIsBusinessInfoCompleted] = useState(0);
  const [isGuarantorInfoCompleted, setIsGuarantorInfoCompleted] = useState(0);
  const [profileCompletionStatus, setProfileCompletionStatus] = useState(false);
  const [showSucessModal, setShowSucessModal] = useState(false);
  const { kycDetails, lenderKycSchema, setKycDetails } = useUserContext();

  useEffect(() => {
    const rejectedReasons = kycDetails?.rejected_reason;
    setIsPersonalInfoCompleted(
      PersonalInfoCompletionStatus(kycDetails, rejectedReasons)
    );
    setIsBusinessInfoCompleted(
      BusinessInfoCompletionStatus(kycDetails, rejectedReasons)
    );
    setIsGuarantorInfoCompleted(
      GuarantorInfoCompletionStatus(kycDetails, rejectedReasons)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitCompleteDetails = async () => {
    api
      .post("/kyc/submit")
      .then((response) => {
        const resObj = response?.data?.data;
        const kycObj = resObj?.kyc;
        setKycDetails(kycObj);
        setShowSucessModal(true);
      })
      .catch((error) => {
        const errMsg = error?.response?.data?.message;
        setError(errMsg);
      });
  };
  const renderAdditionalInfoSection = () => {
    if (Object.keys(lenderKycSchema)?.length < 1) {
      return <></>;
    }

    const groups = lenderKycSchema?.meta?.groups;
    const extraFieldCount = {};
    const extraDetails = kycDetails?.extra_details;
    const rejectedReasons = kycDetails?.rejected_reason;
    // eslint-disable-next-line array-callback-return
    Object.keys(lenderKycSchema?.properties).map((inputKeyName) => {
      const inputName = lenderKycSchema?.properties[inputKeyName];
      const isKeyPresent =
        Object.keys(extraFieldCount)?.length > 0 &&
        extraFieldCount.hasOwnProperty(inputName.groupId);
      const isFieldSubmitted =
        extraDetails &&
        Object.keys(extraDetails)?.length > 0 &&
        extraDetails.hasOwnProperty(inputKeyName);
      const isRejectedWithReason =
        rejectedReasons &&
        Object.keys(rejectedReasons)?.length > 0 &&
        rejectedReasons.hasOwnProperty(inputKeyName);
      if (!isKeyPresent) {
        Object.assign(extraFieldCount, {
          [inputName?.groupId]: {
            totalField: 0,
            submittedField: 0,
          },
        });
      } else {
        if (Object.keys(extraFieldCount)?.length > 0) {
          if (!isRejectedWithReason) {
            isFieldSubmitted &&
              extraFieldCount[inputName?.groupId].submittedField++;
          }
          extraFieldCount && extraFieldCount[inputName?.groupId].totalField++;
        }
      }
    });
    return (
      <>
        {Object.keys(groups).map((inputKeyName, index) => (
          <div
            className="flex items-center justify-between pt-[22px] pb-[46px] border-b-[1px] border-[#94a2ab66] font-normal mt-[40px] cursor-pointer"
            key={index}
            onClick={() =>
              navigate("/kyc/additional-info", {
                state: {
                  group_id: groups[inputKeyName]?.id,
                  display_name: groups[inputKeyName]?.displayName,
                },
              })
            }
          >
            <div className="text-[20px] leading-[14px] text-[#162930]">
              {groups[inputKeyName]?.displayName}
            </div>
            <div className="flex items-center">
              <div
                className={`text-[14px] leading-[14px] ${
                  extraFieldCount[inputKeyName].totalField ===
                  extraFieldCount[inputKeyName].submittedField
                    ? "text-[#2DB49E]"
                    : "text-[#ED5B5C]"
                }`}
              >
                {extraFieldCount[inputKeyName].totalField ===
                extraFieldCount[inputKeyName].submittedField
                  ? "Completed"
                  : "Pending"}
              </div>
              <div className="ml-[8px]">
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.99984 0L0.589844 1.41L5.16984 6L0.589844 10.59L1.99984 12L7.99984 6L1.99984 0Z"
                    fill={
                      extraFieldCount[inputKeyName].totalField ===
                      extraFieldCount[inputKeyName].submittedField
                        ? "#2DB49E"
                        : "#ED5B5C"
                    }
                  />
                </svg>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="md:flex md:items-center md:justify-center relative">
        <div className="hidden md:block z-[1]">
          <SideBar />
        </div>
        <div
          className={`w-full md:w-4/6 md:my-[50px] md:rounded-[20px] md:ml-[-100px] md:border-[#2db49e80] md:border-[1px] overflow-y-auto ${styles.pageHeight} bg-[#2db49e14] md:bg-transparent`}
        >
          <div className="container py-[20px] md:py-[40px] md:pl-[165px] md:pr-[40px] overflow-y-auto md:min-h-[690px] relative z-10">
            <div className="md:flex items-center justify-between">
              <div className="flex items-center">
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.58984 12L7.99984 10.59L3.41984 6L7.99984 1.41L6.58984 0L0.589844 6L6.58984 12Z"
                      fill="#2DB49E"
                    />
                  </svg>
                  <div className="ml-[30px] mr-[10px] text-[#192850] font-semibold text-[20px] leading-[25px]">
                    KYC
                  </div>
                </div>
                <div className="flex items-center pl-[10px]">
                  <div className="font-normal text-[16px] text-[#C59900] p-[10px] bg-[#c5990029] rounded-[10px]">
                    {profileCompletionStatus
                      ? "Pending submission"
                      : "Incomplete"}
                  </div>
                  <button
                    onClick={() => submitCompleteDetails()}
                    disabled={profileCompletionStatus ? false : true}
                    className={`${
                      profileCompletionStatus
                        ? "bg-[#2DB49E] cursor-pointer"
                        : "bg-[#BABFC1]"
                    }  rounded-[5px] font-semibold text-[14px] leading-[16px] text-[#fff] px-[20px]  py-[12px] ml-[10px]`}
                  >
                    Submit
                  </button>
                  <div className="text-[16px] font-bold text-[#FF0000] justify-center min-h-[24px] md:flex hidden ml-[15px]">
                    {error}
                  </div>
                </div>
              </div>
              <div className="text-[16px] font-bold text-[#FF0000] min-h-[24px] md:hidden block">
                {error}
              </div>
              <ProgressBar
                setProfileCompletionStatus={setProfileCompletionStatus}
              />
            </div>
            <div className="mt-[70px]">
              <div
                className="flex items-center justify-between pt-[22px] pb-[46px] border-b-[1px] border-[#94a2ab66] font-normal cursor-pointer"
                onClick={() => {
                  navigate("/kyc/personal-information-1");
                }}
              >
                <div className="text-[20px] leading-[14px] text-[#162930] ">
                  Personal Information
                </div>
                <div className="flex items-center">
                  <div
                    className={`text-[14px] leading-[14px] ${
                      isPersonalInfoCompleted === personalInfoValues?.length
                        ? "text-[#2DB49E]"
                        : "text-[#ED5B5C]"
                    }`}
                  >
                    {isPersonalInfoCompleted === personalInfoValues?.length
                      ? "Completed"
                      : "Pending"}
                  </div>
                  <div className="ml-[8px]">
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.99984 0L0.589844 1.41L5.16984 6L0.589844 10.59L1.99984 12L7.99984 6L1.99984 0Z"
                        fill={
                          isPersonalInfoCompleted === personalInfoValues?.length
                            ? "#2DB49E"
                            : "#ED5B5C"
                        }
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div
                className="flex items-center justify-between pt-[22px] pb-[46px] border-b-[1px] border-[#94a2ab66] font-normal mt-[40px] cursor-pointer"
                onClick={() => {
                  navigate("/kyc/business-information-1");
                }}
              >
                <div className="text-[20px] md:leading-[14px] text-[#162930] ">
                  Business Information
                </div>
                <div className="flex items-center">
                  <div
                    className={`text-[14px] leading-[14px] ${
                      isBusinessInfoCompleted === businessInfoValues?.length
                        ? "text-[#2DB49E]"
                        : "text-[#ED5B5C]"
                    }`}
                  >
                    {isBusinessInfoCompleted === businessInfoValues?.length
                      ? "Completed"
                      : "Pending"}
                  </div>
                  <div className="ml-[8px]">
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.99984 0L0.589844 1.41L5.16984 6L0.589844 10.59L1.99984 12L7.99984 6L1.99984 0Z"
                        fill={
                          isBusinessInfoCompleted === businessInfoValues?.length
                            ? "#2DB49E"
                            : "#ED5B5C"
                        }
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div
                className="flex items-center justify-between pt-[22px] pb-[46px] border-b-[1px] border-[#94a2ab66] font-normal mt-[40px] cursor-pointer"
                onClick={() => {
                  navigate("/kyc/reference-guarantor");
                }}
              >
                <div className="text-[20px] md:leading-[14px] text-[#162930] ">
                  References and Guarantors
                </div>
                <div className="flex items-center">
                  <div
                    className={`text-[14px] leading-[14px] ${
                      isGuarantorInfoCompleted === guarantorInfoValues?.length
                        ? "text-[#2DB49E]"
                        : "text-[#ED5B5C]"
                    }`}
                  >
                    {isGuarantorInfoCompleted === guarantorInfoValues?.length
                      ? "Completed"
                      : "Pending"}
                  </div>
                  <div className="ml-[8px]">
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.99984 0L0.589844 1.41L5.16984 6L0.589844 10.59L1.99984 12L7.99984 6L1.99984 0Z"
                        fill={
                          isGuarantorInfoCompleted ===
                          guarantorInfoValues?.length
                            ? "#2DB49E"
                            : "#ED5B5C"
                        }
                      />
                    </svg>
                  </div>
                </div>
              </div>
              {renderAdditionalInfoSection()}
            </div>
          </div>
          <KycSucess
            shown={showSucessModal}
            close={() => {
              setShowSucessModal(false);
            }}
          />
        </div>

        <div
          className={`absolute bottom-[160px] md:bottom-[105px] right-[10px] md:right-[-10px] ${styles.duploLogoOpaque} z-[-1]`}
        >
          <img
            src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
            alt="DuploLogo"
          />
        </div>
      </div>
    </>
  );
}

export default KycInfo;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { KycInfoSubmit, KycInfoSubmitPatch } from "../../Service/KycInfoSubmit";
import {
  validatePhoneNumber,
  validateEmail,
  validateGender,
  validateFirstName,
  validateLastName,
  validateOccupation,
} from "../../Utility/ValidationsWithJoi";
import styles from "./Kyc.module.scss";
import Calendar from "react-calendar";
import "./Calendar.css";
import SideBar from "../SideBar/SideBar";
import { useUserContext } from "../../Context/UserContext";
import {
  GenderDropdownOptions,
  EducationOptions,
  MaritalStatusOptions,
} from "../../Utility/DropdownOptions";
import ImageUploader from "../ImageUploader";
import { ReactComponent as CalendarIcon } from "../svg/calendarIcon.svg";
import AnimatedDropdown from "./AnimatedDropdown";
import { validate } from "../../Utility/Validations";

const DDMMYYYYToDate = (ddMMYYYYStr: string) => {
  if (!ddMMYYYYStr) {
    return new Date(2000, 0, 1);
  }
  const [dd, mm, yyyy] = ddMMYYYYStr.split("/");
  return new Date(Number(yyyy), Number(mm) - 1, Number(dd));
};

const DateToDDMMYYYY = (dateObj: Date) => {
  if (!dateObj) {
    return "";
  }
  return Intl.DateTimeFormat("en-GB").format(dateObj);
};

var dateObj = new Date();
dateObj.setDate(dateObj.getDate() - 1);

const PersonalInfoPgOne = () => {
  const navigate = useNavigate();
  const { userProfile, kycDetails, setKycDetails } = useUserContext();
  const [dob, onDOBChange] = useState<any>(
    kycDetails?.date_of_birth ? DDMMYYYYToDate(kycDetails?.date_of_birth) : ""
  );
  const [dobModal, setDobModal] = useState(false);
  const [showError, setShowError] = useState(false);

  const validateOptions = {
    abortEarly: false,
    converse: true,
    stripUnknown: true,
  };
  const rejectedReason = kycDetails?.rejected_reason;
  const [errors, setErrors] = useState({
    firstName: rejectedReason?.first_name || "",
    lastName: rejectedReason?.last_name || "",
    email: rejectedReason?.email || "",
    number: rejectedReason?.phone_number || "",
    gender: rejectedReason?.gender || "",
    dateOfBirth: rejectedReason?.date_of_birth || "",
    personalPhotoUrl: rejectedReason?.personal_photo_url || "",
    occupation: rejectedReason?.occupation || "",
    maritalStatus: rejectedReason?.marital_status || "",
    educationalQualification: rejectedReason?.educational_qualification || "",
    generalErr: "",
  });

  const [inputValues, setInputValues] = useState({
    firstName: kycDetails?.first_name || userProfile?.first_name || "",
    lastName: kycDetails?.last_name || userProfile?.last_name || "",
    email: kycDetails?.email || userProfile.email || "",
    number: kycDetails?.phone_number || userProfile.phone_number || "",
    gender: kycDetails?.gender || "",
    occupation: kycDetails?.occupation || "",
    dateOfBirth: kycDetails?.date_of_birth || "",
    maritalStatus: kycDetails?.marital_status || "",
    educationalQualification: kycDetails?.educational_qualification || "",
    kyc_personal_photo_document: kycDetails?.personal_photo_url || "",
  });

  const handleInput = (e: { target: { name: string; value: any } }) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };
  const setS3Url = (key: string, value: string) => {
    setInputValues({ ...inputValues, [key]: value });
  };
  const validationField = (schema: any, value: any, field: any) => {
    if (field === "firstName" || field === "lastName") {
      if (
        validate("last_name", value) !== "" ||
        validate("first_name", value) !== ""
      ) {
        if (field === "firstName") {
          setErrors({
            ...errors,
            firstName: validate("first_name", value),
          });
        }

        if (field === "lastName") {
          setErrors({
            ...errors,
            lastName: validate("last_name", value),
          });
        }
        return;
      }
    }
    const err = JSON.parse(JSON.stringify(errors));
    const res = schema.validate(value, validateOptions);
    let errorsList: any = {};
    if (res.error) {
      res.error.details.forEach((error: any) => {
        errorsList[field] = error.message;
      });
      setErrors({
        ...errors,
        ...errorsList,
      });
    } else {
      delete err[field];
      setErrors(err);
    }
  };

  const validateInput = (e: { target: { name: string; value: any } }) => {
    switch (e.target.name) {
      case "firstName":
        validationField(validateFirstName, e.target.value, "firstName");
        break;
      case "lastName":
        validationField(validateLastName, e.target.value, "lastName");
        break;
      case "email":
        validationField(validateEmail, e.target.value, "email");
        break;
      case "number":
        validationField(validatePhoneNumber, e.target.value, "number");
        break;
      case "gender":
        validationField(validateGender, e.target.value, "gender");
        break;
      case "occupation":
        validationField(validateOccupation, e.target.value, "occupation");
        break;
    }
  };

  const formSubmit = async (e: any) => {
    e.preventDefault();
    const payload = {
      first_name: inputValues?.firstName,
      last_name: inputValues?.lastName,
      email: inputValues?.email,
      phone_number: inputValues?.number,
      gender: inputValues?.gender,
      date_of_birth: inputValues?.dateOfBirth,
      personal_photo_url: inputValues?.kyc_personal_photo_document,
      occupation: inputValues?.occupation,
      marital_status: inputValues?.maritalStatus,
      educational_qualification: inputValues?.educationalQualification,
    };

    let responseData: any = null;

    if (inputValues.firstName || inputValues.lastName) {
      if (
        validate("last_name", inputValues.lastName) !== "" ||
        validate("first_name", inputValues.firstName) !== ""
      ) {
        setErrors({
          ...errors,
          firstName: validate("first_name", inputValues.firstName),
          lastName: validate("last_name", inputValues.lastName),
        });

        return;
      }
    }

    if (userProfile?.kyc_id) {
      const finalPayload = {};

      if (
        kycDetails.business_status === "REGISTERED" &&
        kycDetails.business_type === "Limited Liability Company"
      ) {
        delete kycDetails.bn_number;
        delete kycDetails.certificate_of_registration_document_url;
      }

      if (
        kycDetails.business_status === "REGISTERED" &&
        kycDetails.business_type === "Sole Proprietorship"
      ) {
        delete kycDetails.cac_document_url;
        delete kycDetails.business_rc_number;
      }

      if (
        kycDetails.business_status === "UNREGISTERED" ||
        !kycDetails.business_status
      ) {
        delete kycDetails.business_type;
        delete kycDetails.bn_number;
        delete kycDetails.certificate_of_registration_document_url;
        delete kycDetails.business_type;
        delete kycDetails.business_rc_number;
        delete kycDetails.cac_document_url;
      }

      Object.assign(finalPayload, kycDetails, payload);

      responseData = await KycInfoSubmitPatch(finalPayload);
    } else {
      responseData = await KycInfoSubmit(payload);
      if (responseData.status === 200) {
        const resObj = responseData?.data?.data;
        const kycObj = resObj?.kyc;
        userProfile.kyc_id = kycObj?.id;
      }
    }

    if (responseData.status === 200) {
      const resObj = responseData?.data?.data;
      const kycObj = resObj?.kyc;
      setKycDetails(kycObj);
      navigate("/kyc/personal-information-2");
    } else {
      const errData = responseData?.data?.errors;
      let generalErrorMsg = "";
      if (Object.keys(errData)?.length === 0) {
        generalErrorMsg = responseData?.data?.message;
      }
      if (errData?.personal_photo_url) {
        setShowError(true);
      }
      setErrors({
        firstName: errData?.first_name,
        lastName: errData?.last_name,
        email: errData?.email,
        number: errData?.phone_number,
        gender: errData?.gender,
        dateOfBirth: errData?.date_of_birth,
        maritalStatus: errData?.marital_status,
        educationalQualification: errData?.educational_qualification,
        personalPhotoUrl: errData?.personal_photo_url,
        generalErr: generalErrorMsg,
        occupation: errData?.occupation,
      });
    }
  };
  const setDropDownValues = (e: any, name: string) => {
    setInputValues({
      ...inputValues,
      [name]: e.value,
    });
    errors[name] = "";
  };

  useEffect(() => {
    setInputValues({
      ...inputValues,
      dateOfBirth: typeof dob !== "string" ? DateToDDMMYYYY(dob) : "",
    });
    // eslint-disable-next-line
  }, [dob]);

  return (
    <>
      <div className="md:flex md:items-center md:justify-center relative">
        <div className="hidden md:block z-50">
          <SideBar />
        </div>
        <div
          className={`w-full md:w-4/6 md:my-[50px] md:rounded-[20px] md:ml-[-100px] md:border-[#2db49e80] md:border-[1px] overflow-y-auto ${styles.pageHeight}`}
        >
          <div className="py-[20px] md:py-[30px] md:pl-[165px] md:pr-[40px] md:min-h-[690px] bg-[#2db49e1a] md:bg-transparent">
            <div className="container ">
              <div className="flex justify-between items-center mb-[40px]">
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => {
                    navigate("/kyc");
                  }}
                >
                  <div>
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.58984 12L7.99984 10.59L3.41984 6L7.99984 1.41L6.58984 0L0.589844 6L6.58984 12Z"
                        fill="#2DB49E"
                      />
                    </svg>
                  </div>
                  <div className="ml-[20px] font-semibold text-[20px] leading-[25px] text-[#192850]">
                    Personal Information
                  </div>
                </div>
                <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                  1 of 2
                </div>
              </div>
              <form onSubmit={(e) => formSubmit(e)}>
                <div className="md:grid md:grid-cols-2 md:gap-[20px]">
                  <div className="relative">
                    <div className="md:grid md:grid-cols-2 md:gap-[20px]">
                      <div>
                        <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                          First Name
                        </div>
                        <input
                          className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                          id="firstName"
                          type="text"
                          name="firstName"
                          value={inputValues?.firstName}
                          disabled={
                            kycDetails?.first_name || userProfile?.first_name
                          }
                          autoComplete="off"
                          onChange={(e) => handleInput(e)}
                          onBlur={(e) => validateInput(e)}
                        />
                        <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                          {errors.firstName}
                        </div>
                      </div>
                      <div>
                        <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                          Last Name
                        </div>
                        <input
                          className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                          id="lastName"
                          type="text"
                          name="lastName"
                          disabled={
                            kycDetails?.last_name || userProfile?.last_name
                          }
                          value={inputValues?.lastName}
                          autoComplete="off"
                          onChange={(e) => handleInput(e)}
                          onBlur={(e) => validateInput(e)}
                        />
                        <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                          {errors.lastName}
                        </div>
                      </div>
                    </div>
                    <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px]">
                      Email
                    </div>
                    <input
                      className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                      id="email"
                      type="text"
                      value={inputValues?.email}
                      name="email"
                      disabled
                      autoComplete="off"
                      onChange={(e) => handleInput(e)}
                      onBlur={(e) => validateInput(e)}
                    />
                    <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                      {errors.email}
                    </div>
                    <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px]">
                      Date of Birth
                    </div>
                    <div
                      className="border rounded w-full px-3 py-[10px] leading-tight mt-[10px] cursor-pointer bg-[#fff] flex items-center justify-between"
                      onClick={() => {
                        setDobModal(!dobModal);
                      }}
                    >
                      <div>
                        {typeof dob !== "string" &&
                          dob.toLocaleDateString("en-GB")}
                      </div>
                      <div>
                        <CalendarIcon />
                      </div>
                    </div>
                    <div
                      className={`absolute z-10 ${
                        !dobModal && "hidden"
                      } mt-[4px]`}
                    >
                      <Calendar
                        onChange={onDOBChange}
                        value={typeof dob !== "string" ? dob : null}
                        className={`rounded-[10px]`}
                        onClickDay={() => {
                          setDobModal(false);
                        }}
                        prev2Label={null}
                        next2Label={null}
                        locale="en-GB"
                        maxDate={dateObj}
                        formatMonth={(locale, value) =>
                          [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                            "Oct",
                            "Nov",
                            "Dec",
                          ][value.getMonth()]
                        }
                        formatShortWeekday={(locale, value) =>
                          ["S", "M", "T", "W", "T", "F", "S"][value.getDay()]
                        }
                      />
                    </div>
                    <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                      {errors?.dateOfBirth}
                    </div>
                    <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px]">
                      Phone number
                    </div>
                    <input
                      className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                      id="number"
                      type="text"
                      value={inputValues?.number}
                      name="number"
                      disabled
                      autoComplete="off"
                      maxLength={11}
                      onChange={(e) => handleInput(e)}
                      onBlur={(e) => validateInput(e)}
                    />
                    <div className="text-[12px] text-[#192850] min-h-[20px]">
                      Please enter NGN format. E.g 08122934834
                    </div>
                    <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                      {errors.number}
                    </div>
                    <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px]">
                      Gender
                    </div>
                    <AnimatedDropdown
                      setDropDownValues={setDropDownValues}
                      dropdownOption={GenderDropdownOptions}
                      type={"gender"}
                      selectedValue={inputValues?.gender}
                      heading={"Select your Gender"}
                    />
                    <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                      {errors.gender}
                    </div>
                    <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                      Occupation
                    </div>
                    <input
                      className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[15px]"
                      id="occupation"
                      type="text"
                      value={inputValues?.occupation}
                      onChange={(e) => handleInput(e)}
                      name="occupation"
                      autoComplete="off"
                      onBlur={(e) => validateInput(e)}
                    />
                    <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                      {errors?.occupation}
                    </div>
                  </div>
                  <div>
                    <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                      Marital Status
                    </div>
                    <AnimatedDropdown
                      setDropDownValues={setDropDownValues}
                      dropdownOption={MaritalStatusOptions}
                      type={"maritalStatus"}
                      selectedValue={inputValues?.maritalStatus}
                      heading={"Select your Marital Status"}
                    />
                    <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                      {errors.maritalStatus}
                    </div>

                    <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px]">
                      Educational Qualification
                    </div>

                    <AnimatedDropdown
                      setDropDownValues={setDropDownValues}
                      dropdownOption={EducationOptions}
                      type={"educationalQualification"}
                      selectedValue={inputValues?.educationalQualification}
                      heading={"Select your Educational Qualification"}
                    />
                    <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                      {errors.educationalQualification}
                    </div>
                    <div className="mt-[5px]">
                      <ImageUploader
                        fileTypeIntent={"kyc_personal_photo_document"}
                        setS3Url={setS3Url}
                        setShowError={setShowError}
                      />
                      <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                        {showError ? `${errors?.personalPhotoUrl}` : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center mt-[20px]">
                  <button
                    className="bg-[#2DB49E] rounded-[5px] font-semibold text-[14px] leading-[16px] text-[#fff] w-full md:w-1/2 py-[12px]"
                    type="submit"
                  >
                    Next
                  </button>
                </div>
                <div className="text-[16px] font-bold text-[#FF0000] flex justify-center mt-[10px] min-h-[24px]">
                  {errors.generalErr}
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          className={`absolute bottom-[160px] md:bottom-[105px] right-[10px] md:right-[-10px] ${styles.duploLogoOpaque} z-[-1]`}
        >
          <img
            src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
            alt="DuploLogo"
          />
        </div>
      </div>
    </>
  );
};

export default PersonalInfoPgOne;

const config = {
  API_ENDPOINT: process.env.REACT_APP_API_BASE_URL as string,
  GATEWAY: process.env.REACT_APP_GATEWAY_BASE_URL as string,
  HOTJAR_ID: process.env.REACT_APP_HOTJAR_ID,
  HOTJAR_SNIPPET_VERSION: process.env.REACT_APP_HOTJAR_SNIPPET_VERSION,
  PROD: process.env.NODE_ENV === "production",
  RUDDERSTACK_DATA_PLAIN_URL: process.env
    .REACT_APP_RUDDERSTACK_DATAPLANE_URL as string,
  RUDDERSTACK_WRITE_KEY: process.env.REACT_APP_RUDDERSTACK_APP_KEY as string,
};

export default config;

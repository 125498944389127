import { useState } from "react";
import { useNavigate } from "react-router";
import styles from "../Kyc.module.scss";
import "../Calendar.css";
import { useUserContext } from "../../../Context/UserContext";
import {
  KycInfoSubmitPatch,
  KycInfoSubmit,
} from "../../../Service/KycInfoSubmit";
import { NextOfKinOptions } from "../../../Utility/DropdownOptions";
import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePhoneNumber,
} from "../../../Utility/ValidationsWithJoi";
import SideBar from "../../SideBar/SideBar";
import SearchableDropdown from "../SearchableDropdown";

var dateObj = new Date();
dateObj.setDate(dateObj.getDate() - 1);

const AdditionalInfo = () => {
  const navigate = useNavigate();
  const { userProfile, kycDetails, setKycDetails } = useUserContext();

  const validateOptions = {
    abortEarly: false,
    converse: true,
    stripUnknown: true,
  };
  const rejectedReason = kycDetails?.rejected_reason;
  const [errors, setErrors] = useState({
    firstName: rejectedReason?.first_name || "",
    lastName: rejectedReason?.last_name || "",
    email: rejectedReason?.email || "",
    number: rejectedReason?.phone_number || "",
    gender: rejectedReason?.gender || "",
    address: rejectedReason?.address || "",
    relationship: rejectedReason?.relationship || "",
    generalErr: "",
  });

  const [inputValues, setInputValues] = useState({
    firstName: kycDetails?.extra_details?.nok_first_name || "",
    lastName: kycDetails?.extra_details?.nok_last_name || "",
    email: kycDetails?.extra_details?.nok_email || "",
    address: kycDetails?.extra_details?.nok_address || "",
    number: kycDetails?.extra_details?.nok_phone || "",
    relationship: kycDetails?.extra_details?.nok_relationship || "",
  });

  const handleInput = (e: { target: { name: string; value: any } }) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const validationField = (schema: any, value: any, field: any) => {
    const err = JSON.parse(JSON.stringify(errors));
    const res = schema.validate(value, validateOptions);
    let errorsList: any = {};
    if (res.error) {
      res.error.details.forEach((error: any) => {
        errorsList[field] = error.message;
      });
      setErrors({
        ...errors,
        ...errorsList,
      });
    } else {
      delete err[field];
      setErrors(err);
    }
  };

  const validateInput = (e: { target: { name: string; value: any } }) => {
    switch (e.target.name) {
      case "firstName":
        validationField(validateFirstName, e.target.value, "firstName");
        break;
      case "lastName":
        validationField(validateLastName, e.target.value, "lastName");
        break;
      case "email":
        validationField(validateEmail, e.target.value, "email");
        break;
      case "number":
        validationField(validatePhoneNumber, e.target.value, "number");
        break;
    }
  };

  const formSubmit = async (e: any) => {
    e.preventDefault();
    const payload = {
      extra_details: {
        nok_address: inputValues?.address,
        nok_email: inputValues?.email,
        nok_first_name: inputValues?.firstName,
        nok_last_name: inputValues?.lastName,
        nok_phone: inputValues?.number,
        nok_relationship: inputValues?.relationship,
      },
    };

    if (
      kycDetails.business_status === "REGISTERED" &&
      kycDetails.business_type === "Limited Liability Company"
    ) {
      delete kycDetails.bn_number;
      delete kycDetails.certificate_of_registration_document_url;
    }

    if (
      kycDetails.business_status === "REGISTERED" &&
      kycDetails.business_type === "Sole Proprietorship"
    ) {
      delete kycDetails.cac_document_url;
      delete kycDetails.business_rc_number;
    }

    if (
      kycDetails.business_status === "UNREGISTERED" ||
      kycDetails.business_status === ""
    ) {
      delete kycDetails.business_type;
      delete kycDetails.bn_number;
      delete kycDetails.certificate_of_registration_document_url;
      delete kycDetails.business_type;
      delete kycDetails.business_rc_number;
      delete kycDetails.cac_document_url;
    }

    let responseData: any = null;
    if (userProfile?.kyc_id) {
      const finalPayload = {};
      Object.assign(finalPayload, kycDetails, payload);
      responseData = await KycInfoSubmitPatch(finalPayload);
    } else {
      responseData = await KycInfoSubmit(payload);
      if (responseData.status === 200) {
        const resObj = responseData?.data?.data;
        const kycObj = resObj?.kyc;
        userProfile.kyc_id = kycObj?.id;
      }
    }

    if (responseData.status === 200) {
      const resObj = responseData?.data?.data;
      const kycObj = resObj?.kyc;
      setKycDetails(kycObj);
      navigate("/kyc");
    } else {
      const errData = responseData?.data?.errors;
      let generalErrorMsg = "";
      if (Object.keys(errData)?.length === 0) {
        generalErrorMsg = responseData?.data?.message;
      }

      console.log(errData);

      setErrors({
        firstName: errData?.nok_first_name,
        lastName: errData?.nok_last_name,
        email: errData?.nok_email,
        number: errData?.nok_phone,
        gender: errData?.gender,
        address: errData?.nok_address,
        relationship: errData?.nok_relationship,
        generalErr: generalErrorMsg,
      });
    }
  };
  const setDropDownValues = (e: any, name: string) => {
    setInputValues({
      ...inputValues,
      [name]: e.value,
    });
    errors[name] = "";
  };

  return (
    <>
      <div className="md:flex md:items-center md:justify-center relative">
        <div className="hidden md:block z-50">
          <SideBar />
        </div>
        <div
          className={`w-full md:w-4/6 md:my-[50px] md:rounded-[20px] md:ml-[-100px] md:border-[#2db49e80] md:border-[1px] overflow-y-auto ${styles.pageHeight}`}
        >
          <div className="py-[20px] md:py-[30px] md:pl-[165px] md:pr-[40px] md:min-h-[690px] bg-[#2db49e1a] md:bg-transparent">
            <div className="container ">
              <div className="flex justify-between items-center mb-[40px]">
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => {
                    navigate("/kyc");
                  }}
                >
                  <div>
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.58984 12L7.99984 10.59L3.41984 6L7.99984 1.41L6.58984 0L0.589844 6L6.58984 12Z"
                        fill="#2DB49E"
                      />
                    </svg>
                  </div>
                  <div className="ml-[20px] font-semibold text-[20px] leading-[25px] text-[#192850]">
                    Next of Kin
                  </div>
                </div>
              </div>
              <form onSubmit={(e) => formSubmit(e)}>
                <div className="md:grid md:grid-cols-2 md:gap-[20px]">
                  <div className="relative">
                    <div className="md:grid md:grid-cols-2 md:gap-[20px]">
                      <div>
                        <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                          First Name
                        </div>
                        <input
                          className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                          id="firstName"
                          type="text"
                          name="firstName"
                          value={inputValues?.firstName}
                          autoComplete="off"
                          onChange={(e) => handleInput(e)}
                          onBlur={(e) => validateInput(e)}
                        />
                        <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                          {errors.firstName}
                        </div>
                      </div>
                      <div>
                        <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                          Last Name
                        </div>
                        <input
                          className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                          id="lastName"
                          type="text"
                          name="lastName"
                          value={inputValues?.lastName}
                          autoComplete="off"
                          onChange={(e) => handleInput(e)}
                          onBlur={(e) => validateInput(e)}
                        />
                        <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                          {errors.lastName}
                        </div>
                      </div>
                    </div>
                    <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px]">
                      Phone number
                    </div>
                    <input
                      className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                      id="number"
                      type="text"
                      value={inputValues?.number}
                      name="number"
                      autoComplete="off"
                      maxLength={11}
                      onChange={(e) => handleInput(e)}
                      onBlur={(e) => validateInput(e)}
                    />
                    <div className="text-[12px] text-[#192850] min-h-[20px]">
                      Please enter NGN format. E.g 08122934834
                    </div>
                    <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                      {errors.number}
                    </div>

                    <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px]">
                      Email
                    </div>
                    <input
                      className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                      id="email"
                      type="text"
                      value={inputValues?.email}
                      name="email"
                      autoComplete="off"
                      onChange={(e) => handleInput(e)}
                      onBlur={(e) => validateInput(e)}
                    />
                    <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                      {errors.email}
                    </div>

                    <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px] mb-3">
                      Address
                    </div>
                    <textarea
                      value={inputValues?.address}
                      name="address"
                      maxLength={50}
                      onChange={(e) => handleInput(e)}
                      onBlur={(e) => validateInput(e)}
                      className="block p-2.5 w-full text-sm rounded-lg border h-[75px] resize-none outline-none"
                    ></textarea>

                    <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                      {errors?.address}
                    </div>
                  </div>
                  <div>
                    <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                      Relationship
                    </div>

                    <SearchableDropdown
                      setDropDownValues={setDropDownValues}
                      type={"relationship"}
                      selectedValue={inputValues?.relationship}
                      dropdownOptions={NextOfKinOptions}
                      heading={"Select your relationship"}
                      disableField={false}
                    />

                    <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                      {errors.relationship}
                    </div>
                  </div>
                </div>
                <div className="flex justify-center mt-[20px]">
                  <button
                    className="bg-[#2DB49E] rounded-[5px] font-semibold text-[14px] leading-[16px] text-[#fff] w-full md:w-1/2 py-[12px]"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
                <div className="text-[16px] font-bold text-[#FF0000] flex justify-center mt-[10px] min-h-[24px]">
                  {errors.generalErr}
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          className={`absolute bottom-[160px] md:bottom-[105px] right-[10px] md:right-[-10px] ${styles.duploLogoOpaque} z-[-1]`}
        >
          <img
            src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
            alt="DuploLogo"
          />
        </div>
      </div>
    </>
  );
};

export default AdditionalInfo;

import {
    useState,
    createContext,
    useContext,
    Dispatch,
    SetStateAction,
  } from "react";
  
  type CheckoutContext = {
    checkoutRef: any;
    setCheckoutRef: Dispatch<SetStateAction<any>>;
    checkoutDetails: any;
    setCheckoutDetails: Dispatch<SetStateAction<any>>;
    getDirectTransferUrl: () => string;

  };
  const DIRECT_TRANSFER_BASE_URL= process.env.REACT_APP_DIRECT_TRANSFER_BASE_URL;
  export const Context = createContext<CheckoutContext>({
    checkoutRef: {},
    setCheckoutRef: () => {},
    checkoutDetails: {},
    setCheckoutDetails: () => {},
    getDirectTransferUrl: () => { return '';}
  });

  export const CheckoutRefProvider = ({ children }: any) => {
    const [checkoutRef, setCheckoutRef] = useState('');    
    const [checkoutDetails, setCheckoutDetails] = useState('');   

    const getDirectTransferUrl = () => {
      return `${DIRECT_TRANSFER_BASE_URL}?reference=${checkoutRef}`
    }    
    return (
      <Context.Provider value={{ checkoutRef, setCheckoutRef, checkoutDetails,setCheckoutDetails, getDirectTransferUrl }}>
        {children}
      </Context.Provider>
    );
  };
  
  export const useCheckoutContext = () => useContext(Context);
  
export const CurrentBusinessDropdownStyle = {
  control: (styles: any) => ({
    ...styles,
    display: 'none',
  }),
  menu: (base: any) => ({
    ...base,
    position: 'relative',
    marginBottom: '5px',
    marginTop: '0px',
    paddingTop: '0px',
    boxShadow: 'none',
    borderRadius: '12px',
    backgroundColor: "#103129",
  }),
  indicatorSeparator: () => ({ display: "none" }),
  option: (styles: any, { data, isDisable, isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: "#103129",
      cursor: "pointer",
      color: "#fff",  
      paddingTop:'0px',
      paddingBottom:'0px',
      fontWeight: isSelected ? '500' : '400',
      "&:hover": {
        backgroundColor: "#103129",
      },
    };
  },
};
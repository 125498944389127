import styles from './LoanHistory.module.scss'

const NoActiveLoans = () => {
  return (
    <>
      {window.innerWidth >= 768 ? (
        <div className={`py-[35px] w-full ${styles.loanDetails}`}>
          <div className="px-[25px] grid grid-cols-2 md:grid-cols-3 pb-[40px] border-b-[5px] border-b-[#F4F9F5]">
            <div>
              <div className="font-normal text-[#103129] text-[14px] leading-[14px]">
                Total Repayment Amount
              </div>
              <div className="font-medium text-[30px] leading-[21px] text-[#103129] mt-[20px]">
                ₦ 0.00
              </div>
            </div>
            <div>
              <div className="font-light text-[12px] leading-[16px] text-[#5C696E]">
                Interest incurred
              </div>
              <div className="font-semibold text-[16px] leading-[20px] text-[#003F34] mt-[10px]">
                N/A
              </div>
            </div>
          </div>
          <div className="px-[25px] grid grid-cols-2 md:grid-cols-3 pt-[40px]">
            <div>
              <div className="font-semibold text-[16px] leading-[20px] text-[#003F34]">
                Next repayment
              </div>
              <div className="flex items-center mt-[10px] font-light text-[12px] leading-[16px] text-[#5C696E]">
                <div>-</div>
                <span className="mx-[7px] h-[4px] w-[4px] rounded-full bg-[#D9D9D9]"></span>
                <div>-</div>
              </div>
            </div>
            <div className="grid content-center font-semibold text-[20px] leading-[25px] text-[#003F34]">
              ₦0
            </div>
            <button
              className={`opacity-50 border-[1px] border-[#2DB49E] rounded-[5px] text-[#2DB49E] text-[14px] leading-[18px] font-medium py-[10px] mt-[10px] md:mt-0 col-span-2 md:col-span-1`}
              disabled
            >
              Make Repayment
            </button>
          </div>
        </div>
      ) : (
        <div className="w-full px-[25px] py-[40px] border-b-[5px] border-b-[#F4F9F5] bg-[#fff]">
          <div>
            <div className="font-normal text-[#103129] text-[14px] leading-[14px]">
              Total Repayment Amount
            </div>
            <div className="font-medium text-[30px] leading-[21px] text-[#103129] mt-[20px]">
              ₦ 0.00
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default NoActiveLoans

import { useEffect, useRef, useState } from "react";
import styles from "./HomePage.module.scss";
import { useUserContext } from "../../Context/UserContext";
import KycApprovedBanner from "./KycApprovedBanner";
import KycPendingBanner from "./KycPendingBanner";
import NoActiveLoans from "./NoActiveLoans";
import KycRejectedWithReasonBanner from "./KycRejectedWithReasonBanner";
import { BusinessOptions } from "../../Utility/DropdownOptions";
import { api } from "../../Service/AuthService";
import ActiveLoans from "./ActiveLoans";
import CurrentBusinessDropdown from "./CurrentBusinessDropdown";
import CurrentBusiness from "./CurrentBusiness";
import HomeProfile from "./HomeProfile";
import { CurrencyFormat } from "../../Utility/CurrencyFormat";
import PayBusinessModal from "./PayBusinessModal";
import { ReactComponent as CircularCrossIcon } from "../svg/circularCrossIcon.svg";

const HomeTab = () => {
  const [modalShown, toggleModal] = useState<Boolean>(false);
  const [businessList, setBusinessList] = useState([]);
  const [accountRef, setAccountRef] = useState();
  const [virtualAcc, setVirtualAcc] = useState();
  const dataFetchedRef = useRef(false);
  const [creditLimit, setCreditLimit] = useState(0);
  const [creditLimitLoader, setCreditLimitLoader] = useState(false);
  //region - Application Boot check.
  const userContextUtil = useUserContext();
  const kycDetails = userContextUtil.kycDetails;
  const isBootComplete = () => {
    return (
      userContextUtil.userProfile &&
      userContextUtil.kycDetails &&
      userContextUtil.lenderKycSchema
    );
  };

  const getVirtualAccountDetails = () => {
    api
      .get("/virtual-account", {
        params: { account_ref: accountRef },
      })
      .then((response) => {
        const responseData = response?.data?.data;
        const virtualAccount = responseData?.virtual_account;
        setVirtualAcc(virtualAccount);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getCreditLimit = () => {
    setCreditLimitLoader(true);
    api
      .get("/credit-limit")
      .then((response) => {
        const responseData = response?.data?.data;
        const creditLimit = responseData?.credit_limit;
        setCreditLimit(creditLimit?.credit_limit);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setCreditLimitLoader(false);
      });
  };

  useEffect(() => {
    if (isBootComplete()) {
    } else {
      // Let's figure out what to load.
      if (!userContextUtil.kycDetails) {
        // Load Kyc Details.
        userContextUtil.fetchKycDetails();
      }
      if (!userContextUtil.lenderKycSchema) {
        // Load Lender Kyc Schema
        userContextUtil.fetchKycSchema();
      }
    }
    if (!userContextUtil.lenderKycSchema) {
      // Load Lender Kyc Schema
      userContextUtil.fetchKycSchema();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //endregion

  const getBusinessOptions = async () => {
    const businessName: any = await BusinessOptions();
    setBusinessList(businessName);
    setAccountRef(businessName?.[0]?.value);
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getBusinessOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getKycStatusBanner = () => {
    if (kycDetails?.status === "APPROVED") {
      return <KycApprovedBanner />;
    } else if (kycDetails?.status === "REJECTED_WITH_REASON") {
      return <KycRejectedWithReasonBanner />;
    } else if (!kycDetails?.status || kycDetails?.status === "PENDING") {
      return <KycPendingBanner />;
    }
  };

  useEffect(() => {
    getCreditLimit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getVirtualAccountDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountRef]);

  return (
    <div
      className={`container py-[20px] md:py-[40px] md:pl-[165px] md:pr-[40px] overflow-y-auto md:min-h-[690px] relative`}
    >
      <HomeProfile />

      <div className={`${styles.businessCard} md:px-[40px] px-[20px]`}>
        <div className="lg:flex justify-between items-center">
          <div className="pt-[6%]">
            <p className="text-[#EEEEEE] text-[16px]">Credit Limit:</p>
            <p className="text-[#DDDDDD] text-[40px] font-[600]">
              {" "}
              {creditLimitLoader ? (
                <span className="text-[20px] font-[600]">Loading...</span>
              ) : (
                CurrencyFormat(creditLimit)
              )}
            </p>
          </div>

          <div className="w-[175px]">
            {businessList && businessList.length > 0 && (
              <>
                <p className="text-[#2DB49E] text-[12px] px-[15px]">
                  Select Business
                </p>

                <div className="relative mt-[20px] md:mb-0 mb-10">
                  <>
                    {window.innerWidth < 768 ? (
                      <CurrentBusinessDropdown
                        businessList={businessList}
                        setAccountRef={setAccountRef}
                      />
                    ) : (
                      <CurrentBusiness
                        setAccountRef={setAccountRef}
                        businessList={businessList}
                      />
                    )}
                  </>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="mt-5">{getKycStatusBanner()}</div>

      <div>
        <div className="invisible hidden">
          <button
            className={`flex items-center justify-between bg-[#2DB49E] rounded-[8px] py-[8px] px-[8px] md:py-[12px] md:px-[50px]`}
            disabled={accountRef ? false : true}
            onClick={() => {
              getVirtualAccountDetails();
              toggleModal(!modalShown);
            }}
          >
            <div>
              <CircularCrossIcon />
            </div>
            <div className="text-[#103129] font-bold text-[12px] leading-[16px] ml-[9px]">
              Pay Business
            </div>
          </button>
        </div>
        <PayBusinessModal
          shown={modalShown}
          virtualAcc={virtualAcc}
          close={() => {
            toggleModal(false);
          }}
        />
      </div>
      {kycDetails?.status === "ACTIVATED" ? <ActiveLoans /> : <NoActiveLoans />}
    </div>
  );
};

export default HomeTab;

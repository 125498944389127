import * as Joi from "joi";
export const validatePhoneNumber = Joi.string()
  .pattern(/^[0-9]+$/)
  .min(11)
  .label("Phone number")
  .messages({
    "string.min": "Phone number should be 11 digit.",
    "string.pattern.base": "Phone number should consist of only numbers.",
    "any.required": "Please enter your phone number.",
    "string.empty": "Please enter your phone number.",
  });

export const validateEmail = Joi.string()
  .email({ tlds: { allow: false } })
  .label("Email")
  .messages({
    "string.email": "Invalid Email.",
    "string.empty": "Please enter your email address.",
    "any.required": "Please enter your email address.",
  });

export const validatePassword = Joi.string()
  .regex(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[@!#$%^&*()]).*$/)
  .label("Password")
  .messages({
    "string.pattern.base":
      "Password must be at least 8 characters long and must contain a number, a special character, an uppercase and a lowercase letter",
    "string.empty": "Please enter your password.",
    "any.required": "Please enter your password.",
  });

export const validateConfirmPassword = Joi.string()
  .equal(Joi.ref("password"))
  .label("Confirm password")
  .messages({
    "any.only": "Confirm password does not match.",
    "any.required": "Please confirm your password.",
  });

export const validateFullname = Joi.string()
  .regex(/^(\w.+\s).+/)
  .label("full name")
  .messages({
    "string.pattern.base": "Full name must contain atleast two words.",
    "string.empty": "Please entrer your full name.",
    "any.required": "Please entrer your full name.",
  });

export const validateFirstName = Joi.string().label("first name").messages({
  "string.empty": "Please enter your first name.",
  "any.required": "Please enter your first name.",
});
export const validateLastName = Joi.string().label("last name").messages({
  "string.empty": "Please enter your last name.",
  "any.required": "Please enter your last name.",
});

export const validateAddress = Joi.string().label("Address").messages({
  "string.empty": "Please enter your home address.",
  "any.required": "Please enter your home address.",
});

export const validateLga = Joi.string().label("Address").messages({
  "string.empty": "please enter your LGA.",
  "any.required": "please enter your LGA.",
});

export const validateGender = Joi.string()
  .regex(/^[A-Za-z]/)
  .label("Gender")
  .messages({
    "string.pattern.base": "Please enter valid gender.",
    "string.empty": "Please select your gender.",
    "any.required": "Please select your gender.",
  });

export const validateOnlineLink = Joi.string()
  .pattern(
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
  )
  .label("Online link")
  .messages({
    "string.empty": "",
    "any.required": "",
    "string.pattern.base": "Please enter valid link.",
  });

export const validateMaritalStatus = Joi.string()
  .pattern(/^[a-zA-Z0-9\s,'-]*$/)
  .label("Marital Status")
  .messages({
    "string.pattern.base": "Please enter valid Marital Status.",
    "string.empty": "Please select your marital status.",
    "any.required": "Please select your marital status.",
  });

export const validateQualification = Joi.string()
  .label("Qualification")
  .messages({
    "string.pattern.base": "Please Select valid qualification.",
    "string.empty": "Please select education qualification.",
    "any.required": "Please select education qualification.",
  });

export const validateIDCard = Joi.string().label("ID Card Type ").messages({
  "string.pattern.base": "Please Select valid qualification.",
  "string.empty": "Please select your ID card type.",
  "any.required": "Please select your ID card type.",
});

export const validateBusinessName = Joi.string()
  .regex(/^[A-Za-z]/)
  .label("Business name")
  .messages({
    "string.pattern.base": "Please enter valid business name.",
    "string.empty": "Please enter your business name.",
    "any.required": "Please enter your business name.",
  });
export const validateBusinessRole = Joi.string()
  .label("Business Role")
  .messages({
    "string.empty": "Please enter business role.",
    "any.required": "Please enter business role.",
  });

export const validateBVN = Joi.string()
  .pattern(/^[0-9]+$/)
  .label("BVN")
  .min(11)
  .messages({
    "string.pattern.base": "BVN should consist of only numbers.",
    "string.min": "BVN should be 11 digit.",
    "any.required": "Please enter your BVN.",
    "string.empty": "Please enter your BVN.",
  });

export const validateRelationship = Joi.string()
  .label("Relationship")
  .messages({
    "string.empty": "Please enter the relationship.",
    "any.required": "Please enter the relationship.",
  });
export const validateBankAccountNumber = Joi.string()
  .pattern(/^[0-9]+$/)
  .min(10)
  .label("Bank Account Number")
  .messages({
    "string.pattern.base":
      "Bank account number should consist of only numbers.",
    "string.min": "Bank account number should be 10 digit.",
    "string.empty": "Please enter bank account number.",
    "any.required": "Please enter bank account number.",
  });
export const validateCacDocumentNumber = Joi.string()
  .pattern(/^[0-9]+$/)
  .min(7)
  .label("Cac document number")
  .messages({
    "string.pattern.base":
      "Cac document number should consist of only numbers.",
    "string.min": "Cac document number should be 7 digit.",
    "string.empty": "",
    "any.required": "",
  });

export const validateBnNumber = Joi.string()
  .pattern(/^[0-9]+$/)
  .min(7)
  .max(7)
  .label("BN number")
  .messages({
    "string.pattern.base": "BN number should consist of only numbers.",
    "string.min": "BN number should be 7 digit.",
    "string.empty": "BN number should not be empty.",
    "any.required": "Please enter BN number.",
  });

export const validateBusinessRcNumber = Joi.string()
  .pattern(/^[0-9]+$/)
  .min(7)
  .label("Business RC number")
  .messages({
    "string.pattern.base": "Business RC number should consist of only numbers.",
    "string.min": "Business RC number should be 7 digit.",
    "string.empty": "Business RC number should not be empty.",
    "any.required": "Please enter business RC number.",
  });

export const validateLandmark = Joi.string().label("Landmark").messages({
  "string.empty": "Please enter your landmark.",
  "any.required": "Please enter your landmark.",
});

export const validateOccupation = Joi.string().label("occupation").messages({
  "string.empty": "Please enter your occupation.",
  "any.required": "Please enter your occupation.",
});

export const validateNetIncome = Joi.string()
  .label("Net monthly income")
  .pattern(/^[0-9]+$/)
  .messages({
    "string.empty": "Please enter net monthly income.",
    "any.required": "Please enter net monthly income.",
    "string.pattern.base": "Net monthly income must be a number.",
  });

export const validateNIN = Joi.string()
  .pattern(/^[0-9]+$/)
  .label("NIN")
  .min(11)
  .messages({
    "string.pattern.base": "NIN should consist of only numbers.",
    "string.min": "NIN should be 11 digit.",
    "any.required": "Please enter NIN.",
    "string.empty": "Please enter NIN.",
  });

export default Joi.object().keys({
  phoneNumber: validatePhoneNumber.required(),
  email: validateEmail.required(),
  password: validatePassword.required(),
  confirmPassword: validateConfirmPassword.required(),
  address: validateAddress.required(),
  fullName: validateFullname.required(),
  firstName: validateFirstName.required(),
  lastName: validateLastName.required(),
  gender: validateGender.required(),
  validateOnlineLink: validateOnlineLink.required(),
  maritalStatus: validateMaritalStatus.required(),
  educationalQualification: validateQualification.required(),
  identityCardType: validateIDCard.required(),
  businessName: validateBusinessName.required(),
  businessRole: validateBusinessRole.required(),
  bankVerificationNumber: validateBVN.required(),
  guaranterRelationship: validateRelationship.required(),
  bankAccountNumber: validateBankAccountNumber.required(),
  cacDocumentNumber: validateCacDocumentNumber,
  businessRcNumber: validateBusinessRcNumber.required(),
  landmark: validateLandmark.required(),
  occupation: validateOccupation.required(),
  netMonthlyIncome: validateNetIncome.required(),
  nin: validateNIN.required(),
});

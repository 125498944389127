import React from "react";
import WebBackground from "../Background/WebBackground";
import styles from "../Background/WebBackground.module.scss";
import LoggedOutBottomBar from "../BottomBar/LoggedOutBottomBar";

const CantSyncSalesData = () => {
  return (
    <div className="relative">
      <div
        className="md:container md:py-[40px]"
        style={{ minHeight: "calc(100vh - 55px)" }}
      >
        <WebBackground />
        <div
          className={`${styles.formElement} ${styles.inputField} flex justify-center item-center`}
        >
          <div className="rounded-t-[30px] md:rounded-[30px] pt-[40px] pb-[80px] relative z-10 flex justify-center items-center">
            <div className="px-[10] max-w-[355px]">
              <div className="flex justify-center">
                <img
                  className="w-[93px] h-[84px]"
                  src="https://d1f8g8i172sxu4.cloudfront.net/images/cautionRed.png"
                  alt="Caution"
                />
              </div>
              <div className="text-center mt-[28px] font-semibold text-[20px] leading-[25px] text-[#103129]">
                Unable to fetch sales data
              </div>
              <div className="mt-[20px] font-light text-[14px] leading-[19px] text-[#192850] opacity-60 text-center">
                We are gathering your details to create your account. Please
                check again in 24 hours
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 md:container py-[14px] w-full">
        <LoggedOutBottomBar />
      </div>
      <div
        className={`absolute bottom-[135px] md:bottom-[110px] right-[-4px] md:right-[-10px] ${styles.duploLogoOpaque}`}
      >
        <img
          src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
          alt="DuploLogo"
        />
      </div>
    </div>
  );
};

export default CantSyncSalesData;

import styles from './Kyc.module.scss'
import { ReactComponent as ModalCloseIcon } from '../svg/modalCloseIcon.svg'
import { useNavigate } from 'react-router'

function KycSucess({ shown, close }: any) {
  const navigate = useNavigate()
  return shown ? (
    <div
      className={styles.modalBackdrop}
      onClick={() => {
        close()
      }}
    >
      <div
        className={`${styles.modalContent} ${styles.kycSuccessContent}`}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <button
          className="text-[#000] absolute top-[10px] right-[8px]"
          onClick={() => {
            navigate('/home')
          }}
        >
          <ModalCloseIcon />
        </button>
        <div className="mt-[65px] flex justify-center">
          <div className="w-[180px] h-[180px] flex justify-center">
            <img
              src="https://d1f8g8i172sxu4.cloudfront.net/images/successIcon.gif"
              alt="successfull"
            />
          </div>
        </div>
        <div className="mt-[32px] text-center font-semibold text-[20px] leading-[25px] text-[#192850]">
          Successful
        </div>
        <div className="mt-[14px] text-center font-light text-[16px] leading-[24px] text-[#192850]">
          You KYC details have been submitted successfully for verification. We
          will get back to you within 24 hours.
        </div>
        <div
          className="flex justify-center mt-[70px] mb-[15px]"
          onClick={() => navigate('/home')}
        >
          <button className="bg-[#2DB49E] px-[45px] py-[13px] text-[#fff] font-medium text-[14px] leading-[14px] rounded-[5px]">
            Okay
          </button>
        </div>
      </div>
    </div>
  ) : null
}

export default KycSucess

import React from "react";
import { Freshchat } from "reactjs-freshchat";
import "reactjs-freshchat/dist/index.css";
import { useUserContext } from "../../Context/UserContext";

const FreshChat = () => {
  const token = process.env.REACT_APP_FRESH_CHAT_TOKEN;
  const { kycDetails, userProfile } = useUserContext();
  const first_name = kycDetails?.first_name || userProfile?.first_name || "";
  const last_name = kycDetails?.last_name || userProfile?.last_name || "";
  const email = kycDetails?.email || userProfile?.email || "";
  const name = ` ${first_name} ${last_name} `;

  return (
    <div>
      <Freshchat
        token={token || ""}
        externalId={name}
        firstName={first_name}
        lastName={last_name}
        email={email}
        ic_styles={{
          backgroundColor: "#002d85",
          color: "#fff",
        }}
      />
    </div>
  );
};

export default FreshChat;

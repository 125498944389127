import { CurrencyFormat } from "../../Utility/CurrencyFormat";
import {
  DDMMYYWithoutTime,
  getMMDDYYYYFormat,
} from "../../Utility/DateFormats";

const LoanHistoryCell = ({ loanTransactionsData }: any) => {
  if (window.innerWidth <= 768) {
    return (
      <>
        {loanTransactionsData.map((item: any, index: number) => (
          <div
            className="flex items-center justify-between bg-[#FFFFFF] border-[1px] rounded-[5px] md:w-3/4 px-[10px] py-[25px] mt-[20px]"
            key={index}
          >
            <div className="font-medium text-[14px] text-[#003F34]">
              {getMMDDYYYYFormat(+item?.date)}
            </div>

            <div className="pr-[10px] font-medium text-[24px] leading-[30px] text-[#4B515B]">
              {CurrencyFormat(item?.amount)}
            </div>
          </div>
        ))}
      </>
    );
  } else {
    return (
      <div className="md:h-[265px] overflow-y-auto px-[20px]">
        {loanTransactionsData.map((item: any, index: number) => (
          <div className="grid grid-cols-3 my-[25px] gap-[100px]" key={index}>
            <div className="basis-3/12 text-[14px] leading-[14px]">
              {item?.date && DDMMYYWithoutTime(+item?.date)}
            </div>
            <div className="basis-3/12 text-[14px] leading-[14px]">
              {item?.type}
            </div>
            <div className="basis-3/12 text-[14px] leading-[14px] font-bold">
              {CurrencyFormat(item?.amount)}
            </div>
          </div>
        ))}
      </div>
    );
  }
};

export default LoanHistoryCell;

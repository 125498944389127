import { useNavigate } from 'react-router'
import { CurrencyFormat } from '../../Utility/CurrencyFormat'
import { getMMDDYYYYFormat } from '../../Utility/DateFormats'

const NotificationCell = ({ data }: any) => {
  const navigate = useNavigate()
  const confirm = () => {
    if (data?.kind === 'CONFIRM_DELIVERY') {
      navigate("/confirm-delivery", {
        state: {
          checkout_ref: data?.payload?.checkout_ref,
          notification_id : data?.id,
        },
      })
    }
  }
  return (
    <div className=" mt-[20px] md:mt-[35px]">
      <div
        className={`${
          data?.kind === 'CONFIRM_DELIVERY' ? 'cursor-pointer' : ''
        } flex items-end md:items-center justify-between bg-[#F4F9F5] border-[1px] border-[#2db49e4d] rounded-[5px] md:w-3/4 px-[10px] py-[16px] mt-[20px]`}
        onClick={() => {
          confirm()
        }}
      >
        <div className="">
          <div className="font-medium text-[12px] leading-[24px] text-[#5C696E]">
            {getMMDDYYYYFormat(data?.timestamp)}
          </div>
          <div className="mt-[10px] font-medium text-[16px] leading-[18px] text-[#162930]">
            {data?.text}
          </div>
        </div>
        {data?.payload?.amount ? (
          <div className="pr-[10px] font-medium text-[16px] md:text-[20px] leading-[20px] md:leading-[25px] text-[#4B515B]">
            {CurrencyFormat(data?.payload?.amount)}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default NotificationCell

import { useState } from "react";
import Select from "react-select";
import styles from "./History.module.scss";
import { ColorStyles } from "./PageNumberDropdown";
import Pagination from "../Pagination/Pagination";
import { api } from "../../Service/AuthService";
import { useEffect } from "react";
import TransactionHistoryCell from "./TransactionHistoryCell";
import NoTransactionHistory from "./NoTransactionHistory";
import Loader from "../Loader";

const options = [
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 40, label: 40 },
  { value: 50, label: 50 },
];

function TransactionHistoryTab() {
  const [perPageRecord, setPerPageRecord] = useState(5);
  const selectedOption = { label: 5, value: 5 };
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [transactionsData, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getTransactionHistory = async () => {
    await api
      .get(`transactions`, {
        params: {
          page_no: pageNumber,
          page_size: perPageRecord,
        },
      })
      .then((responseData) => {
        if (responseData?.data?.success) {
          const data = responseData?.data?.data;
          setTotalRecord(data?.meta?.total_records);
          const transactions = data?.transactions;
          setTransactions(transactions);
          setIsLoading(true);
        }
      })
      .finally(() => {
        setIsLoading(true);
      });
  };

  const handlePaginationChange = ({ selected }: any) => {
    const selectedPage = selected;
    setPageNumber(selectedPage + 1);
  };

  useEffect(() => {
    getTransactionHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, perPageRecord]);

  return (
    <div className="container">
      <div className="py-[20px] md:py-[40px] md:pl-[165px] md:pr-[40px] overflow-y-auto md:min-h-[690px] relative md:bg-[#fff]">
        <div
          className={`py-[30px] md:py-[40px] w-full ${styles.historyDetails} px-[12px] md:px-[25px]`}
        >
          <div className="font-semibold text-[20px] leading-[20px] text-[#103129]">
            Transaction History
          </div>
        </div>
        {isLoading ? (
          <>
            {transactionsData?.length > 0 ? (
              <div>
                <div className="md:grid md:grid-cols-8 border-[1px] border-[#2db49e80] rounded-[5px] font-bold text-[12px] leading-[15px] text-[#68717F] px-[20px] mt-[40px] hidden tracking-[0.04em]">
                  <div className="col-span-3 py-[18px]">DATE</div>
                  <div className="col-span-3 py-[18px]">DESCRIPTION</div>
                  <div className="col-span-1 py-[18px] min-w-[80px] text-right">
                    AMOUNT (₦)
                  </div>
                </div>
                <div className="md:h-[365px] overflow-y-auto px-[12px] md:px-[20px]">
                  <TransactionHistoryCell transactionsData={transactionsData} />
                </div>
                <div className="flex items-center gap-[80px] mt-[20px]  md:flex">
                  <div className="flex items-center">
                    <Select
                      options={options}
                      defaultValue={selectedOption}
                      isSearchable={false}
                      onChange={(e: any) => {
                        setPerPageRecord(e?.value);
                        setPageNumber(1);
                      }}
                      menuPlacement="top"
                      styles={ColorStyles}
                    />
                    <div className="ml-[7px] font-medium text-[10px] leading-[13px] text-[#5c696e99]">
                      per page
                    </div>
                  </div>
                  <Pagination
                    pageCount={Math.ceil(totalRecord / perPageRecord)}
                    handlePaginationChange={handlePaginationChange}
                  />
                </div>
              </div>
            ) : (
              <NoTransactionHistory historyKind={"TRANACTION_HISTORY"} />
            )}
          </>
        ) : (
          <div className="mt-4">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
}

export default TransactionHistoryTab;

import { useEffect, useState } from 'react'
import { useCheckoutContext } from '../../Context/CheckoutRef'
import { api } from '../../Service/AuthService'
import { CurrencyFormat } from '../../Utility/CurrencyFormat'
import { getDDMMYYYFormat } from '../../Utility/DateFormats'

const LoanOptions = () => {
  const { checkoutRef } = useCheckoutContext()

  const [loanOptionsData, setLoanOptionsData] = useState({
    timestamp: '',
    interestRate: '',
    borrowedAmount: 0,
    totalAmount: 0,
  })

  const getLoanOptions = () => {
    api
      .get('/loan-option', { params: { checkout_ref: checkoutRef } })
      .then((response) => {
        const responseData = response?.data?.data
        const loanOptions = responseData?.loan_options[0]
        setLoanOptionsData({
          timestamp: loanOptions?.due_date_timestamp,
          interestRate: loanOptions?.interest_rate,
          borrowedAmount: loanOptions?.loan_amount,
          totalAmount: loanOptions?.amount_due_on_due_date,
        })
      })
  }
  useEffect(() => {
    getLoanOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="mt-[36px] bg-[#2db49e14] py-[22px] rounded-[10px]">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-[15px]">
        <div className="justify-self-center text-center">
          <div className="font-light text-[10px] leading-[16px] text-[#5C696E]">
            Payment due date
          </div>
          <div className="mt-[8px] font-medium text-[18px] leading-[23px] text-[#003F34]">
            {loanOptionsData?.timestamp &&
              getDDMMYYYFormat(loanOptionsData?.timestamp)}
          </div>
        </div>
        <div className="justify-self-center text-center">
          <div className="font-light text-[10px] leading-[16px] text-[#5C696E]">
            Amount borrowed
          </div>
          <div className="mt-[8px] font-medium text-[18px] leading-[23px] text-[#003F34]">
            {CurrencyFormat(loanOptionsData?.borrowedAmount)}
          </div>
        </div>
        <div className="justify-self-center text-center">
          <div className="font-light text-[10px] leading-[16px] text-[#5C696E]">
            Interest rate
          </div>
          <div className="mt-[8px] font-medium text-[18px] leading-[23px] text-[#003F34]">
            {loanOptionsData?.interestRate && `${loanOptionsData?.interestRate}%`}
          </div>
        </div>
        <div className="justify-self-center text-center">
          <div className="font-bold text-[10px] leading-[16px] text-[#5C696E]">
            Total amount payable
          </div>
          <div className="mt-[8px] font-medium text-[18px] leading-[23px] text-[#003F34]">
          {CurrencyFormat(loanOptionsData?.totalAmount)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoanOptions

import { useEffect, useState } from 'react'
import { useUserContext } from '../../Context/UserContext'
import { useNavigate } from 'react-router-dom'
import { useCheckoutContext } from '../../Context/CheckoutRef'
import { api } from '../../Service/AuthService'
const PostLogin = () => {
  const navigate = useNavigate()
  //region - Application Boot check.
  const userContextUtil = useUserContext()
  const { checkoutRef } = useCheckoutContext()
  const [bnplAccessibleInDays, setBnplAccessibleInDays] = useState(0)
  const [hasNoMerchants, setHasNoMerchants] = useState(false)
  const [maxBnplReached, setMaxBnplReached] = useState(false)
  const kycDetails = userContextUtil.kycDetails
  const userProfile = userContextUtil.userProfile
  const isBootComplete = () => {
    return (
      userContextUtil.userProfile &&
      (userProfile.kyc_id ? userContextUtil.kycDetails : true) &&
      userContextUtil.lenderKycSchema
    )
  }

  const checkMaxBnplReached = () => {
    api.get('customer/max-bnpl-reached').then((response) => {
      const data = response?.data?.data
      setBnplAccessibleInDays(data?.bnpl_accessible_in_days)
      if (data?.bnpl_accessible_in_days !== 0) {
        setMaxBnplReached(true)
      }
    })
  }

  const checkMerchents = () => {
    api.get('/merchants').then((response) => {
      const data = response?.data?.data
      if (data?.merchants?.length === 0) {
        setHasNoMerchants(true)
      }
    })
  }

  const checkBnplStatus = async () => {
    if (hasNoMerchants) {
      navigate('/checkout/bnpl-rejected-reason', {
        state: {
          heading: 'Not registered',
          desc:
            'We are sorry we could not find your details registered under any vendor. Please use another method to complete your purchase.',
        },
      })
    } else if (maxBnplReached) {
      navigate('/checkout/bnpl-rejected-reason', {
        state: {
          heading: 'Limit exceeded',
          desc: `We are sorry, you have exceeded your BNPL request for one month. Try again in 
          ${bnplAccessibleInDays} ${
            bnplAccessibleInDays === 1 ? 'day' : 'days'
          }.`,
        },
      })
    } else {
      navigate('/checkout/bnpl/loan-details')
    }
  }

  useEffect(() => {
    checkMaxBnplReached()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    checkMerchents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isBootComplete()) {
      if (hasNoMerchants) {
        navigate('/not-registered')
      } else if (checkoutRef && !kycDetails?.sales_data_availability_status) {
        navigate('/checkout/bnpl-rejected-reason', {
          state: {
            heading: 'Insufficient sales history',
            desc:
              'We are sorry you do not have sufficient sales history to access this option.',
          },
        })
      } else if (kycDetails?.status === 'ACTIVATED' && checkoutRef) {
        checkBnplStatus()
      } else if (!userProfile.kyc_id) {
        navigate('/kyc-required')
      } else if (kycDetails?.status === 'PENDING') {
        if (!kycDetails?.sales_data_availability_status) {
          navigate('/insufficient-sales-history')
        } else {
          navigate('/kyc-required')
        }
      } else {
        navigate('/home')
      }
    } else {
      // Let's figure out what to load.
      if (!userContextUtil.kycDetails) {
        // Load Kyc Details.
        userContextUtil.fetchKycDetails()
      }
      if (!userContextUtil.lenderKycSchema) {
        // Load Lender Kyc Schema
        userContextUtil.fetchKycSchema()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContextUtil])

  //endregion
  return <></>
}

export default PostLogin

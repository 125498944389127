import { useEffect, useState } from "react";
import { useUserContext } from "../../Context/UserContext";
import { api } from "../../Service/AuthService";
import Messages from "../../Utility/Messages";
import Loader from "./Loader";

export const ImageUploader = ({
  fileTypeIntent,
  setS3Url,
  labelText,
  setShowError = false,
}: any) => {
  let ValidFileExtensions = [".png", ".jpeg", ".jpg", ".pdf"];
  if (fileTypeIntent === "kyc_personal_photo_document") {
    ValidFileExtensions = [".png", ".jpeg", ".jpg"];
  }
  const { kycDetails } = useUserContext();
  const [url, setUrl] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const getSignedUrl = async (fileName: string) => {
    const signedURLDataRes = await api.get("/signed-url", {
      params: {
        file_type_intent: fileTypeIntent,
        file_names: [fileName],
      },
    });
    return signedURLDataRes;
  };

  const uploadImageToS3 = async (postUrl: string, payload: any) => {
    const res = await api.post(postUrl, payload);
    return res;
  };

  const getFormData = (postFields: any, originalImage: any) => {
    let formData = new FormData();
    Object.entries(postFields).forEach((entry) => {
      const [key, value]: any = entry;
      formData.append(key, value);
    });
    formData.append("file", originalImage);
    return formData;
  };
  const getImageUploadParams = async (imageFile: any) => {
    const localFileName = imageFile?.name;
    const fileExtension = localFileName.split(".").pop().toLowerCase();
    const name = `${fileTypeIntent}_${Date.now()}.${fileExtension}`;

    const res = await getSignedUrl(name);
    if (res) {
      const responseData = res?.data?.data;
      const imgData = responseData?.files?.[name];
      const postUrl = imgData?.postUrl;
      const signedURLData = imgData?.postFields;
      const s3Url = imgData?.s3Url;
      const uploaded = uploadImageToS3(
        postUrl,
        getFormData(signedURLData, imageFile)
      );
      uploaded.then((res) => {
        setS3Url(fileTypeIntent, s3Url);
        const previewUrl = imgData?.previewUrl;
        setUrl(previewUrl);
        setShowError(false);
      });
      uploaded.catch(() => {
        setErrorMsg(Messages.FailedUploadingDocument);
        setTimeout(() => setErrorMsg(""), 5000);
      });
      uploaded.finally(() => {
        setShowLoader(false);
      });
    }
  };
  const validateFile = (fileObj: any) => {
    let isValidExtension = false;
    const MAX_FILE_SIZE = 5120; // 5MB
    const MIN_FILE_SIZE = 1; // kB
    const fileSize = fileObj?.size;
    const fileName = fileObj?.name;
    const fileSizeKiloBytes = fileSize / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      const error = Messages.LargeImageSize;
      setErrorMsg(error);
      setTimeout(() => setErrorMsg(""), 5000);
      return false;
    }
    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      const error = Messages.SmallImageSize;
      setErrorMsg(error);
      setTimeout(() => setErrorMsg(""), 5000);

      return false;
    }
    for (var j = 0; j < ValidFileExtensions?.length; j++) {
      var currentExtension = ValidFileExtensions[j];
      if (
        fileName
          .substr(
            fileName?.length - currentExtension?.length,
            currentExtension?.length
          )
          .toLowerCase() === currentExtension.toLowerCase()
      ) {
        isValidExtension = true;
        break;
      }
    }
    if (!isValidExtension) {
      setErrorMsg(Messages.InvalidExtension);
      setTimeout(() => setErrorMsg(""), 5000);
      return false;
    }
    setErrorMsg("");
    return true;
  };

  const handleInputChange = (e: any) => {
    e && e.stopPropagation();
    setShowLoader(true);
    setUrl("");
    setS3Url(fileTypeIntent, "");
    if (setShowError) {
      setShowError(false);
    }
    const files = e.target.files;
    if (files.length === 0) {
      setShowLoader(false);
      return;
    }
    const fileObj = files[0];
    const canUpload = validateFile(fileObj);
    const localFileName = fileObj?.name;
    const fileExtension = localFileName.split(".").pop().toLowerCase();
    if (
      ValidFileExtensions.indexOf(fileExtension) > -1 &&
      fileExtension !== "pdf"
    ) {
      const localUrl = URL.createObjectURL(fileObj);
      setUrl(localUrl);
    }

    if (canUpload) {
      getImageUploadParams(fileObj);
    } else {
      setShowLoader(false);
    }
  };
  useEffect(() => {
    if (fileTypeIntent === "kyc_personal_photo_document") {
      setUrl(kycDetails?.personal_photo_url);
    } else if (fileTypeIntent === "kyc_identity_document") {
      setUrl(kycDetails?.identity_document_url);
    } else if (fileTypeIntent === "kyc_utility_document") {
      setUrl(kycDetails?.utility_bill_document_url);
    } else if (fileTypeIntent === "lender_extra_details_document") {
      setUrl(kycDetails?.extra_details?.some_document_file_url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getFileType = () => {
    if (fileTypeIntent === "kyc_personal_photo_document") {
      return "Personal Photo";
    } else if (fileTypeIntent === "kyc_identity_document") {
      return "Upload ID Photo";
    } else if (fileTypeIntent === "kyc_utility_document") {
      return "Utility Bill(water bill, electricity bill, sanitation bill)";
    } else if (fileTypeIntent === "lender_extra_details_document") {
      return labelText;
    }
  };
  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <div>
          <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px] md:mt-0">
            {getFileType()}
          </div>
          <div className="font-bold text-[14px] leading-[16px] text-[#192850] mt-[15px]">
            {ValidFileExtensions.join(", ")}&nbsp;&nbsp;(5mb)
          </div>
          <label className="cursor-pointer border-[1px] border-[#DCDDE6] rounded-[5px] p-[20px] bg-[#fff] mt-[20px] flex items-center justify-center">
            <input
              type="file"
              className="hidden w-full file:rounded-full file:border-0"
              accept={ValidFileExtensions.join()}
              onChange={(e) => {
                handleInputChange(e);
              }}
            />
            {!url ? (
              <div className="border-dashed border-[1px] border-[#DCDDE6] w-full h-full py-[40px]">
                <div className={`flex justify-center`}>
                  <img
                    src="https://d1f8g8i172sxu4.cloudfront.net/images/camera.png"
                    alt="camera"
                  />
                </div>
                <div className="text-[#6E757C] font-normal text-center text-[12px] leading-[15px] mt-[20px]">
                  CLICK HERE TO UPLOAD PHOTO
                </div>
              </div>
            ) : (
              <>
                <div className="border-dashed border-[1px] border-[#DCDDE6] w-full h-full py-[60px]">
                  <div className={`flex justify-center`}>
                    <img
                      src="https://d1f8g8i172sxu4.cloudfront.net/images/previewImage.png"
                      alt="imagePreview"
                      width="83px"
                      height="83px"
                      className="border border-[#2DB49E] p-[10px] rounded-[5px]"
                    />
                  </div>
                  <a
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-[#2DB49E] font-bold text-center text-[12px] leading-[15px] mt-[20px] cursor-pointer flex justify-center"
                  >
                    File successfully uploaded
                  </a>
                </div>
              </>
            )}
          </label>
          <div className="text-[12px] text-[#FF0000] min-h-[20px]">
            {errorMsg}
          </div>
        </div>
      )}
    </>
  );
};
export default ImageUploader;

import styles from "./LoanHistory.module.scss";
import { ReactComponent as ModalCloseIcon } from '../svg/modalCloseIcon.svg'

function RepaymentSuccessModal({ shown, close}: any) {
  return shown ? (
    <div
      className={`${styles.modalBackdrop}`}
      onClick={() => {
        close();
      }}
    >
      <div
        className={`${styles.modalContent}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <div className="flex items-center justify-between bg-[#F9F9F9] w-full rounded-t-[10px] py-[30px] px-[20px]">
            <div className="font-semibold text-[18px] leading-[14px] text-[#162930]">
              Make Repayment
            </div>
            <button className="text-[#000]" onClick={close}>
              <ModalCloseIcon/>
            </button>
          </div>
          <div className="mt-[25px]">
            <div className="flex justify-center">
              <div className="w-[180px] h-[180px]">
                <img
                  src="https://d1f8g8i172sxu4.cloudfront.net/images/successIcon.gif"
                  alt="successfull"
                />
              </div>
            </div>
            <div className="text-center mt-[15px] font-medium text-[16px] leading-[20px] text-[#192850]">
              Your payment will be effected immediately <br/> we confirm it.
            </div>
          </div>
          <div className="bg-[#F9F9F9] rounded-b-[10px] pt-[18px] pb-[46px] flex justify-center mt-[20px]">
            <button
              className="text-[#FFFFFF] text-[14px] leading-[14px] font-normal bg-[#2DB49E] py-[13px] px-[44px] rounded-[5px]"
              onClick={close}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default RepaymentSuccessModal;

export const ColorStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor:'#2DB49E1A',
      boxShadow: "none",
      fontSize: '10px',
      fontWeight : '500',
      borderColor: '#2DB49E4D',
      "&:hover": {
        borderColor: '#2DB49E4D',
        cursor: "pointer",
      },
    }),
    valueContainer : (styles: any) => ({
      ...styles,
      width:'33px'
    }),
    indicatorSeparator: () => ({ display: "none" }),
    option: (styles: any, { data, isDisable, isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: "#FFFFFF",
        cursor: "pointer",
        color: "#162930",
        fontSize: '12px',
        fontWeight: isSelected ? '500' : '400',
        "&:hover": {
          backgroundColor: "#2db49e1a",
        },
      };
    },
  };
export const personalInfoValues = [
  "first_name",
  "last_name",
  "email",
  "date_of_birth",
  "phone_number",
  "gender",
  "occupation",
  "educational_qualification",
  "marital_status",
  "personal_photo_url",
  "nationality",
  "state",
  "lga",
  "home_address",
  "landmark",
  "nin",
  "identity_card_type",
  "identity_document_url",
];
export const businessInfoValues = [
  "business_name",
  "business_role",
  "establishment_period",
  "bank_verification_number",
  "business_category",
  "net_monthly_income",
  "business_state",
  "business_lga",
  "business_address",
  "bank_name",
  "bank_account_number",
  "bank_account_name",
  "utility_bill_document_url",
];
export const guarantorInfoValues = [
  "guarantor_first_name",
  "guarantor_last_name",
  "guarantor_phone_number",
  "guarantor_email",
  "guarantor_business_name",
  "guarantor_business_address",
  "guarantor_business_role",
  "guarantor_relationship",
];

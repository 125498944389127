import { useNavigate } from "react-router";

const KycPendingBanner = () => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate("/kyc");
      }}
      className={`flex items-center kyc-box-shadow bg-[#ffffff] px-[20px] md:px-[50px] py-[20px] md:py-[30px] mt-[25px] rounded-[20px] cursor-pointer relative z-[3]`}
    >
      <div className="pl-[10px]">
        <div className="text-[14px] font-bold text-[#333333]">
          Complete your KYC
        </div>
        <div className="font-normal text-[14px] leading-[18px] text-[#333333] mt-[5px]">
          Please complete your KYC requirements to start shopping!
        </div>
      </div>
      <div className="ml-auto">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_15720_113777)">
            <path d="M5.5 2.5L12.5 9.5L5.5 16.5L5.5 2.5Z" fill="#05CD99" />
          </g>
          <defs>
            <clipPath id="clip0_15720_113777">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default KycPendingBanner;

import React from "react";
// import { useNavigate } from "react-router-dom";
// import { ReactComponent as NotificationBell } from "../svg/notificationBell.svg";
// import NotificationCount from "../NotificationCount";
import { useUserContext } from "../../Context/UserContext";
import { getInitials } from "../../Utility/textUtil";

const HomeProfile = () => {
  const { userProfile, kycDetails } = useUserContext();
  // const navigate = useNavigate();
  return (
    <div className="border border-[#879894] rounded-[20px] mb-5 px-6 py-4 flex justify-between items-center">
      <div className="flex items-center">
        <div>
          {kycDetails?.personal_photo_url && (
            <div className="w-[60px] h-[60px] overflow-hidden rounded-full mr-4">
              <img
                src={kycDetails?.personal_photo_url}
                alt="personalPhoto"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          )}

          {!kycDetails?.personal_photo_url && (
            <div className="h-[60px] w-[60px] bg-[#2DB49E] rounded-full flex justify-center items-center text-[#ffffff] font-[700] text-[22px] mr-4">
              {getInitials(
                kycDetails?.first_name || userProfile?.first_name,
                kycDetails?.last_name || userProfile?.last_name
              )}
            </div>
          )}
        </div>

        <div>
          <p className="text-[#4d8077] text-[14px] leading-[14px]">
            Welcome to Connect,
          </p>

          <p className="text-[#1A4230] font-[700] text-[20px] leading-[14px] mt-[0.7rem]">
            {kycDetails?.first_name || userProfile?.first_name}&nbsp;
            {kycDetails?.last_name || userProfile?.last_name}
          </p>
        </div>
      </div>

      {/* <div
        className="ml-auto relative cursor-pointer"
        onClick={() => {
          navigate("/notifications");
        }}
      >
        <NotificationBell />
        <div className="absolute top-0 right-0">
          <NotificationCount />
        </div>
      </div> */}
    </div>
  );
};

export default HomeProfile;

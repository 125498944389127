function LoggedOutBottomBar() {

  return (
    <div className="flex items-center justify-center md:justify-start text-[12px] text-[#192850] font-light">
      <div className="">&#169;Duplo</div>
      <div className="pl-[20px] md:pl-[28px]">Privacy & Terms</div>
      
    </div>
  );
}

export default LoggedOutBottomBar;

import { useState } from "react";
import styles from "./Dropdown.module.scss";
import { ReactComponent as ModalCloseIcon } from "../svg/modalCloseIcon.svg";
import Select, { components } from "react-select";
import { ReactComponent as DropdownSearchIcon } from "../svg/dropdownSearchIcon.svg";
import { SearchDropdownStyles } from "../../Utility/SearchDropdownStyles";

const SearchableDropdown = ({
  setDropDownValues,
  dropdownOptions,
  selectedValue,
  type,
  heading,
  disableField,
}: any) => {
  const [isStateModalOpen, setIsStateModalOpen] = useState(false);

  const formattedLabel = (label: string) => {
    const state = label.toLowerCase();
    return state.charAt(0).toUpperCase() + state.slice(1);
  };

  const Option = (props: any) => {
    const hasBorder =
      props.options[props?.options?.length - 1]?.label === props?.data?.label
        ? false
        : true;
    return (
      <components.Option {...props}>
        <div className="px-[10px] py-[27px]">
          <div className="text-[14px] leading-[16px] font-light">
            {formattedLabel(props?.data?.label)}
          </div>
          <div>{props?.data?.subLabel}</div>
        </div>
        <div
          className={`${hasBorder ? "border-b border-[#94A2AB66]" : ""}`}
        ></div>
      </components.Option>
    );
  };
  const placeholderComponent = () => {
    return (
      <div className="flex justify-between items-center">
        <div className="font-normal text-[14px]">Search</div>
        <DropdownSearchIcon />
      </div>
    );
  };
  return (
    <>
      <div
        className={`w-full mt-[10px] h-[40px] border-[1px] relative text-start flex items-center px-3 rounded-[4px]  
        ${disableField ? "bg-[#F6F7F9]" : "bg-[#fff] cursor-pointer"}
        ${isStateModalOpen ? "border-[#2DB49E]" : "border-[#DCDDE6]"}`}
        onClick={() => {
          if (disableField) {
            return;
          }
          setIsStateModalOpen(!isStateModalOpen);
        }}
      >
        <div>{formattedLabel(selectedValue)}</div>
        <div
          className={`${isStateModalOpen ? "rotate-180" : "rotate-0"} ml-auto`}
        >
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.75 1.5L5 5.25L1.25 1.5"
              stroke={isStateModalOpen ? "#2DB49E" : "#162930"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="md:relative z-10">
        {isStateModalOpen && (
          <div>
            <div
              className={`fixed w-screen h-screen top-0 bottom-0 left-0 right-0`}
              onClick={() => {
                setIsStateModalOpen(false);
              }}
            ></div>
            <div
              className={`w-screen md:w-full mt-[10px] ${styles.dropdownModal}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {window.innerWidth < 767 ? (
                <div className="py-[25px] bg-[#F9F9F9] px-[20px] flex items-center justify-between">
                  <div className="font-semibold text-[18px] leading-[18px] text-[#162930]">
                    {heading}
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setIsStateModalOpen(false);
                    }}
                  >
                    <ModalCloseIcon />
                  </div>
                </div>
              ) : null}
              <Select
                className="cursor-pointer"
                options={dropdownOptions}
                components={{ Option, DropdownIndicator: null }}
                styles={SearchDropdownStyles}
                menuIsOpen
                onChange={(e) => {
                  setDropDownValues(e, type);
                  setIsStateModalOpen(false);
                }}
                placeholder={placeholderComponent()}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchableDropdown;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../Context/UserContext";
import { KycInfoSubmit, KycInfoSubmitPatch } from "../../Service/KycInfoSubmit";
import SideBar from "../SideBar/SideBar";
import styles from "./Kyc.module.scss";
import { ReactComponent as GreenLeftArrow } from "../svg/greenLeftArrow.svg";
import "./Calendar.css";
import {
  validateBusinessName,
  validateBusinessRole,
  validateBVN,
  validateAddress,
  validateNetIncome,
} from "../../Utility/ValidationsWithJoi";
import {
  StateOptions,
  BusinessCategoryOptions,
  YearsOfExistenceOptions,
} from "../../Utility/DropdownOptions";
import { LgaOptions } from "../../Utility/StateWithLgas";
import AnimatedDropdown from "./AnimatedDropdown";
import SearchableDropdown from "./SearchableDropdown";

var dateObj = new Date();
dateObj.setDate(dateObj.getDate() - 1);

function BusinessInfoPgOne() {
  const { userProfile, kycDetails, setKycDetails } = useUserContext();
  const navigate = useNavigate();

  const onPopulateOthersField = () => {
    const filteredData = BusinessCategoryOptions.filter(
      (option) => option.value === kycDetails?.business_category
    );

    return filteredData.length > 0 ? "" : kycDetails?.business_category;
  };

  const checkingIfBusinessCategory = () => {
    const filteredData = BusinessCategoryOptions.find(
      (option) => option.value === kycDetails?.business_category
    );

    if (kycDetails?.business_category) {
      return filteredData?.value || "Others";
    }

    return filteredData?.value || "";
  };

  const [inputValues, setInputValues] = useState({
    businessName: kycDetails?.business_name || "",
    businessRole: kycDetails?.business_role || "",
    establishment_period: kycDetails?.establishment_period || "",
    bankVerificationNumber: kycDetails?.bank_verification_number || "",
    businessCategory: checkingIfBusinessCategory(),
    address: kycDetails?.business_address || "",
    businessState: kycDetails?.business_state || "",
    businessLga: kycDetails?.business_lga || "",
    monthlyIncome: kycDetails?.net_monthly_income || "",
    otherCategories: onPopulateOthersField(),
  });
  const rejectedReason = kycDetails?.rejected_reason;
  const [errors, setErrors] = useState({
    businessName: rejectedReason?.business_name || "",
    businessRole: rejectedReason?.business_role || "",
    establishment_period: rejectedReason?.establishment_period || "",
    bankVerificationNumber: rejectedReason?.bank_verification_number || "",
    businessCategory: rejectedReason?.business_category || "",
    address: rejectedReason?.business_address || "",
    businessState: rejectedReason?.business_state || "",
    businessLga: rejectedReason?.business_lga || "",
    monthlyIncome: rejectedReason?.net_monthly_income || "",
    generalErr: "",
    otherCategories: "",
  });
  const validateOptions = {
    abortEarly: false,
    converse: true,
    stripUnknown: true,
  };
  const validationField = (schema: any, value: any, field: any) => {
    const err = JSON.parse(JSON.stringify(errors));
    const res = schema.validate(value, validateOptions);
    let errorsList: any = {};
    if (res.error) {
      res.error.details.forEach((error: any) => {
        errorsList[field] = error.message;
      });
      setErrors({
        ...errors,
        ...errorsList,
      });
    } else {
      delete err[field];
      setErrors(err);
    }
  };
  const validateInput = (e: { target: { name: string; value: any } }) => {
    switch (e.target.name) {
      case "businessName":
        validationField(validateBusinessName, e.target.value, "businessName");
        break;
      case "businessRole":
        validationField(validateBusinessRole, e.target.value, "businessRole");
        break;
      case "bankVerificationNumber":
        validationField(validateBVN, e.target.value, "bankVerificationNumber");
        break;
      case "address":
        validationField(validateAddress, e.target.value, "address");
        break;
      case "monthlyIncome":
        validationField(validateNetIncome, e.target.value, "monthlyIncome");
        break;
    }
  };

  const handleInput = (e: { target: { name: string; value: any } }) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const formSubmit = async (e: any) => {
    e.preventDefault();
    const businessFullAddress = [
      inputValues?.address,
      inputValues?.businessState,
      inputValues?.businessLga,
    ];
    const payload = {
      business_name: inputValues?.businessName,
      bank_verification_number: inputValues?.bankVerificationNumber,
      business_role: inputValues?.businessRole,
      business_category:
        inputValues?.businessCategory === "Others"
          ? inputValues?.otherCategories
          : inputValues?.businessCategory,
      establishment_period: inputValues?.establishment_period,
      business_address: inputValues?.address,
      business_state: inputValues?.businessState,
      business_lga: inputValues?.businessLga,
      net_monthly_income: inputValues?.monthlyIncome,
      business_full_address: businessFullAddress.join(" "),
    };
    const finalPayload = {};

    if (
      kycDetails.business_status === "REGISTERED" &&
      kycDetails.business_type === "Limited Liability Company"
    ) {
      delete kycDetails.bn_number;
      delete kycDetails.certificate_of_registration_document_url;
    }

    if (
      kycDetails.business_status === "REGISTERED" &&
      kycDetails.business_type === "Sole Proprietorship"
    ) {
      delete kycDetails.cac_document_url;
      delete kycDetails.business_rc_number;
    }

    if (
      kycDetails.business_status === "UNREGISTERED" ||
      !kycDetails.business_status
    ) {
      delete kycDetails.business_type;
      delete kycDetails.bn_number;
      delete kycDetails.certificate_of_registration_document_url;
      delete kycDetails.business_type;
      delete kycDetails.business_rc_number;
      delete kycDetails.cac_document_url;
    }

    Object.assign(finalPayload, kycDetails, payload);

    let responseData: any = null;
    if (userProfile?.kyc_id) {
      responseData = await KycInfoSubmitPatch(finalPayload);
    } else {
      responseData = await KycInfoSubmit(payload);
      if (responseData.status === 200) {
        const resObj = responseData?.data?.data;
        const kycObj = resObj?.kyc;
        userProfile.kyc_id = kycObj?.id;
      }
    }
    if (responseData.status === 200) {
      const resObj = responseData?.data?.data;
      const kycObj = resObj?.kyc;
      setKycDetails(kycObj);
      navigate("/kyc/business-information-2");
    } else {
      const errData = responseData?.data?.errors;
      let generalErrorMsg = "";
      if (Object.keys(errData)?.length === 0) {
        generalErrorMsg = responseData?.data?.message;
      }
      setErrors({
        businessName: errData?.business_name,
        businessRole: errData?.business_role,
        establishment_period: errData?.establishment_period,
        bankVerificationNumber: errData?.bank_verification_number,
        businessCategory: errData?.business_category,
        address: errData?.business_address,
        businessState: errData?.business_state,
        businessLga: errData?.business_lga,
        monthlyIncome: errData?.net_monthly_income,
        generalErr: generalErrorMsg,
        otherCategories: errData?.business_category,
      });
    }
  };
  const setDropDownValues = (e: any, name: string) => {
    if (name === "businessState") {
      inputValues.businessLga = "";
    }
    setInputValues({
      ...inputValues,
      [name]: e.value,
    });
    errors[name] = "";
  };

  return (
    <div className="md:flex md:items-center md:justify-center relative">
      <div className="hidden md:block z-50">
        <SideBar />
      </div>
      <div
        className={`w-full md:w-4/6 md:my-[50px] md:rounded-[20px] md:ml-[-100px] md:border-[#2db49e80] md:border-[1px] overflow-y-auto ${styles.pageHeight}`}
      >
        <div className="container py-[20px] md:py-[40px] md:pl-[165px] md:pr-[40px] overflow-y-auto md:min-h-[790px] relative z-10 bg-[#2db49e1a] md:bg-transparent">
          <div className="flex justify-between items-center mb-[40px]">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => {
                navigate("/kyc");
              }}
            >
              <div>
                <GreenLeftArrow />
              </div>
              <div className="ml-[20px] font-semibold text-[20px] leading-[25px] text-[#192850]">
                Business Information
              </div>
            </div>
            <div className="font-light text-[14px] leading-[16px] text-[#192850]">
              1 of 2
            </div>
          </div>
          <form onSubmit={(e) => formSubmit(e)}>
            <div className="md:grid md:grid-cols-2 md:gap-[20px]">
              <div className="relative">
                <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                  Business Name
                </div>
                <input
                  className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                  id="businessName"
                  type="text"
                  value={inputValues?.businessName}
                  onChange={(e) => handleInput(e)}
                  name="businessName"
                  autoComplete="off"
                  onBlur={(e) => validateInput(e)}
                />
                <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                  {errors?.businessName}
                </div>
              </div>

              <div className="relative">
                <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                  Business Category
                </div>
                <AnimatedDropdown
                  setDropDownValues={setDropDownValues}
                  dropdownOption={BusinessCategoryOptions}
                  type={"businessCategory"}
                  selectedValue={inputValues?.businessCategory}
                  heading={"Select yout Business Category"}
                />
                <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                  {errors?.businessCategory}
                </div>
              </div>

              {inputValues?.businessCategory === "Others" && (
                <div className="relative">
                  <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                    Please state your business category
                  </div>
                  <input
                    className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                    id="otherCategories"
                    type="text"
                    value={inputValues?.otherCategories}
                    onChange={(e) => handleInput(e)}
                    name="otherCategories"
                    autoComplete="off"
                    onBlur={(e) => validateInput(e)}
                  />
                  <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                    {errors?.otherCategories}
                  </div>
                </div>
              )}

              <div className="relative">
                <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                  Business Role
                </div>
                <input
                  className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                  id="businessRole"
                  type="text"
                  value={inputValues?.businessRole}
                  onChange={(e) => handleInput(e)}
                  name="businessRole"
                  autoComplete="off"
                  onBlur={(e) => validateInput(e)}
                />
                <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                  {errors?.businessRole}
                </div>
              </div>

              <div className="relative">
                <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                  Years of Existence
                </div>
                <SearchableDropdown
                  setDropDownValues={setDropDownValues}
                  type={"establishment_period"}
                  selectedValue={inputValues?.establishment_period}
                  dropdownOptions={YearsOfExistenceOptions}
                  heading={"Select your year of existence"}
                  disableField={false}
                />

                <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                  {errors?.establishment_period}
                </div>
              </div>
              <div className="relative">
                <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                  Business State
                </div>
                <SearchableDropdown
                  setDropDownValues={setDropDownValues}
                  type={"businessState"}
                  selectedValue={inputValues?.businessState}
                  dropdownOptions={StateOptions}
                  heading={"Select your Business State"}
                  disableField={false}
                />
                <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                  {errors?.businessState}
                </div>
              </div>

              <div className="relative">
                <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                  BVN
                </div>
                <input
                  className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                  id="bankVerificationNumber"
                  type="text"
                  name="bankVerificationNumber"
                  value={inputValues?.bankVerificationNumber}
                  onChange={(e) => handleInput(e)}
                  onBlur={(e) => validateInput(e)}
                  autoComplete="off"
                  maxLength={11}
                />
                <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                  {errors?.bankVerificationNumber}
                </div>
              </div>
              <div className="relative">
                <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                  Business LGA
                </div>
                <SearchableDropdown
                  setDropDownValues={setDropDownValues}
                  type={"businessLga"}
                  selectedValue={inputValues?.businessLga}
                  dropdownOptions={LgaOptions(inputValues?.businessState)}
                  heading={"Select your Business LGA"}
                  disableField={inputValues?.businessState ? false : true}
                />
                <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                  {errors?.businessLga}
                </div>
              </div>
              <div className="relative">
                <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                  Business Monthly Income
                </div>
                <input
                  className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                  id="monthlyIncome"
                  type="text"
                  value={inputValues?.monthlyIncome}
                  onChange={(e) => handleInput(e)}
                  name="monthlyIncome"
                  autoComplete="off"
                  onBlur={(e) => validateInput(e)}
                />
                <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                  {errors?.monthlyIncome}
                </div>
              </div>
              <div className="relative">
                <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                  Business Address
                </div>
                <input
                  className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                  id="address"
                  type="text"
                  value={inputValues?.address}
                  name="address"
                  onChange={(e) => handleInput(e)}
                  onBlur={(e) => validateInput(e)}
                  autoComplete="off"
                />
                <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                  {errors?.address}
                </div>
              </div>
            </div>

            <div className="flex justify-center mt-[55px]">
              <button
                className="bg-[#2DB49E] rounded-[5px] font-semibold text-[14px] leading-[16px] text-[#fff] w-full md:w-1/2 py-[12px]"
                type="submit"
              >
                Next
              </button>
            </div>
            <div className="text-[16px] font-bold text-[#FF0000] flex justify-center mt-[10px] min-h-[24px]">
              {errors.generalErr}
            </div>
          </form>
        </div>
      </div>
      <div
        className={`absolute bottom-[160px] md:bottom-[105px] right-[10px] md:right-[-10px] ${styles.duploLogoOpaque} z-[-1]`}
      >
        <img
          src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
          alt="DuploLogo"
        />
      </div>
    </div>
  );
}

export default BusinessInfoPgOne;

import axios from "axios";

import { setupInterceptors } from "./Interceptors";
import config from "../../config";

export const api = setupInterceptors(
  axios.create({
    baseURL: config.API_ENDPOINT,
  })
);

export const gatewayApi = setupInterceptors(
  axios.create({
    baseURL: process.env.REACT_APP_GATEWAY_BASE_URL,
  })
);

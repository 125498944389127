import { useEffect, useRef, useState } from "react";
import { api } from "../../Service/AuthService";
import { CurrencyFormat } from "../../Utility/CurrencyFormat";
import { getDaysLeft, getDDMMFormat } from "../../Utility/DateFormats";
import ConfirmRepaymentModal from "../Loans/ConfirmRepaymentModal";
import RepaymentSuccessModal from "../Loans/RepaymentSuccessModal";
import styles from "./HomePage.module.scss";
import { useNavigate } from "react-router-dom";
import StampdutyModal from "../Loans/StampdutyModal";
const NextRepayment = ({ loans }: any) => {
  const navigate = useNavigate();
  const [virtualAcc, setVirtualAcc] = useState();
  const [repaymentModalShown, toggleRepaymentModal] = useState<Boolean>(false);
  const [repaymentSuccessModalShown, toggleRepaymentSuccessModal] =
    useState(false);
  const dataFetchedRef = useRef(false);
  const [stampDutyModalShown, toggleStampDutyModal] = useState(false);

  const getRepaymentAccount = async () => {
    await api.get("repayment-account").then((response) => {
      const responseData = response?.data?.data;
      const virtualAccount = responseData?.virtual_account;
      setVirtualAcc(virtualAccount);
    });
  };

  const handleStampDutySubmit = () => {
    toggleStampDutyModal(false);
    toggleRepaymentModal(true);
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getRepaymentAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className={`bg-[#eef8f4] border-[1.5px] border-[#8ed6c9] mt-[25px] rounded-[5px]  ${styles.activeLoans}`}
    >
      <div className="flex justify-center items-center">
        <div className="text-[16px] text-[#001812] leading-[14px] font-semibold opacity-60 pl-8 pt-3">
          Active Loans
        </div>
        <button
          className={`bg-[#2DB49E] text-[#FFFFFF] h-[47px] flex justify-center items-center  w-[128px] ${styles.viewMoreBtn}`}
          onClick={() => {
            navigate("/loans");
          }}
        >
          <span className="mr-2">View more</span>
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.00009 0L0.590088 1.41L5.17009 6L0.590088 10.59L2.00009 12L8.00009 6L2.00009 0Z"
              fill="#ffffff"
            />
          </svg>
        </button>
      </div>
      <div className="lg:flex md:items-center md:justify-between  md:pl-[35px] md:pr-[30px] px-[20px] py-[20px] md:pt-[30px] md:pb-[50px]">
        <div className="md:flex md:items-center">
          <div>
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.2499 28.375H30.6666V30.9583H33.2499M33.2499 23.2083H30.6666V25.7917H33.2499M35.8333 33.5417H25.4999V30.9583H28.0833V28.375H25.4999V25.7917H28.0833V23.2083H25.4999V20.625H35.8333M22.9166 18.0417H20.3333V15.4583H22.9166M22.9166 23.2083H20.3333V20.625H22.9166M22.9166 28.375H20.3333V25.7917H22.9166M22.9166 33.5417H20.3333V30.9583H22.9166M17.7499 18.0417H15.1666V15.4583H17.7499M17.7499 23.2083H15.1666V20.625H17.7499M17.7499 28.375H15.1666V25.7917H17.7499M17.7499 33.5417H15.1666V30.9583H17.7499M25.4999 18.0417V12.875H12.5833V36.125H38.4166V18.0417H25.4999Z"
                fill="#146E5F"
              />
              <rect
                opacity="0.2"
                width="50"
                height="50"
                rx="5"
                fill="#2DB49E"
              />
            </svg>
          </div>
          <div className="flex items-end md:items-center justify-between">
            <div className="md:ml-[40px]">
              <div className="mt-[30px] md:mt-0 md:flex">
                <p className="text-start font-semibold text-[16px] leading-[20px] text-[#003F34]">
                  Next repayment
                </p>

                <div className="font-semibold text-[20px] leading-[25px] text-[#003F34] md:ml-[40px]">
                  {CurrencyFormat(loans?.amount_due_on_due_date)}
                </div>
              </div>
              <div className="mt-[10px]">
                <div className="flex items-center text-[10px] leading-[16px] text-[#5C696E] font-light">
                  <span>
                    {loans?.due_date_timestamp &&
                      `Due ${getDDMMFormat(loans?.due_date_timestamp)}`}
                  </span>
                  <span className="mx-[7px] h-[4px] w-[4px] rounded-full bg-[#D9D9D9]"></span>
                  <span>
                    {loans?.due_date_timestamp &&
                      getDaysLeft(loans?.due_date_timestamp) +
                        " days left"}{" "}
                  </span>
                </div>
              </div>
              <div className="flex items-center text-[10px] leading-[16px] text-[#5C696E] font-light mt-[1.5px]">
                Repayment can be made before the due date
              </div>
            </div>
          </div>
        </div>
        <button
          className={`${
            loans && loans?.total_borrowed_amount === 0 ? "opacity-50" : ""
          } w-full md:w-auto px-[80px] justify-center  text-[14px] leading-[18px] bg-[#eef8f4] py-[11px] text-[#2DB49E] border-[1.5px] border-[#2DB49E] rounded-[5px] mt-[30px] lg:mt-0 font-[600]`}
          onClick={() => {
            toggleStampDutyModal(true);
          }}
          disabled={loans && loans?.total_borrowed_amount === 0}
        >
          Repay now
        </button>
        <StampdutyModal
          shown={stampDutyModalShown}
          close={() => {
            toggleStampDutyModal(false);
          }}
          toggleStampDutyModal={handleStampDutySubmit}
        />
        <ConfirmRepaymentModal
          shown={repaymentModalShown}
          amount={loans?.amount_due_on_due_date || 0}
          virtualAcc={virtualAcc}
          toggleRepaymentSuccessModal={toggleRepaymentSuccessModal}
          close={() => {
            toggleRepaymentModal(false);
          }}
        />

        <RepaymentSuccessModal
          shown={repaymentSuccessModalShown}
          close={() => {
            toggleRepaymentSuccessModal(false);
          }}
        />
      </div>
    </div>
  );
};

export default NextRepayment;

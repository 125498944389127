import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../Service/AuthService";
import { CurrencyFormat } from "../../Utility/CurrencyFormat";
import { getMMDDYYYYFormat } from "../../Utility/DateFormats";
import { ReactComponent as GreenLeftArrow } from "../svg/greenLeftArrow.svg";
import ConfirmDeliveryModal from "./ConfirmDeliveryModal";
import styles from './ConfirmDelivery.module.scss';

function ConfirmDeliveryTab() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [orderStatus, setOrderStatus] = useState("");
  const [updatedAt, setUpdatedAt] = useState();
  const [amount, setAmount] = useState(0);
  const [errors, setErrors] = useState("");
  const [modalShown, toggleModal] = useState<Boolean>(false);
  const dataFetchedRef = useRef(false);

  const deliveryStatus = [
    { value: "CUSTOMER_DELIVERY_CONFIRMED", label: "Delivered as ordered" },
    { value: "CUSTOMER_DELIVERY_DISPUTED", label: "Not delivered as ordered" },
    { value: "CUSTOMER_DELIVERY_DISPUTED", label: "Not delivered " },
  ];

  const markDeliveryDone = () => {
    api
    .post("/notifications/mark-done",{
      notification_id : state?.notification_id
    })
    .then(() => {
      toggleModal(!modalShown);
    })
    .catch((error) => {
      const errorsData = error?.response?.data?.errors;
      setErrors(errorsData?.status);
    });
  }

  const confirmDelivery = () => {
    api
      .post("/customer/delivery-status", {
        checkout_ref: state?.checkout_ref,
        status: orderStatus,
      })
      .then(() => {
        markDeliveryDone();
        toggleModal(!modalShown);
      })
      .catch((error) => {
        const errorsData = error?.response?.data?.errors;
        setErrors(errorsData?.status);
      });
  };

  const getNotificationsDetails = async () => {
    await api
      .get(`/checkout/${state?.checkout_ref}`)
      .then((response) => {
        const responseData = response?.data?.data;
        const checkout = responseData?.checkout;
        setUpdatedAt(checkout?.updated_at);
        setAmount(checkout?.amount);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getNotificationsDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className={`container py-[40px] md:pl-[165px] md:pr-[40px] overflow-y-auto md:min-h-[690px] relative`}
    >
      <div className="md:pt-[44px] md:pl-[29px] flex">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            navigate("/notifications");
          }}
        >
          <span>
            <GreenLeftArrow />
          </span>
          <span className="pl-[30px] font-semibold text-[20px] leading-[25px] text-[#192850]">
            Confirm Delivery
          </span>
        </div>
      </div>
      <div className="mt-[40px] font-medium text-[14px] leading-[24px] text-[#5C696E]">
        Kindly confirm your delivery of your order. Once confirmed, you cannot
        undo this action.
      </div>
      <div className="mt-[30px]">
        <div className="flex items-end md:items-center justify-between bg-[#F4F9F5] border-[1px] border-[#2db49e4d] rounded-[5px] md:w-3/4 px-[10px] py-[25px] cursor-pointer mt-[20px]">
          <div className="font-medium text-[12px] md:text-[14px] leading-[24px] text-[#5C696E]">
            Items ordered on {getMMDDYYYYFormat(updatedAt)}
          </div>

          <div className="pr-[10px] font-medium text-[24px] leading-[30px] text-[#4B515B]">
            {CurrencyFormat(amount)}
          </div>
        </div>
        <div className="mt-[30px] font-medium text-[16px] leading-[18px] text-[#162930]">
          I confirm my items have been
        </div>
        <div className="mt-[23px]">
          {deliveryStatus.map((item, index) => (
            <div className="flex" key={index}>
              <div className="flex items-center cursor-pointer">
                <label
                  className={`${styles.radioBox} flex items-center cursor-pointer`}
                >
                  <input
                    type="radio"
                    id={item.value}
                    name="deliveryStatus"
                    onChange={() => {
                      setOrderStatus(item.value);
                    }}
                  />
                  <span></span>
                </label>
                <label
                  htmlFor={item.value}
                  className={`py-[8px] px-[16px] font-normal text-[14px] leading-[24px] text-[#5C696E]`}
                >
                  {item.label}
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button
        className="font-medium text-[14px] leading-[14px] text-[#ffffff] bg-[#2DB49E] rounded-[5px] md:px-[160px] py-[14px] mt-[45px] w-full md:w-min"
        onClick={() => {
          confirmDelivery();
        }}
      >
        Confirm
      </button>
      <ConfirmDeliveryModal
        shown={modalShown}
        close={() => {
          toggleModal(false);
        }}
      />
      <div className="text-[12px] text-[#FF0000] min-h-[20px]">{errors}</div>
    </div>
  );
}

export default ConfirmDeliveryTab;

import React from "react";
import styles from "./LafModal.module.scss";
import { ReactComponent as GreyCloseIcon } from "../svg/greyCloseIcon.svg";

const LafModal = ({
  shown,
  close,
  isloading,
  onAcceptingTerms,
  onRejectTerms,
}: any) => {
  return shown ? (
    <div
      className={styles.modalBackdrop}
      onClick={() => {
        close();
      }}
    >
      <div
        className={styles.modalContent}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex items-center justify-between">
          <div className="font-semibold text-[20px] leading-[25px] text-[#192850]">
            Loan Terms
          </div>
          <button onClick={close} className="text-[#000]">
            <GreyCloseIcon />
          </button>
        </div>
        <div className="mt-[20px] bg-[#d9d9d933] w-full  overflow-y-auto rounded-[10px]">
          <div className="font-normal text-[15px] leading-[16px] text-[#192850] p-[20px]">
            By clicking “Accept”, you agree to accepting this loan based on our
            terms our Terms and conditions.
            <br /> <br />
            <b>
              Please note that the loan amount disbursed could be either the
              requested amount or a lesser amount. The exact amount disbursed is
              dependent on the amount upon order fulfillment by the merchant.
            </b>
            <br /> <br />
          </div>
        </div>
        <div className="flex justify-center mt-[25px]">
          <button
            className="bg-[#2DB49E] rounded-[5px] font-semibold text-[14px] leading-[16px] text-[#fff] py-[13px] px-[44px] flex items-center"
            onClick={onAcceptingTerms}
            disabled={isloading}
          >
            {isloading ? "Loading..." : "Accept"}
          </button>

          <button
            className="bg-[#dcdde6] rounded-[5px] ml-5 font-semibold text-[14px] leading-[16px] text-[#fff] py-[13px] px-[44px]"
            onClick={onRejectTerms}
            disabled={isloading}
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default LafModal;

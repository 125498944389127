import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import SignIn from "./component/SignIn";
import SignUp from "./component/SignUp";
import PostLogin from "./component/PostLogin/PostLogin";
import ForgotPassword from "./component/ForgotPassword";
import ResetPassword from "./component/ResetPassword";
import VerifyOtp from "./component/VerifyOtp";
import Home from "./component/Home";
import CheckoutVerifyPhoneNumber from "./component/Checkout/VerifyPhoneNumber";
import PaymentError from "./component/Checkout/PaymentError";
import { UserProvider } from "./Context/UserContext";
import { CheckoutRefProvider } from "./Context/CheckoutRef";
import Profile from "./component/Profile";
import PrivateRoute from "./component/PrivateRoute";
import PersonalInfoPgOne from "./component/Kyc/PersonalInfoPgOne";
import KycInfo from "./component/Kyc/KycInfo";
import PersonalInfoPgTwo from "./component/Kyc/PersonalInfoPgTwo";
import ReferencesAndGuarantor from "./component/Kyc/ReferencesAndGuarantor";
import AdditionalInfo from "./component/Kyc/AdditionalInformation/AdditionalInfo";
import BusinessInfoPgTwo from "./component/Kyc/BusinessInfoPgTwo";
import BusinessInfoPgOne from "./component/Kyc/BusinessInfoPgOne";
import KycProcesssing from "./component/Kyc/KycProcesssing";
import KycApproved from "./component/Kyc/KycApproved";
import KycRejected from "./component/Kyc/KycRejected";
import KycRejectedWithReason from "./component/Kyc/KycRejectedWithReason";
import KycPendingModal from "./component/ForgotPassword/KycPendingModal";
import PaymentSuccess from "./component/Checkout/PaymentSuccess";
import PaymentMode from "./component/Checkout/PaymentMode";
import History from "./component/TransactionHistory";
import Loans from "./component/Loans";
import Notifications from "./component/Notifications";
import ConfirmDelivery from "./component/ConfirmDelivery";
import KycCompleted from "./component/Kyc/KycCompleted";
import BnplFailedReason from "./component/Checkout/PaymentError/BnplFailedReason";
import InsufficientSalesHistory from "./component/InsufficientSalesHistory";
import NotRegistered from "./component/NotRegistered/NotRegistered";
// import ViewportResizer from "./component/ViewportResizer/ViewportResizer";
import RejectedOffer from "./component/Checkout/RejectedOffer";
import ProcessingSalesHistory from "./component/ProcessingSalesHistory";
import { useCallback, useEffect } from "react";
import { initializeHotjar } from "./Utility/hotjat";
import FreshChat from "./component/FreshChat";
import CantSyncSalesData from "./component/CantSyncSalesData";
import config from "./config";
import { rudderAnalytics } from "./Utility/rudderEventGenerator";

function App() {
  /* @HINT: Read Hotjar secrets from environment varaibles */
  const hotjarId = Number(config.HOTJAR_ID);
  const hotjarSnipVersion = Number(config.HOTJAR_SNIPPET_VERSION);

  const bootHotJar = useCallback(
    (hotjarId: number, hotjarSnipVersion: number) => {
      initializeHotjar(hotjarId, hotjarSnipVersion);
    },
    []
  );

  if (config.PROD) {
    if (typeof rudderAnalytics.getAnonymousId() === "undefined") {
      rudderAnalytics.ready(() => undefined);
    }
  }

  useEffect(() => {
    /* @NOTE: `check for dev and prod env */
    if (!config.API_ENDPOINT?.includes("dev")) {
      bootHotJar(hotjarId, hotjarSnipVersion);
    }
  }, [bootHotJar, hotjarId, hotjarSnipVersion]);

  return (
    <BrowserRouter>
      {/* <ViewportResizer /> */}
      <UserProvider>
        <CheckoutRefProvider>
          <Routes>
            <Route path="/" element={<Navigate replace to="/sign-in" />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/verify-otp" element={<VerifyOtp />} />
            <Route path="/checkout/bnpl/error" element={<PaymentError />} />

            <Route
              path="/processing-sales-history"
              element={<ProcessingSalesHistory />}
            />

            <Route
              path="/cant-process-sales-history"
              element={<CantSyncSalesData />}
            />

            <Route path="/checkout/bnpl/success" element={<PrivateRoute />}>
              <Route path="" element={<PaymentSuccess />} />
            </Route>

            <Route
              path="/checkout/bnpl/loan-details"
              element={<PrivateRoute />}
            >
              <Route path="" element={<PaymentMode />} />
            </Route>

            <Route
              path="/checkout/bnpl-rejected-reason"
              element={<PrivateRoute />}
            >
              <Route path="" element={<BnplFailedReason />} />
            </Route>

            <Route
              path="/checkout/bnpl/reject-offer"
              element={<PrivateRoute />}
            >
              <Route path="" element={<RejectedOffer />} />
            </Route>

            <Route
              path="/checkout/bnpl"
              element={<CheckoutVerifyPhoneNumber />}
            />
            <Route path="/logged-in" element={<PrivateRoute />}>
              <Route path="" element={<PostLogin />}></Route>
            </Route>
            <Route path="/home" element={<PrivateRoute />}>
              <Route path="" element={<Home />} />
            </Route>
            <Route path="/history" element={<PrivateRoute />}>
              <Route path="" element={<History />} />
            </Route>

            <Route path="/profile" element={<PrivateRoute />}>
              <Route path="" element={<Profile />} />
            </Route>
            <Route path="/reset-password" element={<PrivateRoute />}>
              <Route path="" element={<ResetPassword />} />
            </Route>
            <Route path="/kyc" element={<PrivateRoute />}>
              <Route path="" element={<KycInfo />} />
            </Route>
            <Route path="/notifications" element={<PrivateRoute />}>
              <Route path="" element={<Notifications />} />
            </Route>
            <Route path="/confirm-delivery" element={<PrivateRoute />}>
              <Route path="" element={<ConfirmDelivery />} />
            </Route>
            <Route path="/loans" element={<PrivateRoute />}>
              <Route path="" element={<Loans />} />
            </Route>
            <Route
              path="/kyc/personal-information-1"
              element={<PrivateRoute />}
            >
              <Route path="" element={<PersonalInfoPgOne />} />
            </Route>

            <Route path="/kyc/reference-guarantor" element={<PrivateRoute />}>
              <Route path="" element={<ReferencesAndGuarantor />} />
            </Route>

            <Route
              path="/kyc/personal-information-2"
              element={<PrivateRoute />}
            >
              <Route path="" element={<PersonalInfoPgTwo />} />
            </Route>
            <Route
              path="/kyc/business-information-2"
              element={<PrivateRoute />}
            >
              <Route path="" element={<BusinessInfoPgTwo />} />
            </Route>
            <Route
              path="/kyc/business-information-1"
              element={<PrivateRoute />}
            >
              <Route path="" element={<BusinessInfoPgOne />} />
            </Route>

            <Route path="/kyc/additional-info" element={<PrivateRoute />}>
              <Route path="" element={<AdditionalInfo />} />
            </Route>

            <Route path="/kyc-processing" element={<PrivateRoute />}>
              <Route path="" element={<KycProcesssing />} />
            </Route>

            <Route path="/kyc-approved" element={<PrivateRoute />}>
              <Route path="" element={<KycApproved />} />
            </Route>

            <Route path="/kyc-rejected" element={<PrivateRoute />}>
              <Route path="" element={<KycRejected />} />
            </Route>

            <Route
              path="/insufficient-sales-history"
              element={<PrivateRoute />}
            >
              <Route path="" element={<InsufficientSalesHistory />} />
            </Route>

            <Route path="/not-registered" element={<PrivateRoute />}>
              <Route path="" element={<NotRegistered />} />
            </Route>

            <Route path="/kyc-completed" element={<PrivateRoute />}>
              <Route path="" element={<KycCompleted />} />
            </Route>

            <Route path="/kyc-rejected-with-reason" element={<PrivateRoute />}>
              <Route path="" element={<KycRejectedWithReason />} />
            </Route>
            <Route path="/kyc-required" element={<PrivateRoute />}>
              <Route path="" element={<KycPendingModal />} />
            </Route>
          </Routes>
        </CheckoutRefProvider>
      </UserProvider>

      <FreshChat />
    </BrowserRouter>
  );
}

export default App;

import { Link, useLocation } from "react-router-dom";
import WebBackground from "../Background/WebBackground";
import styles from "../Background/WebBackground.module.scss";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ResetPassword from "./ForgotPassword";
import LoggedOutBottomBar from "../BottomBar/LoggedOutBottomBar";

function ForgotPassword() {
  const { state } = useLocation();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const business_id = urlParams.get("business_id");
  return (
    <>
      {state?.isForgotPassword ? (
        <div className="relative">
          <div
            className="md:container md:py-[40px]"
            style={{ minHeight: "calc(100vh - 58px)" }}
          >
            <WebBackground />
            <div
              className={`${styles.formElement} ${styles.inputField} flex justify-center item-center`}
            >
              <ResetPassword />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 md:container py-[14px] w-full">
            <LoggedOutBottomBar />
          </div>
          <div
            className={`absolute bottom-[135px] md:bottom-[110px] right-[-4px] md:right-[-10px] ${styles.duploLogoOpaque}`}
          >
            <img
              src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
              alt="DuploLogo"
            />
          </div>
        </div>
      ) : (
        <div className="relative">
          <div
            className="md:container md:py-[40px]"
            style={{ minHeight: "calc(100vh - 58px)" }}
          >
            <WebBackground />
            <div
              className={`${styles.formElement} ${styles.inputField} flex justify-center item-center`}
            >
              <ForgotPasswordForm />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3  md:container py-[14px] w-full relative z-10">
            <LoggedOutBottomBar />
            {window.innerWidth >= 768 && (
              <div className="flex justify-center text-[14px]">
                <div className="text-[#192850]">Don't have an account?</div>
                <Link
                  to={
                    business_id
                      ? `/sign-up?business_id=${business_id}`
                      : "/sign-up"
                  }
                  className="pl-[10px] text-[#2DB49E] underline underline-offset-4"
                >
                  {" "}
                  Sign up
                </Link>
              </div>
            )}
          </div>
          <div
            className={`absolute bottom-[135px] md:bottom-[110px] right-[-4px] md:right-[-10px] ${styles.duploLogoOpaque}`}
          >
            <img
              src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
              alt="DuploLogo"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default ForgotPassword;

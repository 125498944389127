import { useUserContext } from '../../Context/UserContext'
import {
  PersonalInfoCompletionStatus,
  BusinessInfoCompletionStatus,
  GuarantorInfoCompletionStatus,
} from '../../Utility/KycCompletedStatus'
import {
  businessInfoValues,
  guarantorInfoValues,
  personalInfoValues,
} from '../../Utility/FormValues'
import { useEffect, useState } from 'react'

const ProgressBar = ({ setProfileCompletionStatus }: any) => {
  const [completionPer, setCompletionper] = useState<any>(0)

  const { kycDetails, lenderKycSchema } = useUserContext()  
  if (parseInt(completionPer) === 100) {
    setProfileCompletionStatus(true)
  }

  useEffect(() => {
    const rejectedReasons = kycDetails?.rejected_reason;    
    const personalInfo = PersonalInfoCompletionStatus(kycDetails,rejectedReasons),
      businessInfo = BusinessInfoCompletionStatus(kycDetails,rejectedReasons),
      guarantorInfo = GuarantorInfoCompletionStatus(kycDetails,rejectedReasons),
      totalPersonalInfo = personalInfoValues?.length,
      totalBusinessInfo = businessInfoValues?.length,
      totalGuarantor = guarantorInfoValues?.length,
      totalAdditionalInfo = (lenderKycSchema?.properties && Object.keys(lenderKycSchema?.properties)?.length) || 0;
    const totalFields: any = `${
      totalPersonalInfo + totalBusinessInfo + totalGuarantor + totalAdditionalInfo
    }`
    const totalSumbittedExtraDetails = (kycDetails?.extra_details && Object.keys(kycDetails?.extra_details)?.length) || 0;
    const totalRejectedExtraDetails = (rejectedReasons?.extra_details && Object.keys(rejectedReasons?.extra_details)?.length) || 0;
    const validatedAdditionalInfoFields= totalSumbittedExtraDetails - totalRejectedExtraDetails;

    const totalCompletedFields: any = `${
      personalInfo + businessInfo + guarantorInfo + validatedAdditionalInfoFields
    }`;    
    let completionStatus = `${(100 / totalFields) * totalCompletedFields}`;

    if(Number(completionStatus) > Number('100')) {
      completionStatus = '100'
    }
    
    if(Number(completionStatus) > Number('0')){
      setCompletionper(completionStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex items-center bg-[#fff] mx-[-15px] px-[15px] py-[35px] justify-between mt-[20px] md:mt-0">
      <svg
        width="245"
        height="14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x=".5"
          y="1"
          width="244"
          height="12"
          rx="6"
          fill="#fff"
          stroke="#2DB49E"
        />
        <rect
          y="1"
          width={`${completionPer}%`}
          height="12"
          rx="6"
          fill="#2DB49E"
        />
      </svg>
      <div className="md:ml-[28px] font-normal text-[12px] leading-[18px] text-[#94A2AB]">
        {Math.floor(completionPer)}% Complete
      </div>
    </div>
  )
}

export default ProgressBar

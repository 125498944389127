import {
  useState,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";
import { api } from "../Service/AuthService";

type UserContext = {
  userProfile: any;
  setUserProfile: Dispatch<SetStateAction<any>>;
  kycDetails: any;
  setKycDetails: Dispatch<SetStateAction<any>>;
  lenderKycSchema: any;
  setLenderKycSchema: Dispatch<SetStateAction<any>>;
  clearUserContext: () => void;
  fetchKycDetails: () => Promise<any>;
  fetchKycSchema: () => Promise<any>;
};

export const Context = createContext<UserContext>({
  userProfile: null,
  kycDetails: null,
  lenderKycSchema: null,
  setUserProfile: () => { },
  setKycDetails: () => { },
  setLenderKycSchema: () => { },
  clearUserContext: () => { },
  fetchKycDetails: function (): Promise<any> {
    throw new Error("Function not implemented.");
  },
  fetchKycSchema: function (): Promise<any> {
    throw new Error("Function not implemented.");
  }
});

// Make it a singleton var so that two requests are avoided.
const fetchStates = {
  isFetchingKycDetails: false,
  isFetchingKycSchema: false
};

export const UserProvider = ({ children }: any) => {
  const [userProfile, setUserProfile] = useState<any>(null);
  const [kycDetails, setKycDetails] = useState<any>(null);
  const [lenderKycSchema, setLenderKycSchema] = useState<any>(null);
  // const [fetchStates, setFetchStates] = useState<any>({
  //   isFetchingKycDetails: false,
  //   isFetchingKycSchema: false
  // });
  // const [isFetchingKycDetails, setIsFetchingKycDetails] = useState<boolean>(false);
  // const [isFetchingKycSchema, setIsFetchingKycSchema] = useState<boolean>(false);

  const clearUserContext = () => {
    setUserProfile(null);
    setKycDetails(null);
    setLenderKycSchema(null);
  };

  const fetchKycSchema = () => {
    if ( fetchStates.isFetchingKycSchema ) {
      return Promise.resolve( null );
    }
  
    fetchStates.isFetchingKycSchema = true;
    return api.get("/kyc-schema")
      .then((response) => {
        fetchStates.isFetchingKycSchema = false;
        const responseData = response?.data?.data;
        const extendedKycSchema = responseData?.extra_detials_kyc_schema || {};
        setLenderKycSchema(extendedKycSchema);
        return response;
      })
      .catch((e) => {
        fetchStates.isFetchingKycSchema = false;
        throw(e);
      });
  };

  const fetchKycDetails = () => {
    
    if ( !userProfile?.kyc_id || fetchStates.isFetchingKycDetails) {
      return Promise.resolve(null);
    }

    fetchStates.isFetchingKycDetails = true;
    return api.get("/kyc")
      .then((response) => {
        fetchStates.isFetchingKycDetails = false;
        const responseData = response?.data?.data;
        const kycObj = responseData?.kyc;
        setKycDetails(kycObj);
        return response;
      })
      .catch((e) => {
        fetchStates.isFetchingKycDetails = false;
        throw(e);
      });
  };

  return (
    <Context.Provider
      value={{ userProfile, kycDetails, setUserProfile, setKycDetails, lenderKycSchema, setLenderKycSchema, clearUserContext, fetchKycSchema, fetchKycDetails  }}
    >
      {children}
    </Context.Provider>
  );
};

export const useUserContext = () => useContext(Context);

import LoggedInBottomBar from "../BottomBar/LoggedInBottomBar";
import SideBar from "../SideBar/SideBar";
import styles from "./HomePage.module.scss";

function InsufficientSaleHistory() {
  return (
    <div className="md:flex md:items-center md:justify-center relative">
      <div className="hidden md:block z-50">
        <SideBar />
      </div>
      <div
        className={`w-full md:w-4/6 md:my-[50px] md:rounded-[20px] md:ml-[-100px] md:border-[#2db49e80] md:border-[1px] overflow-y-auto md:min-h-[690px] ${styles.pageHeight} bg-[#2db49e1a] md:bg-transparent flex justify-center items-center`}
      >
        <div>
          <div className="flex justify-center">
            <img
              className="w-[93px] h-[84px]"
              src="https://d1f8g8i172sxu4.cloudfront.net/images/cautionRed.png"
              alt="Caution"
            />
          </div>
          <div className="text-center mt-[28px] font-semibold text-[20px] leading-[25px] text-[#103129]">
            Insufficient sales history
          </div>
          <div className="mt-[20px] font-light text-[14px] leading-[19px] text-[#192850] opacity-60 text-center max-w-[400px]">
            We are sorry, but you do not have sufficient sales history to access
            this product. Please try again after you've made more purchases with
            your vendor.
          </div>
        </div>
      </div>
      <div className="md:hidden w-full">
        <LoggedInBottomBar />
      </div>
      <div
        className={`absolute bottom-[160px] md:bottom-[105px] right-[10px] md:right-[-10px] ${styles.duploLogoOpaque} z-[-1]`}
      >
        <img
          src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
          alt="DuploLogo"
        />
      </div>
    </div>
  );
}

export default InsufficientSaleHistory;

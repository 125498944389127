function InsufficientSales() {
  return (
    <>
      <div className="rounded-t-[30px] md:rounded-[30px] pt-[40px] pb-[80px] relative z-10 flex justify-center items-center">
        <div className="">
          <div className="flex justify-center">
            <img
              className="w-[93px] h-[84px]"
              src="https://d1f8g8i172sxu4.cloudfront.net/images/cautionRed.png"
              alt="Caution"
            />
          </div>
          <div className="text-center mt-[28px] font-semibold text-[20px] leading-[25px] text-[#103129]">
            Insufficient sales history
          </div>
          <div className="mt-[20px] font-light text-[14px] leading-[19px] text-[#192850] opacity-60 text-center  max-w-[400px]">
            We are sorry, but you do not have sufficient sales history to access
            this product. Please try again after you've made more purchases with
            your vendor.
          </div>
        </div>
      </div>
    </>
  );
}

export default InsufficientSales;

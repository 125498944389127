import { useCheckoutContext } from "../../../Context/CheckoutRef";

interface SideBarProps {
  isBnpl?: boolean,
  isTransfer?: boolean
}

function SideBar({ isBnpl = true}: SideBarProps) {
  const { getDirectTransferUrl} = useCheckoutContext()

  return (
    <div className="h-screen bg-[#103129] w-full relative">
      <div className="text-[12px] leading-[15px] tracking-[0.64px] text-[#babfc1b3] pt-[53px] px-[30px] font-medium">
        PAY WITH
      </div>
  
      <div style={{ display: "none" }} className="flex items-center mt-[33px] py-[17px] px-[30px] cursor-pointer text-[#fff]">
      
        <div>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_17326_85067)">
              <path
                d="M7.74247 0.44873L0.5 3.93586V5.75004H15.5V3.92623L7.74247 0.44873ZM14.5 4.75004H1.5V4.56423L7.75753 1.55136L14.5 4.57386V4.75004ZM1.5 12.75H14.5V13.75H1.5V12.75ZM0.5 14.5H15.5V15.5H0.5V14.5ZM1.75 6.75004H2.75V11.75H1.75V6.75004ZM13.25 6.75004H14.25V11.75H13.25V6.75004ZM10.25 6.75004H11.25V11.75H10.25V6.75004ZM4.75 6.75004H5.75V11.75H4.75V6.75004ZM7.5 6.75004H8.5V11.75H7.5V6.75004Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_17326_85067">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <a href={getDirectTransferUrl()} className="pl-[16px] text-[14px] leading-[14px] font-normal">Bank Transfer</a>
     
      </div>
     
     {isBnpl &&
      <div className="flex items-center py-[17px] bg-[#ffffff33] px-[30px] cursor-pointer mt-[33px] text-[#fff] border-l-[5px] border-l-[#fff]">
        <div>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_17326_85067)">
              <path
                d="M7.74247 0.44873L0.5 3.93586V5.75004H15.5V3.92623L7.74247 0.44873ZM14.5 4.75004H1.5V4.56423L7.75753 1.55136L14.5 4.57386V4.75004ZM1.5 12.75H14.5V13.75H1.5V12.75ZM0.5 14.5H15.5V15.5H0.5V14.5ZM1.75 6.75004H2.75V11.75H1.75V6.75004ZM13.25 6.75004H14.25V11.75H13.25V6.75004ZM10.25 6.75004H11.25V11.75H10.25V6.75004ZM4.75 6.75004H5.75V11.75H4.75V6.75004ZM7.5 6.75004H8.5V11.75H7.5V6.75004Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_17326_85067">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="pl-[16px] text-[14px] leading-[14px] font-normal">Buy Now Pay Later</div>
      </div>
      }
      <div className="absolute bottom-0">
        <img src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png" alt="" />
      </div>
    </div>
  );
}

export default SideBar;

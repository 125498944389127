import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../Context/UserContext";
import { api } from "../../Service/AuthService";
import { CurrencyFormat } from "../../Utility/CurrencyFormat";
import SideBar from "../SideBar/SideBar";
import { ReactComponent as GreenLeftArrow } from "../svg/greenLeftArrow.svg";
import styles from "./Kyc.module.scss";

function KycApproved() {
  const { fetchKycDetails } = useUserContext();
  const dataFetchedRef = useRef(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [creditLimit, setCreditLimit] = useState(0);
  const [daysleft, setDaysLeft] = useState(0);
  const [interestRate, setInterestRate] = useState(0);
  const navigate = useNavigate();
  const tokenisation = async () => {
    await api
      .post("kyc/tokenization")
      .then((response) => {
        if (response?.data?.success) {
          const tokenization = response?.data?.data?.tokenization;
          fetchKycDetails();
          window.location.replace(tokenization?.url);
        }
      })
      .catch((error) => {
        setErrorMessage(
          error &&
            error.response &&
            error.response.data &&
            error.response.data &&
            error.response.data.message
        );
        setTimeout(() => {
          setErrorMessage("");
        }, 2000);
      });
  };

  const getCreditLimit = () => {
    api.get("/credit-limit").then((response) => {
      const responseData = response?.data?.data;
      const creditLimit = responseData?.credit_limit;
      setCreditLimit(creditLimit?.credit_limit);
      setDaysLeft(creditLimit?.period);
      setInterestRate(creditLimit?.interest_rate);
    });
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    getCreditLimit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="md:flex md:items-center md:justify-center relative">
      <div className="hidden md:block z-50">
        <SideBar />
      </div>
      <div
        className={`w-full md:w-4/6 md:my-[50px] md:rounded-[20px] md:ml-[-100px] md:border-[#2db49e80] md:border-[1px] overflow-y-auto ${styles.pageHeight} bg-[#2db49e1a] md:bg-transparent`}
      >
        <div className="py-[20px] md:py-[30px] md:pl-[165px] md:pr-[40px] md:min-h-[690px]">
          <div
            className="flex items-center px-[15px] cursor-pointer"
            onClick={() => {
              navigate("/profile");
            }}
          >
            <div>
              <GreenLeftArrow />
            </div>
            <div className="ml-[30px] mr-[10px] text-[#192850] font-semibold text-[20px] leading-[25px]">
              KYC
            </div>
            <div className="font-normal text-[16px] text-[#2DB49E] p-[10px] bg-[#2db49e29] rounded-[10px]">
              Approved
            </div>
          </div>
          <div className="md:mx-[50px] mt-[25px]">
            <div className="w-full py-[22px] px-[30px] border-[1px] border-[#0000000d] rounded-[5px] grid grid-cols-3 bg-[#fff]">
              <div className="justify-self-start">
                <div className="font-semibold text-[16px] leading-[14px] text-[#001812] opacity-[0.65]">
                  Credit Limit:
                </div>
                <div className="mt-[15px] font-medium text-[20px] leading-[14px] text-[#2DB49E]">
                  {CurrencyFormat(creditLimit)}
                </div>
              </div>
              <div className="justify-self-center">
                <div className="font-light text-[12px] leading-[16px] text-[#5C696E]">
                  Loan period
                </div>
                <div className="mt-[9px] font-medium text-[16px] leading-[20px] text-[#003F34]">
                  {daysleft} {daysleft === 1 ? "day" : "days"}
                </div>
              </div>
              <div className="justify-self-end">
                <div className="font-light text-[12px] leadind-[16px] text-[#5C696E]">
                  Interest rate
                </div>
                <div className="mt-[9px] font-medium text-[16px] leading-[20px] text-[#003F34]">
                  {interestRate}% monthly
                </div>
              </div>
            </div>

            <div className="mt-4 w-full py-[22px] px-[30px] border-[1px] border-[#0000000d] rounded-[5px] bg-[#fff]">
              <p className="text-[13px]">
                Your creditworthiness has been verified and you are eligible to
                take a maximum loan of {CurrencyFormat(creditLimit)}.
              </p>
              <p className="text-[13px] py-2">
                Please note that any credit request will be subject to the terms
                and conditions outlined in our loan agreement, which you will be
                required to accept before the approved loan is extended.
              </p>
              <p className="text-[13px]">
                Click continue to proceed with your loan application.
              </p>

              <div className="flex justify-center mt-[45px]">
                <button
                  className={`w-full md:w-1/2 py-[13px] bg-[#2DB49E] rounded-[5px] font-medium text-[14px] leading-[14px] text-[#fff]`}
                  onClick={() => tokenisation()}
                >
                  Continue
                </button>
              </div>

              <div className="text-[16px] font-bold text-[#FF0000] flex justify-center mt-[10px] min-h-[24px]">
                {errorMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`absolute bottom-[160px] md:bottom-[105px] right-[10px] md:right-[-10px] ${styles.duploLogoOpaque} z-[-1]`}
      >
        <img
          src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
          alt="DuploLogo"
        />
      </div>
    </div>
  );
}

export default KycApproved;

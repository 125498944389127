import { ReactComponent as Warning } from "../../svg/warning.svg";

function LinkExpired() {
  return (
    <div
      className="container flex items-center justify-center"
      style={{ minHeight: "calc(100vh - 55px)" }}
    >
      <div>
        <div className="flex justify-center">
          <Warning />
        </div>
        <div className="flex justify-center mt-[22px] font-medium text-[16px] leading-[20px] text-[#000000]">
          Link has expired or is invalid
        </div>
      </div>
    </div>
  );
}

export default LinkExpired;
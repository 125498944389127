import styles from './HomePage.module.scss'
import { ReactComponent as CopyIcon } from '../svg/copyIcon.svg'
import { ReactComponent as ModalCloseIcon } from '../svg/modalCloseIcon.svg'

import { useState } from 'react'

function PayBusinessModal({ shown, close, virtualAcc }: any) {
  const [copiedAlert, setCopiedAlert] = useState(false)

  const copy = async () => {
    await navigator.clipboard.writeText(virtualAcc?.account_number)
    setCopiedAlert(true)
    setTimeout(() => {
      setCopiedAlert(false)
    }, 3000)
  }

  return shown ? (
    <div
      className={`${styles.modalBackdrop}`}
      onClick={() => {
        close()
      }}
    >
      <div
        className={`${styles.modalContent}`}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div>
          <div className="flex items-center justify-between bg-[#F9F9F9] w-full rounded-t-[10px] py-[30px] px-[20px]">
            <div className="font-semibold text-[18px] leading-[14px] text-[#162930]">
              Pay business
            </div>
            <button className="text-[#000]" onClick={close}>
              < ModalCloseIcon />
            </button>
          </div>
          <div className="mt-[30px] px-[35px] font-normal text-[14px] leading-[18px] text-[#192850]">
            Make payment using the details below:
          </div>
          <div className="my-[30px] mx-[35px] bg-[#F6F6F6] rounded-[5px]">
            <div className="flex justify-between py-[25px] mx-[30px] gap-x-[20px]">
              <div>
                <div className="text-start">
                  <div className="font-normal text-[14px] leading-[18px] text-[#5C696E]">
                    Account Number
                  </div>
                  <div className="flex items-center mt-[14px] h-[24px]">
                    <div className="font-normal text-[14px] leading-[18px] text-[#003F34]">
                      {virtualAcc?.account_number}
                    </div>
                    <div className="ml-[10px] cursor-pointer">
                      {copiedAlert ? <div className='bg-[#2DB49E] text-[12px] px-[5px] rounded'>Copied</div> :<div onClick={() => {copy()}}> <CopyIcon /></div> }
                    </div>
                  </div>
                  <div className="font-normal text-[14px] leading-[18px] text-[#5C696E] mt-[22px]">
                    Account Name
                  </div>
                  <div className="flex items-center mt-[14px]">
                    <div className="font-normal text-[14px] leading-[18px] text-[#003F34]">
                      {virtualAcc?.account_name}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-start">
                <div className="font-normal text-[14px] leading-[18px] text-[#5C696E]">
                  Bank Name
                </div>
                <div className="flex items-center mt-[14px]">
                  <div className="font-normal text-[14px] leading-[18px] text-[#003F34]">
                    {virtualAcc?.bank_name}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#F9F9F9] rounded-b-[10px] pt-[18px] pb-[46px] flex justify-center">
            <button
              className="text-[#FFFFFF] text-[14px] leading-[14px] font-normal bg-[#2DB49E] py-[13px] px-[44px] rounded-[5px]"
              onClick={close}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default PayBusinessModal

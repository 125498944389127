import { useState, useRef, useEffect } from "react";
import styles from "./HomePage.module.scss";
import Select, { components } from "react-select";
import { CurrentBusinessDropdownStyle } from "../../Utility/CurrentBusinessDropdownStyle";

const CurrentBusiness = ({ setAccountRef, businessList }: any) => {
  const [listOfBusiness, setListOfBusiness] = useState<
    { label: string; value: string }[]
  >([]);
  const [selected, setIsSelected] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [dataFetchedRef, setDataFetchedRef] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const getTruncatedString = (business: string) => {
    if (business?.length <= 12 || isOpen) {
      return business;
    } else {
      return business?.substring(0, 12)?.concat("...");
    }
  };

  const Option = (props: any) => {
    const hasBorder =
      props?.options[props?.options?.length - 1]?.label === props?.data?.label
        ? false
        : true;
    return (
      <components.Option {...props}>
        <div className="px-[10px] py-[15px]">
          <div className="text-[16px] leading-[14px]">{props?.data?.label}</div>
        </div>
        <div
          className={`${
            hasBorder ? "border-b border-[#94A2AB66]" : "pb-[30px]"
          }`}
        ></div>
      </components.Option>
    );
  };

  window.addEventListener("click", (e: any) => {
    if (!document.getElementById("dropdownBox")?.contains(e.target)) {
      setIsOpen(false);
    }
  });

  const setDropdownValue = (e: any) => {
    setIsSelected(e?.label);
    setAccountRef(e?.value);
  };

  useEffect(() => {
    if (dropdownRef.current) {
      const height = dropdownRef.current.scrollHeight;
      if (height < 335) {
        dropdownRef.current.style.setProperty("--max-height", `${height}px`);
      } else {
        dropdownRef.current.style.setProperty("--max-height", `335px`);
        dropdownRef.current.style.setProperty("overflow-y", `auto`);
      }
      if (isOpen) {
        dropdownRef.current.style.setProperty("width", "286px");
      } else {
        dropdownRef.current.style.setProperty("width", "174px");
      }
    }
  }, [isOpen]);

  useEffect(() => {
    setListOfBusiness(
      businessList.filter(
        (business: { label: string }) =>
          business.label !== businessList[0].label
      )
    );
  }, [businessList]);

  useEffect(() => {
    setListOfBusiness(
      businessList.filter(
        (business: { label: string }) => business.label !== selected || ""
      )
    );
    // eslint-disable-next-line
  }, [selected]);

  return (
    <div className="relative">
      <div
        className={`${styles.currentBusinessDropdown} ${
          isOpen ? styles.openCurrentBusinessDropdown : ""
        }`}
        ref={dropdownRef}
        onClick={(e) => {
          if (businessList && businessList.length > 1) {
            e.stopPropagation();
            if (!dataFetchedRef) setDataFetchedRef(true);
            setIsOpen(!isOpen);
          }
        }}
      >
        <>
          <div className="cursor-pointer">
            <div className="flex items-center justify-between px-[15px] py-[19px] text-[#fff]">
              <div className="text-[18px] font-medium leading-[14px]">
                {selected ? (
                  <div className="text-[#fff]">
                    {getTruncatedString(selected)}
                  </div>
                ) : (
                  <div className="text-[#fff]">
                    {getTruncatedString(businessList?.[0]?.label)}
                  </div>
                )}
              </div>
              {businessList && businessList.length > 1 && (
                <div
                  className={`${
                    isOpen ? "rotate-180" : "rotate-0"
                  } ml-auto pt-[2px]`}
                >
                  <svg
                    width="14"
                    height="7"
                    viewBox="0 0 14 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 0L7 7L6.11959e-07 -1.22392e-06L14 0Z"
                      fill="#05CD99"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
          {businessList && businessList.length > 1 && (
            <>
              <div>
                {businessList?.length === 0 ? (
                  <div className="pb-[100px] pt-[10px] text-[#fff] text-center">
                    No business available
                  </div>
                ) : (
                  <Select
                    className="cursor-pointer"
                    options={listOfBusiness}
                    components={{ Option, DropdownIndicator: null }}
                    styles={CurrentBusinessDropdownStyle}
                    menuIsOpen
                    onChange={(e) => {
                      setDropdownValue(e);
                    }}
                  />
                )}
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default CurrentBusiness;

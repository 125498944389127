import { api } from "../Service/AuthService";

export const getImageUploadParams = (
  fileTypeIntent: string,
  imageFile: any
) => {
  const localFileName = imageFile?.name;
  const fileExtension = localFileName.split(".").pop().toLowerCase();
  const name = `${fileTypeIntent}_${Date.now()}.${fileExtension}`;

  return name;
};

export const getSignedUrl = async (
  fileTypeIntent: string,
  fileName: string
) => {
  const signedURLDataRes = await api.get("/signed-url", {
    params: {
      file_type_intent: fileTypeIntent,
      file_names: [fileName],
    },
  });
  return signedURLDataRes;
};

export const uploadImageToS3 = async (postUrl: string, payload: any) => {
  const res = await api.post(postUrl, payload);
  return res;
};

export const getFormData = (postFields: any, originalImage: any) => {
  let formData = new FormData();
  Object.entries(postFields).forEach((entry) => {
    const [key, value]: any = entry;
    formData.append(key, value);
  });
  formData.append("file", originalImage);
  return formData;
};

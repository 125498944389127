import { useUserContext } from "../../Context/UserContext";
import { api } from "../../Service/AuthService";
import { ReactComponent as GreenRightArrow } from "../svg/greenRightArrow.svg";
import { ReactComponent as RedRightArrow } from "../svg/redRightArrow.svg";
import { useNavigate } from "react-router-dom";
// import NotificationCount from "../NotificationCount";

function UserAccount() {
  const { userProfile, clearUserContext, kycDetails } = useUserContext();
  const phoneNumber = userProfile?.phone_number;

  const navigate = useNavigate();

  function getOtp() {
    api
      .post(`/phone/get-otp`, {
        phone_number: phoneNumber,
      })
      .then(() => {
        navigate("/verify-otp", {
          state: { phoneNumber: phoneNumber, isResetPassword: true },
        });
      });
  }

  async function logOut() {
    await api.post("/phone/logout").then((response) => {});
    clearUserContext();
    localStorage.clear();
    navigate("/sign-in");
  }

  let redirectTo = "";
  let styleClasses = "";
  let statusText = "";
  const kycStatus = kycDetails?.status;
  if (kycStatus === "APPROVED" || kycStatus === "TOKENIZATION_INITIATED") {
    redirectTo = "/kyc-approved";
    styleClasses = "text-[#2DB49E] bg-[#2db49e29]";
    statusText = "Approved";
  } else if (kycStatus === "REJECTED") {
    redirectTo = "/kyc-rejected";
    styleClasses = "text-[#ED5B5C] bg-[#ed5b5c29]";
    statusText = "Rejected";
  } else if (kycStatus === "REJECTED_WITH_REASON") {
    redirectTo = "/kyc-rejected-with-reason";
    styleClasses = "text-[#9747FF] bg-[#9747ff29]";
    statusText = "Rejected with reason";
  } else if (kycStatus === "SUBMITTED") {
    redirectTo = "/kyc-processing";
    styleClasses = "text-[#007AFF] bg-[#007aff29] ";
    statusText = "Processing";
  } else if (kycStatus === "ACTIVATED") {
    redirectTo = "/kyc-completed";
    styleClasses = "text-[#FFFFFF] bg-[#000000b3] ";
    statusText = "Completed";
  } else {
    redirectTo = "/kyc";
    styleClasses = "text-[#C59900] bg-[#c5990029]";
    statusText = "Incomplete";
  }

  return (
    <div className="container">
      <div className="py-[20px] md:py-[40px] md:pl-[165px] md:pr-[40px] overflow-y-auto md:min-h-[690px] relative z-10 md:bg-[#fff]">
        <div className="md:flex md:items-center">
          <div className="flex justify-center md:justify-start">
            <div className="rounded-full border-[2px] border-[#57b8946e] w-[80px] h-[80px]">
              <div className="flex justify-center items-center">
                <div className="w-[80px] h-[80px] rounded-full">
                  {kycDetails?.personal_photo_url && (
                    <div className="w-[80px] h-[80px] overflow-hidden rounded-full">
                      <img
                        src={kycDetails?.personal_photo_url}
                        alt="personalPhoto"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="ml-0 md:ml-[30px]">
            <div className="flex justify-center md:justify-start mt-[20px] md:mt-0 font-bold text-[18px] leading-[14px] text-[#162930]">
              {kycDetails?.first_name}&nbsp;{kycDetails?.last_name}
            </div>
            <div className="flex justify-center md:justify-start mt-[15px] font-normal text-[14px] leading-[14px] text-[#5C696E]">
              {userProfile?.email}
            </div>
            <div className="flex justify-center md:justify-start mt-[10px] font-normal text-[14px] leading-[14px] text-[#5C696E]">
              {phoneNumber}
            </div>
          </div>
        </div>
        {/* <div
          className="flex items-center justify-between mt-[70px] md:mt-[80px] font-normal text-[#162930] text-[18px] leading-[14px] mb-[40px] mx-[12px] cursor-pointer"
          onClick={() => navigate("/notifications")}
        >
          <div className="flex items-center gap-[1px]">
            <div>Notifications</div>
            <NotificationCount />
          </div>
          <span>
            <GreenRightArrow />
          </span>
        </div> */}
        <div
          className="mt-[60px] md:mt-[60px] flex items-center justify-between text-[#162930] border-b-[1px] border-[#94a2ab66] py-[31px] mx-[12px] cursor-pointer font-normal text-[18px] leading-[14px]"
          onClick={() => {
            navigate(redirectTo);
          }}
        >
          <div className="text-[20px] leading-[14px]">KYC</div>
          <div className="flex items-center">
            <span
              className={`p-[10px] rounded-[10px] text-[16px] leading-[14px] ${styleClasses}`}
            >
              {statusText}
            </span>
            <span className="ml-[15px]">
              <GreenRightArrow />
            </span>
          </div>
        </div>
        <div
          className="flex items-center justify-between text-[#162930] border-b-[1px] border-[#94a2ab66] py-[40px] mx-[12px] cursor-pointer font-normal text-[18px] leading-[14px]"
          onClick={() => getOtp()}
        >
          <span>Password and Security</span>
          <span>
            <GreenRightArrow />
          </span>
        </div>
        <div
          className="flex items-center justify-between mt-[40px] text-[#ED5B5C] mx-[12px] cursor-pointer font-normal text-[18px] leading-[14px]"
          onClick={() => logOut()}
        >
          <span>Log-out</span>
          <span>
            <RedRightArrow />
          </span>
        </div>
      </div>
    </div>
  );
}

export default UserAccount;

import { useLocation, useNavigate } from "react-router-dom";
import styles from "./SideBar.module.scss";

function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();

  const imageUrlPrefix = "https://d1f8g8i172sxu4.cloudfront.net/images/";

  const homeClick = () => {
    navigate("/home");
  };
  const accountClick = () => {
    navigate("/profile");
  };
  const loanClick = () => {
    navigate("/loans");
  };
  const historyClick = () => {
    navigate("/history");
  };

  const currentLocation = location.pathname;

  return (
    <div className={`${styles.sideBar}`}>
      <div className="flex flex-col py-[40px] ml-[30px]">
        <div
          className={`cursor-pointer flex justify-center ${
            currentLocation === "/home" ? "bg-[#2db49e1a]" : "bg-[#fff]"
          } rounded-l-[10px]`}
          onClick={() => homeClick()}
        >
          <div
            className={`flex flex-col items-center justify-center py-[14px] pl-[35px] pr-[55px]`}
          >
            <div className={`w-[20px] h-[20px]`}>
              <img
                src={`${imageUrlPrefix}${
                  currentLocation === "/home"
                    ? "homeGreenIcon.png"
                    : "homeGreyIcon.png"
                }`}
                alt="homeIcon"
              />
            </div>
            <div
              className={`text-[14px] leading-[18px] tracking-[0.5px] mt-[7px] ${
                currentLocation === "/home"
                  ? "text-[#2DB49E] font-bold"
                  : "text-[#00181299] font-normal"
              }`}
            >
              Home
            </div>
          </div>
        </div>
        <div
          className={`flex justify-center mt-[46px] ${
            currentLocation === "/loans" ? "bg-[#2db49e1a]" : "bg-[#fff]"
          } rounded-l-[10px]`}
          onClick={() => loanClick()}
        >
          <div className="cursor-pointer flex flex-col items-center justify-center py-[14px] pl-[35px] pr-[55px]">
            <div className="w-[20px] h-[20px]">
              <img
                src={`${imageUrlPrefix}${
                  currentLocation === "/loans"
                    ? "loanGreenIcon.png"
                    : "loanGreyIcon.png"
                }`}
                alt="loanIcon"
              />
            </div>
            <div
              className={`text-[14px] leading-[18px] tracking-[0.5px] mt-[7px] ${
                currentLocation === "/loans"
                  ? "text-[#2DB49E] font-bold"
                  : "text-[#00181299] font-normal"
              }`}
            >
              Loans
            </div>
          </div>
        </div>
        <div
          className={`flex justify-center mt-[46px] ${
            currentLocation === "/history" ? "bg-[#2db49e1a]" : "bg-[#fff]"
          } rounded-l-[10px]`}
          onClick={() => historyClick()}
        >
          <div className="cursor-pointer flex flex-col items-center justify-center py-[14px] pl-[35px] pr-[55px]">
            <div className="w-[20px] h-[20px]">
              <img
                src={`${imageUrlPrefix}${
                  currentLocation === "/history"
                    ? "historyGreenIcon.png"
                    : "historyGreyIcon.png"
                }`}
                alt="historyIcon"
              />
            </div>
            <div
              className={`text-[14px] leading-[18px] tracking-[0.5px] mt-[7px] ${
                currentLocation === "/history"
                  ? "text-[#2DB49E] font-bold"
                  : "text-[#00181299] font-normal"
              }`}
            >
              History
            </div>
          </div>
        </div>
        <div
          className={`flex justify-center mt-[46px] 
          ${
            currentLocation === "/profile" ||
            currentLocation === "/verify-otp" ||
            currentLocation === "/reset-password" ||
            currentLocation === "/notifications" ||
            currentLocation.includes("kyc")
              ? "bg-[#2db49e1a]"
              : "bg-[#fff]"
          } 
          rounded-l-[10px]`}
          onClick={() => accountClick()}
        >
          <div
            className={`cursor-pointer flex flex-col items-center justify-center py-[14px] pl-[35px] pr-[55px]`}
          >
            <div className="w-[20px] h-[20px]">
              <img
                src={`${imageUrlPrefix}${
                  currentLocation === "/profile" ||
                  currentLocation === "/verify-otp" ||
                  currentLocation === "/notifications" ||
                  currentLocation === "/reset-password" ||
                  currentLocation.includes("kyc")
                    ? "accountGreenIcon.png"
                    : "accountGreyIcon.png"
                }`}
                alt="accountIcon"
              />
            </div>
            <div
              className={`text-[14px] leading-[18px] tracking-[0.5px] mt-[7px] ${
                currentLocation === "/profile" ||
                currentLocation === "/verify-otp" ||
                currentLocation === "/notifications" ||
                currentLocation === "/reset-password" ||
                currentLocation.includes("kyc")
                  ? "text-[#2DB49E] font-bold"
                  : "text-[#00181299] font-normal"
              }`}
            >
              Account
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;

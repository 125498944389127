import styles from "./PrivacyAndTermsModal.module.scss";
import { ReactComponent as GreyCloseIcon } from "../svg/greyCloseIcon.svg";

function PrivacyAndTermsModal({ shown, close }: any) {
  return shown ? (
    <div
      className={styles.modalBackdrop}
      onClick={() => {
        // close modal when outside of modal is clicked
        close();
      }}
    >
      <div
        className={styles.modalContent}
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}
      >
        <div className="flex items-center justify-between">
          <div className="font-semibold text-[20px] leading-[25px] text-[#192850]">
            Terms and privacy policy
          </div>
          <button onClick={close} className="text-[#000]">
            <GreyCloseIcon />
          </button>
        </div>
        <div className="mt-[20px] bg-[#d9d9d933] w-full h-[550px] overflow-y-auto rounded-[10px]">
          <div className="font-normal text-[15px] leading-[16px] text-[#192850] p-[20px]">
            INTRODUCTION <br /> <br />
            This <b>Terms and Conditions</b> (the “Terms” /“Agreement”) is a
            legal agreement between <b>Duplo Limited</b>. (“Duplo”, “us”, or
            “we”) and the User (“you”, “your” or “the Customer”) by your access
            to and use of Duplo Services on Duplo’s Platform. You will be unable
            to access our Services unless you agree to and continue to abide by
            the Terms contained or incorporated into this Agreement. <br />{" "}
            <br />
            <b className="text-[14px]">
              PLEASE READ THESE TERMS OF SERVICE CAREFULLY BEFORE SIGNING UP AS
              A USER. YOU MAY NOT ACCESS OR USE ANY SERVICES UNLESS YOU AGREE TO
              ABIDE BY ALL OF THE TERMS AND CONDITIONS IN THIS AGREEMENT.
            </b>
            <br /> <br />
            <ol className={`${styles.ol_reset} pl-3`}>
              <li>
                DEFINITIONS <br />
                <br />
                <div>
                  “Merchant” shall mean the business entity or vendor that you
                  are desirous of purchasing goods from. <br />
                  <br />
                  “Facility” shall mean the Invoiced amount made available by
                  the Liquidity Partner on behalf of the Customer to be repaid
                  by the Customer in addition to the agreed interest on a
                  repayment due date. <br />
                  <br />
                  “Liquidity Partner” shall mean any entity or organization or
                  business approved by Us to give Facility to a desiring
                  Customer on our Buy Now Pay Later Platform (BNPL). <br />
                  <br />
                  “Platform” shall means a software program and web services,
                  developed by Duplo called “Connect” for the provision of the
                  Services herein. <br />
                  <br />
                  “Repayment Account” shall mean an account for Customers to
                  repay the Facility amount to the Liquidity Partner. <br />
                  <br />
                  “User” shall mean the Customer. <br />
                  <br />
                  "User Data" shall mean any data, information, documents, or
                  materials submitted by the User to Duplo prior to or during
                  the use of the Site. It shall also include Customers data
                  processed by Duplo from time to time. <br />
                  <br />
                  “Transaction Account” shall mean the User’s virtual money
                  account maintained on Duplo’s Platform, created by the
                  Merchant for their Customer. <br />
                  <br />
                </div>
              </li>
              <li>
                ACCOUNT CREATION
                <ol className={`${styles.ol_reset} pl-3`}>
                  <li>
                    You will be assigned a dedicated, secure access to your
                    Duplo Account using your login details. You are responsible
                    for the password, chosen by you at the time of registration,
                    and undertake not to share it with anyone. Duplo shall not,
                    under any circumstances, be held responsible for the
                    consequences of fraudulent use of your password by a third
                    party.
                  </li>
                  <li>
                    To register for a Duplo Account, you or the person or people
                    submitting the application (your “Representative”) must
                    provide us with the requisite KYC information under the
                    following categories - Personal information, Business
                    information, References and Guarantors, and other
                    information that we might require. Duplo may also in its
                    absolute discretion collect personal information about your
                    beneficial owners, principals, and your Duplo Transaction
                    Account administrator (including name, birthdate, and
                    government-issued identification number). Until you have
                    submitted, and we have reviewed and approved, all required
                    information, your Duplo Account will be available to you on
                    a preliminary basis only, and we may terminate it at any
                    time and for any reason.
                  </li>
                  <li>
                    Without prejudice to the foregoing information, we reserve
                    the right to request additional information from you, as may
                    be required by us or our Affiliates in order to provide the
                    Services. Please note that your provision of the requested
                    information may become a condition for your continued use of
                    the Service and the maintenance of your Duplo Account.
                  </li>
                </ol>
              </li>
              <li>
                CHANGE OF INFORMATION
                <div className="py-2 pl-3">
                  You agree to keep the information in your Duplo Account up to
                  date. You are required to promptly update your Duplo Account
                  with any changes affecting you, the nature of your business
                  activities, your Representatives, beneficial owners,
                  principals, or any other pertinent information. We may suspend
                  your Duplo Account or terminate this Agreement if you fail to
                  keep this information current. You also agree to promptly
                  notify us if any of the following occur: you are the subject
                  of any voluntary or involuntary bankruptcy or insolvency
                  application, petition or proceeding, receivership, or similar
                  action (any of the foregoing, a “Bankruptcy Proceeding”);
                  there is an adverse change in your financial condition; there
                  is a planned or anticipated liquidation or substantial change
                  in the basic nature of your business.
                </div>
              </li>
              <li>
                CUSTOMER’S GENERAL OBLIGATION
                <ol className={`${styles.ol_reset} pl-3`}>
                  <li>
                    The Customer hereby expressly agrees to:
                    <div className="pl-4">
                      <p className="pt-2">
                        i. Provide all necessary and accurate KYC information required by Duplo from time to time.
                      </p>
                      <p className="pt-2">
                        ii. Immediately  notify  the  Merchant  of  any  discrepancy  in  the  quantity  of  goods  supplied  by  the Merchant.
                      </p>
                      <p className="pt-2">
                        iii. Hold Duplo harmless in respect of any discrepancy in the quantity and quality of the goods supplied by the Merchant.
                      </p>
                      <p className="pt-2">
                        iv. Be solely responsible for any liability arising out of the Customer’s negligence or mistake in relation to the Customer’s dealings with the Merchants.
                      </p>
                      <p className="pt-2">
                        v. Comply with all applicable anti-bribery and anti-corruption laws regulations and codes of practice in Nigeria.
                      </p>
                      <p className="pt-2">
                        vi. Use its best endeavour to ensure compliance with the relevant anti-money laundering laws in force.
                      </p>
                    </div>
                  </li>
                  <li>
                    Where the Customer subscribes to Duplo’s Buy Now Pay Later
                    option, the Customer agrees to:
                    <div className="pl-4">
                      <p className="pt-2">
                        i. Permit Duplo to negotiate and accept on your behalf, the terms upon which the Facility is granted by the Liquidity Partner.
                      </p>
                      <p className="pt-2">
                        ii. Execute all necessary Agreements with the Liquidity Partner.
                      </p>
                      <p className="pt-2">
                        iii. Always  credit  its  Repayment  Account  before  the  Facility  due  date  to  promptly  repay  the  Facility obtained from the Liquidity Partner. 
                      </p>
                      <p className="pt-2">
                        iv. Ensure repayment of Facility to the Financier through the Customer’s Repayment Account within the agreed timeline.
                      </p>
                      <p className="pt-2">
                        v. Agrees to pay in full the accurate facility amount applicable within the repayment period.
                      </p>
                      <p className="pt-2">
                        vi. Maintain  discretion  with  the  use  of  the  Service  and  comply  with  all  financing  criteria  as  may  be stipulated by the Liquidity Partner.
                      </p>
                      <p className="pt-2">
                        vii. Confirm delivery of goods supplied by the Merchant, where applicable
                      </p>
                      <p className="pt-2">
                        viii. Ensure compliance with all stipulated terms of the Facility provided by the Liquidity Partner(s).
                      </p>
                      <p className="pt-2">
                        ix. Indemnify Duplo and the Liquidity Partner against any inaccurate information and loss of funds that may occur as a result of the information provided to Duplo and the Liquidity Partner.
                      </p>
                      <p className="pt-2">
                        x. Be bound by the terms of those Agreements executed with the Duplo and the Liquidity Partner.
                      </p>
                      <p className="pt-2">
                        xi. Waive  all  claims  in  respect  of  the  use  of  its  personal  data  by  the  Liquidity  Partner  where  the Customer is in default of repayment of the facility granted by the Liquidity Partner.
                      </p>
                      <p className="pt-2">
                        xii. Be solely responsible for any liability arising out of the Customer’s negligence or mistake in relation to the Customer’s dealings with the Merchants and the Liquidity Partner(s).
                      </p>
                    </div>
                  </li>
                  <li>
                    In addition to the above Obligations, the Customer further
                    agrees that in relation to the use of the Platform, it shall
                    not:
                    <div className="pl-4">
                      <p className="pt-2">
                        i. interfere with or disrupt the Platform or servers or
                        networks connected to the Platform, or disobey any
                        requirements, procedures, policies, or regulations of
                        networks connected to the Platform.
                      </p>
                      <p className="pt-2">
                        ii. disrupt the normal flow of or otherwise act in a
                        manner that negatively affects other Customer’s ability
                        to engage in real-time exchanges;
                      </p>
                      <p className="pt-2">
                        iii. upload, transmit or otherwise make available on the
                        Site, any material that contains software viruses or any
                        other computer code, files, or programs designed to
                        interrupt, destroy or limit the functionality of
                        Platform.
                      </p>
                    </div>
                  </li>
                </ol>
              </li>
              <li>
                REPRESENTATION AND WARRANTIES
                <div className="pl-4">
                  <p className="pt-3">
                    <b>You</b> represent and warrant to Duplo that:
                  </p>
                  <p className="pt-2">
                    i. you have full power and authority to enter into, execute,
                    deliver and perform this Agreement;
                  </p>
                  <p className="pt-2">
                    ii. you are duly registered under the laws of the Federal
                    Republic of Nigeria or any state, region or country of your
                    organisation and are duly authorised to do business in all
                    other states, regions or countries in which your business
                    operates.
                  </p>
                </div>
                <div className="pl-4">
                  <p className="pt-3">
                    <b>Duplo</b> represents and warrants that:
                  </p>
                  <p className="pt-2">
                    i. It shall provide the Customer with access to a secure
                    transaction dashboard to view logs of the Customer’s
                    transactions on a real-time basis.
                  </p>
                  <p className="pt-2">
                    ii. It shall ensure that the quality of the Services is
                    consistent with Industry Standards, and the applicable local
                    and international laws and regulations; and Comply with the
                    provisions of the Nigerian Information Technology
                    Development Act and the Nigerian Data Protection Regulations
                    2019 or any amendments thereof, in the processing, storage
                    and all aspects of the data and information collected from
                    users purchasing from the Retailer and using the
                    Representative’s products and payment platform
                  </p>

                  <p className="pt-2">
                    iii. It shall implement and maintain security systems for
                    the transmission of Transaction Data consisting of
                    encryption “firewall” technologies and compliance with the
                    minimum requirement of the PCI DSS.
                  </p>
                </div>
              </li>
              <li>
                INTELLECTUAL PROPERTY
                <ol className={`${styles.ol_reset} pl-3`}>
                  <li>
                    <b>Ownership</b>; License: Duplo owns all rights, titles,
                    and interests in and to the services rendered by Duplo
                    including all related intellectual property rights. Subject
                    to your compliance with these Terms, you are granted a
                    limited, non-exclusive, non-transferable, non-sub
                    licensable, revocable right to use the Platform for your
                    internal business purposes only, and for no other purpose.
                    All of our rights not expressly granted by us to you
                    pursuant to these Terms are retained by Us.
                  </li>
                  <li>
                    <b>Maintenance and Support:</b> You acknowledge that while
                    Duplo may, at its sole discretion, provide maintenance and
                    support for the Site from time to time, the Company shall
                    have no specific obligation whatsoever to furnish such
                    services to you.
                  </li>
                  <li>
                    <b>Updates/Upgrades:</b> We may launch new updates/upgrades
                    for the Site to enhance the functionality of the Site and
                    ensure Your access to certain features or functionality.
                  </li>
                </ol>
              </li>
              <li>
                PUBLICITY <br />
                <br />
                <div className="pl-3">
                  You hereby grant Duplo permissions to use your name and logo
                  in our marketing materials including, but not limited to use
                  on our website, in customer listings, in interviews and in
                  press releases. Such Publicity does not imply an endorsement
                  for your products and services.
                </div>
              </li>
              <li>
                OUR FEES & PRICING SCHEDULE <br />
                <br />
                <div className="pl-3">
                  Duplo will provide the Services to you at the rates and for
                  the fees (“Fees”) described above.
                </div>
              </li>
              <li>
                KYC OBLIGATIONS <br />
                <br />
                <div className="pl-3">
                  You agree that you shall provide all necessary KYC documents
                  required by Duplo as a precondition to the use of the
                  Platform. Where there is a change in any personal information
                  supplied, you undertake to immediately notify Duplo of this
                  change and update you KYC details.
                </div>
              </li>
              <li>
                FRAUDULENT TRANSACTIONS <br />
                <br />
                <div className="pl-3">
                  Please keep in mind that, you are liable for all losses that
                  we may incur or transactions initiated on your Duplo Account
                  when your account credential is lost or stolen. Duplo does not
                  and will not insure you against losses caused by fraud under
                  any circumstances. For example, if someone uses your account
                  to order goods on our BNPL Platform, you will be responsible
                  to fully pay for the value of such product even if you are
                  unable to recover the products fraudulently purchased.
                  <br />
                  <br /> You understand that Duplo does not participate in
                  trading and lending activities with the Merchants and the
                  Liquidity Partner respectively, therefore Duplo does not
                  guarantee that it will assist you in recovery of goods or
                  funds where your Duplo Account has been compromised. Duplo
                  will however in its absolute discretion use its reasonable
                  commercial endeavor to assist in the recovery of such goods or
                  funds.
                </div>
              </li>
              <li>
                SETTLEMENTS
                <ol className={`${styles.ol_reset} pl-3`}>
                  <li>
                    Subject to the terms of this Agreement, Duplo will create a
                    Transaction Account for you though which you will be
                    required to settle the Merchants and the Liquidity
                    Partner(s). Where you are using Duplo’s BNPL and where the
                    amount in your Transaction Account on the due date of
                    repayment is not sufficient to cover the Facility advanced
                    by the Liquidity Partner, the Liquidity Partner shall be at
                    Liberty to commence recovery proceedings against you in line
                    with the provisions of your Facility Agreement.
                  </li>
                  <li>
                    We will provide transaction history in your Duplo Dashboard,
                    you are however solely responsible for compiling and
                    retaining permanent records of all transactions and other
                    data associated with your Duplo Account as may be required
                    for your business. Duplo is not responsible for maintaining
                    Transaction History or other records in a manner consistent
                    with your record retention obligations.
                  </li>
                </ol>
              </li>
              <li>
                NOTIFICATION OF ERRORS
                <ol className={`${styles.ol_reset} pl-3`}>
                  <li>
                    You agree to notify us immediately any error is detected on
                    the Platform. We will investigate and rectify the errors
                    where verified. In the event that we notice any errors, we
                    will also investigate and rectify such errors.
                  </li>
                  <li>
                    Where we owe you money as a result of such errors, we will
                    credit the amount to your Transaction Account. Where you owe
                    us due to processing error, such amount will be debited from
                    your Transaction Account and where your Transaction Account
                    is not funded for such debit, it will continue to read
                    negative until you fund your Transaction Account. Duplo may
                    notwithstanding the foregoing explore other means of
                    recovering Duplo’s funds being held by you due to
                    transaction error.
                  </li>
                  <li>
                    If a transaction is erroneously processed through your Duplo
                    Account on the Platform, you are required to immediately
                    report this to us. We will investigate any such reports and
                    attempt to rectify the errors by crediting or debiting your
                    Duplo Account as appropriate.
                  </li>
                  <li>
                    Failure to notify us within 45 (forty-five) days of the
                    occurrence of an error will be deemed a waiver of your
                    rights to amounts that are owed to you due to an error. Our
                    failure to detect transaction error in time shall not
                    operate as a waiver of our right to proceed to recover what
                    you owe Duplo.
                  </li>
                </ol>
              </li>
              <li>
                SET-OFF
                <ol className={`${styles.ol_reset} pl-3`}>
                  <li>
                    We may, without notice, set off any debts or liabilities due
                    from you to us under this Agreement against any debts or
                    liabilities owed by us to you, regardless of the place of
                    payment or currency of either obligation. If the obligations
                    are in different currencies, we may convert either
                    obligation at a market rate of exchange in its usual course
                    of business for the purpose of the set-off.
                  </li>
                  <li>
                    If we have reasonable suspicion that a transaction may be
                    fraudulent or involve other criminal activity, we may
                    suspend the processing of that transaction and any
                    connected, transaction, or withhold settlement until the
                    satisfactory completion of any investigation. The Customer
                    shall not be entitled to any interest or other compensation
                    whatsoever in respect of suspension or delay in receiving
                    Payment.
                  </li>
                  <li>
                    The exercise by Duplo of any of its rights under this clause
                    shall be without prejudice to any other rights or remedies
                    (including but not limited to set-off) to which Duplo is
                    otherwise entitled (by operation of law, contract, or
                    otherwise).
                  </li>
                </ol>
              </li>
              <li>
                TAXES
                <br />
                <br />
                <div className="pl-3">
                  You are responsible for determining any and all taxes
                  assessed, incurred, or required to be collected, paid, or
                  withheld for any reason for your use of the Platform. You also
                  are solely responsible for collecting, withholding, reporting,
                  and remitting correct Taxes to the appropriate tax authority.
                  We are not obligated to, nor will we determine whether Taxes
                  apply, or calculate, collect, report, or remit any Taxes to
                  any tax authority arising from any transaction. Duplo
                  specifically disclaims any liability for Taxes. Duplo may be
                  required by law to report information about you and your use
                  of the Platform to appropriate tax authority.
                </div>
              </li>
              <li>
                TERMINATION
                <ol className={`${styles.ol_reset} pl-3`}>
                  <li>
                    You may terminate this Agreement by closing your Duplo
                    Account.
                  </li>
                  <li>
                    We may suspend your Duplo Account and your access to Duplo
                    services and any funds, or terminate this Agreement, if;
                    <div className="pl-3 py-2">
                      <p>
                        i. you do not comply with any of the provisions of this
                        Agreement;
                      </p>
                      <p>ii. we are required to do so by a Law;</p>
                      <p>
                        iii. we are directed by our partner financial
                        institution; or
                      </p>
                      <p>
                        iv. where a suspicious or fraudulent transaction occurs
                      </p>
                      <p>v. Where it is our best interest to do so.</p>

                      <div className="pt-3">
                        Any termination of this Agreement, (howsoever), shall
                        not affect any provision of this Agreement which
                        expressed to survive or operate in the event of
                        termination of this Agreement and shall be without
                        prejudice to any accrued rights of either Party and
                        shall not affect any provision of this Agreement which
                        is expressly or by necessary implication provided to
                        come into effect on or to continue after such
                        termination.
                      </div>
                    </div>
                  </li>
                </ol>
              </li>
              <li>
                CONFIDENTIAL INFORMATION
                <br />
                <br />
                <div className="pl-3">
                  <p className="pt-3">
                    The parties acknowledge that in the performance of their
                    duties under this Agreement, either party may communicate to
                    the other (or its designees) certain confidential and
                    proprietary information, including without limitation
                    information concerning each party’s services, know how,
                    technology, techniques, or business or marketing plans
                    (collectively, the “Confidential Information”) all of which
                    are confidential and proprietary to, and trade secrets of,
                    the disclosing party. Confidential Information does not
                    include information that: (i) is public knowledge at the
                    time of disclosure by the disclosing party; (ii) becomes
                    public knowledge or known to the receiving party after
                    disclosure by the disclosing party other than by breach of
                    the receiving party’s obligations under this section or by
                    breach of a third party’s confidentiality obligations; (iii)
                    was known by the receiving party prior to disclosure by the
                    disclosing party other than by breach of a third party’s
                    confidentiality obligations; or (iv) is independently
                    developed by the receiving party.
                  </p>
                  <p className="pt-3">
                    As a condition to the receipt of the Confidential
                    Information from the disclosing party, the receiving party
                    shall: (i) not disclose in any manner, directly or
                    indirectly, to any third party any portion of the disclosing
                    party’s Confidential Information; (ii) not use the
                    disclosing party’s Confidential Information in any fashion
                    except to perform its duties under this Agreement or with
                    the disclosing party’s express prior written consent; (iii)
                    disclose the disclosing party’s Confidential Information, in
                    whole or in part, only to employees and agents who need to
                    have access thereto for the receiving party’s internal
                    business purposes; (iv) take all necessary steps to ensure
                    that its employees and agents are informed of and comply
                    with the confidentiality restrictions contained in this
                    Agreement; and (v) take all necessary precautions to protect
                    the confidentiality of the Confidential Information received
                    hereunder and exercise at least the same degree of care in
                    safeguarding the Confidential Information as it would with
                    its own confidential information, and in no event shall
                    apply less than a reasonable standard of care to prevent
                    disclosure.
                  </p>
                </div>
              </li>
              <li>
                PRIVACY POLICY
                <br />
                <br />
                <div className="pl-3">
                  Duplo is committed to managing your Personal Information in
                  line with global industry best practices. You can read our
                  Privacy Policy, to understand how we use your information and
                  the steps we take to protect your information. The Terms of
                  our{" "}
                  <a
                    href="https://www.tryduplo.com/terms-and-conditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>{" "}
                  is hereby incorporated into this Agreement.
                </div>
              </li>
              <li>
                DISCLAIMER
                <br />
                <br />
                <div className="pl-3">
                  EXCEPT FOR THE EXPRESS REPRESENTATIONS AND WARRANTIES STATED
                  IN THIS AGREEMENT, DUPLO MAKES NO ADDITIONAL REPRESENTATION OR
                  WARRANTY OF ANY KIND WHETHER EXPRESS, IMPLIED (EITHER IN FACT
                  OR BY OPERATION OF LAW), OR STATUTORY, AS TO ANY MATTER
                  WHATSOEVER, INCLUDING ALL IMPLIED WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY,
                  ACCURACY, TITLE, AND NON-INFRINGEMENT. DUPLO DOES NOT WARRANT
                  AGAINST INTERFERENCE WITH THE ENJOYMENT OF THE SERVICES OR ANY
                  DELIVERABLES, OR AGAINST INFRINGEMENT, EXCEPT AS EXPRESSLY
                  STATED IN THIS AGREEMENT. DUPLO DOES NOT WARRANT THAT THE
                  SERVICES OR ANY DELIVERABLES ARE ERROR-FREE OR THAT OPERATION
                  OF THE SERVICES OR DELIVERABLES WILL BE SECURE OR
                  UNINTERRUPTED. DUPLO EXERCISES NO CONTROL OVER AND EXPRESSLY
                  DISCLAIMS ANY LIABILITY ARISING OUT OF OR BASED UPON THE
                  CLIENT’S USE OF THE SERVICES AND DELIVERABLES. IF DUPLO IS
                  PROVIDING PAYMENTS SERVICES PURSUANT TO THE AGREEMENT, DUPLO
                  MAKES NO REPRESENTATIONS OR WARRANTIES REGARDING THE AMOUNT OF
                  TIME NEEDED TO COMPLETE PAYMENT REQUESTS THROUGH THE PAYMENTS
                  SERVICES, AS SUCH PAYMENTS ARE DEPENDENT UPON MANY FACTORS
                  OUTSIDE OF DUPLO'S CONTROL, INCLUDING BUT NOT LIMITED TO
                  PAYMENT SETTLEMENT TIMES, PAYMENT HOLDS, BANK INFRASTRUCTURE
                  OUTAGES OR OTHER DELAYS IN THE BANKING SYSTEM AND BANK
                  PAYMENT.
                </div>
              </li>
              <li>
                LIMITATION OF LIABILITY
                <br />
                <br />
                <div className="pl-3">
                  IN NO EVENT WILL DUPLO BE LIABLE FOR (A) ANY INDIRECT,
                  SPECIAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES OR (B)
                  ANY DAMAGES WHATSOEVER IN EXCESS OF THE AMOUNT OF THE
                  TRANSACTION OR TEN THOUSAND UNITED STATES DOLLARS
                  #1,000,000.00 (ONE MILLION NAIRA), WHICHEVER IS LESSER
                  (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOSS OF
                  REVENUES, LOST PROFITS, LOSS OF GOODWILL, LOSS OF USE,
                  BUSINESS INTERRUPTION, OR OTHER INTANGIBLE LOSSES), ARISING
                  OUT OF OR IN CONNECTION WITH DUPLO’S WEBSITE OR SERVICES
                  (INCLUDING, WITHOUT LIMITATION, USE, INABILITY TO USE, OR THE
                  RESULTS OF USE OF DUPLO’S WEBSITES OR SERVICES), WHETHER SUCH
                  DAMAGES ARE BASED ON WARRANTY, CONTRACT, TORT, STATUTE, OR ANY
                  OTHER LEGAL THEORY.
                </div>
              </li>
              <li>
                INDEMNITY
                <br />
                <br />
                <div className="pl-3">
                  Each party (that is you and us) hereby agrees to indemnify,
                  and hold the other party, its officers, directors, employees,
                  agents, licensors, and suppliers, harmless from and against
                  any claims, actions or demands, liabilities and settlements
                  including without limitation, reasonable legal fees, resulting
                  from its violation of its obligations under this Agreement.
                </div>
              </li>
              <li>
                WAIVER
                <br />
                <br />
                <div className="pl-3">
                  If we fail at any time to insist upon strict performance of
                  any of your obligations under any of this Terms, or if we fail
                  to exercise any of the rights or remedies to which we or such
                  user is entitled under these Terms, this shall not constitute
                  a waiver of such rights or remedies and shall not relieve you
                  from compliance with such obligations. A waiver by us of any
                  default shall not constitute a waiver of any subsequent
                  default. No waiver by us of any of these Terms shall be
                  effective unless it is expressly stated to be a waiver and is
                  communicated to you in writing in accordance with these Terms.
                </div>
              </li>
              <li>
                NO PARTNERSHIP OR AGENCY
                <br />
                <br />
                <div className="pl-3">
                  Each party is an independent contractor, and neither party has
                  any authority to act on behalf of the other. Neither Party
                  will represent itself as agent, servant, franchisee, joint
                  venturer, or legal partner of the other. We are entering into
                  these Terms and Conditions as principal and not as agent for
                  any other Affiliate company and claims under these Terms and
                  Conditions may be brought only against us and not against any
                  of our Affiliates.
                </div>
              </li>
              <li>
                SEVERABILITY
                <br />
                <br />
                <div className="pl-3">
                  If any term or condition is found to be invalid, unlawful, or
                  unenforceable to any extent, the parties will endeavor in good
                  faith to agree to amendments that will preserve, as far as
                  possible, the intentions expressed in this Agreement. If the
                  parties fail to agree on an amendment, the invalid term,
                  condition, or provision will be severed from the remaining
                  terms, conditions, and provisions of this Agreement, which
                  will continue to be valid and enforceable to the fullest
                  extent permitted by law.
                </div>
              </li>
              <li>
                UNFAIR COMPETITION
                <br />
                <br />
                <div className="pl-3">
                  You shall not use the Platform, documentation or any other
                  materials provided by us from time to time, including but not
                  being limited to our Intellectual Property Rights or
                  Confidential Information, to build a competitive product,
                  service, or Site or to benchmark with a product or service not
                  provided by us during the duration of this Agreement and for a
                  period of 1 year after its termination.
                </div>
              </li>
              <li>
                CHANGE TO TERMS
                <br />
                <br />
                <div className="pl-3">
                  We reserve the right, at Our sole discretion, to modify or
                  replace these Terms at any time, and will notify you prior to
                  any new terms taking effect. By continuing to access or use
                  Our Service after those revisions become effective, you agree
                  to be bound by the revised terms.
                </div>
              </li>
              <li>
                FORCE MAJEURE
                <ol className={`${styles.ol_reset} pl-3`}>
                  <li>
                    We shall not be liable or responsible for any failure to
                    perform or delay in performance of, any of our obligations
                    under these Terms that are caused by events outside of our
                    reasonable control (“Force Majeure Event”).
                  </li>
                  <li>
                    A Force Majeure Event includes in particular (but without
                    limitation) the following: strikes, lockouts or other
                    industrial action; civil commotion, riot, invasion,
                    terrorist attack or threat of terrorist attack, war (whether
                    declared or not) or threat or preparation for war; fire,
                    explosion, storm, flood, earthquake, subsidence, epidemic,
                    pandemic or other natural disaster or Act of God; nuclear,
                    chemical or biological contamination or sonic boom; the
                    impossibility of the use of public or private
                    telecommunications networks; the acts, decrees, change in
                    legislation, regulations or general restrictions of any
                    government; the non-delivery or late delivery of products or
                    Service to us by third parties; or any other event beyond a
                    Party’s reasonable control.
                  </li>
                  <li>
                    Our performance under these Terms and Conditions is deemed
                    to be suspended for the period that the Force Majeure Event
                    continues and we will have an extension of time for
                    performance for the duration of that period.
                  </li>
                </ol>
              </li>
              <li>
                GOVERNING LAW
                <br />
                <br />
                <div className="pl-3">
                  These Agreement shall be interpreted and governed by the laws
                  currently in force in the Federal Republic of Nigeria.
                </div>
              </li>
              <li>
                LEGAL DISPUTES
                <br />
                <br />
                <div className="pl-3">
                  We shall make an effort to settle all disputes amicably by
                  Authorised Representatives of the Parties. Any dispute arising
                  out of this Agreement which cannot be settled, by mutual
                  agreement/negotiation within 1 (one) month shall be, shall be
                  referred to and finally resolved by Arbitration under the LCIA
                  Rules, which Rules are deemed to be incorporated by reference
                  into this clause.
                  <br /> <br />
                  The number of arbitrators shall be one. The seat, or legal
                  place, of Arbitration shall be Lagos, Nigeria. The language to
                  be used in the arbitral proceedings shall be the English
                  Language. The governing law of this arbitration agreement
                  shall be the substantive law of the Federal Republic of
                  Nigeria.
                </div>
              </li>
            </ol>
          </div>
        </div>
        <div className="flex justify-center mt-[15px]">
          <button
            className="bg-[#2DB49E] rounded-[5px] font-semibold text-[14px] leading-[16px] text-[#fff] py-[13px] px-[44px]"
            onClick={close}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default PrivacyAndTermsModal;

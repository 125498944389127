import React from "react";

function CheckoutFooter() {
  return (
    <div>
      <div className="flex justify-center items-center">
        <div className="font-light text-[18px] leading-[22px] text-[#000000] opacity-60 mr-[8px]">
          powered by
        </div>
        <img
          src="https://d1f8g8i172sxu4.cloudfront.net/images/duploTextLogo.png"
          alt="Duplo"
          width="59px"
          height="25px"
        />
        <img
          className="ml-[4px]"
          src="https://d1f8g8i172sxu4.cloudfront.net/images/duploLogo.png"
          alt="Duplo"
          width="13px"
          height="16px"
        />
      </div>
      <div className="flex items-center justify-center font-light text-[10px] leading-[13px] text-[#5c696e99] mt-[12px]">
        <div className="">&#169;</div>
        <div className="ml-[5px]">2022 Duplo</div>
      </div>
    </div>
  );
}

export default CheckoutFooter;

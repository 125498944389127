import { useState } from "react";
import styles from "./LoanHistory.module.scss";
import { ReactComponent as CopyIcon } from "../svg/copyIcon.svg";
import { ReactComponent as ModalCloseIcon } from "../svg/modalCloseIcon.svg";
import { CurrencyFormat } from "../../Utility/CurrencyFormat";
function ConfirmRepaymentModal({
  shown,
  close,
  virtualAcc,
  amount,
  toggleRepaymentSuccessModal,
}: any) {
  const [copiedAlert, setCopiedAlert] = useState(false);
  const [amountCopiedAlert, setAmountCopiedAlert] = useState(false);

  const copyAccountNumber = async () => {
    await navigator.clipboard.writeText(virtualAcc?.account_number);
    setCopiedAlert(true);
    setTimeout(() => {
      setCopiedAlert(false);
    }, 3000);
  };

  const copyRepaymentAmount = async () => {
    await navigator.clipboard.writeText( amount ? amount.toFixed(2) : 0);
    setAmountCopiedAlert(true);
    setTimeout(() => {
      setAmountCopiedAlert(false);
    }, 3000);
  };

  return shown ? (
    <div
      className={`${styles.modalBackdrop}`}
      onClick={() => {
        close();
      }}
    >
      <div
        className={`${styles.modalContent}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <div className="flex items-center justify-between bg-[#F9F9F9] w-full rounded-t-[10px] py-[30px] px-[20px]">
            <div className="font-semibold text-[18px] leading-[14px] text-[#162930]">
              Make Repayment
            </div>
            <button className="text-[#000]" onClick={close}>
              <ModalCloseIcon />
            </button>
          </div>
          <div className="mt-[30px] px-[35px] font-normal text-[14px] leading-[18px] text-[#192850]">
            Pay your loan with this account details.
          </div>
          <div className="my-[30px] mx-[35px] bg-[#F6F6F6] rounded-[5px]">
            <div className="grid grid-cols-2 gap-9 mx-[30px]  pt-[25px]">
              <div>
                <div className="font-normal text-[14px] leading-[18px] text-[#5C696E]">
                  Repayment Amount
                </div>
                <div className="flex items-center mt-[14px]">
                  <div className="text-[14px] leading-[18px] text-[#003F34]  font-[700]">
                    {CurrencyFormat(amount)}
                  </div>
                  <div className="flex items-center ml-[10px] cursor-pointer h-[25px]">
                    {amountCopiedAlert ? (
                      <div className="bg-[#2DB49E] text-[12px] px-[5px] rounded text-[#fff]">
                        Copied
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          copyRepaymentAmount();
                        }}
                      >
                        {" "}
                        <CopyIcon />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <div className="font-normal text-[14px] leading-[18px] text-[#5C696E]">
                  Account Number
                </div>
                <div className="flex items-center mt-[14px]">
                  <div className="font-[700] text-[14px] leading-[18px] text-[#003F34]">
                    {virtualAcc?.account_number}
                  </div>
                  <div className="flex items-center ml-[10px] cursor-pointer h-[25px]">
                    {copiedAlert ? (
                      <div className="bg-[#2DB49E] text-[12px] px-[5px] rounded text-[#fff]">
                        Copied
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          copyAccountNumber();
                        }}
                      >
                        {" "}
                        <CopyIcon />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-9 mx-[30px] pt-[30px] pb-8">
              <div>
                <div className="font-normal text-[14px] leading-[18px] text-[#5C696E]">
                  Account Name
                </div>
                <div className="flex items-center mt-[14px]">
                  <div className="text-[14px] leading-[18px] text-[#003F34] font-[700]">
                    {virtualAcc?.account_name}
                  </div>
                </div>
              </div>

              <div>
                <div className="font-normal text-[14px] leading-[18px] text-[#5C696E]">
                  Bank Name
                </div>
                <div className="flex items-center mt-[14px]">
                  <div className="font-[700] text-[14px] leading-[18px] text-[#003F34]">
                    {virtualAcc?.bank_name}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#F9F9F9] rounded-b-[10px] pt-[18px] pb-[46px] flex justify-center">
            <button
              className="text-[#FFFFFF] text-[14px] leading-[14px] font-normal bg-[#2DB49E] py-[13px] px-[44px] rounded-[5px]"
              onClick={() => {
                toggleRepaymentSuccessModal(true);
                close();
              }}
            >
              I have made payment
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default ConfirmRepaymentModal;

import { ReactComponent as Logo } from "../svg/duploWebLogo.svg";
import styles from "./WebBackground.module.scss";

function WebBackground() {
  return (
    <>
      <div className="flex justify-center item-center">
        <div className={`${styles.backgroundBox}`}>
          <span className={`grid place-items-center`}>
            <Logo className={`${styles.logo}`}/>
          </span>
        </div>
      </div>
    </>
  );
}

export default WebBackground;

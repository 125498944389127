import { useState } from 'react'
import styles from './Dropdown.module.scss'
import Select, { components } from 'react-select'
import { NoSearchDropdownStyles } from '../../Utility/NoSearchDropdownStyles'
import { ReactComponent as ModalCloseIcon } from '../svg/modalCloseIcon.svg'

const AnimatedDropdown = ({
  setDropDownValues,
  dropdownOption,
  type,
  selectedValue,
  heading
}: any) => {
  const [isOpen, setIsOpen] = useState(false)
  const Option = (props: any) => {
    const hasBorder = props.options[props?.options?.length-1].label === props?.data?.label ? false : true ;   
    return (
      <components.Option {...props}>
        <div className="px-[10px] py-[27px]">
          <div className="text-[14px] leading-[16px]">
            {props?.data?.label}
          </div>
          <div className='text-[12px] leading-[14px] font-light mt-[5px]'>{props?.data?.subLabel}</div>
        </div>
        <div className={`${hasBorder ? 'border-b border-[#94A2AB66]' : ''}`}></div>
      </components.Option>
    )
  }

  return (
    <>
      <div
        className={`w-full mt-[10px] h-[40px] border-[1px] relative text-start flex items-center px-3 rounded-[4px] bg-[#fff] cursor-pointer
        ${isOpen ? 'border-[#2DB49E]' : 'border-[#DCDDE6]'}`}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <div className="capitalize">{selectedValue}</div>
        <div className={`${isOpen ? 'rotate-180' : 'rotate-0'} ml-auto`}>
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.75 1.5L5 5.25L1.25 1.5"
              stroke={isOpen ? '#2DB49E' : '#162930'}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="md:relative z-10">
        {isOpen && (
          <div>
            <div
              className={`fixed w-screen h-screen top-0 bottom-0 left-0 right-0`}
              onClick={() => {
                setIsOpen(false)
              }}
            ></div>
            <div
              className={`w-screen md:w-full mt-[10px] ${styles.dropdownModal}`}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <div className="py-[25px] bg-[#F9F9F9] px-[20px] flex items-center justify-between block md:hidden">
                <div className="font-semibold text-[18px] leading-[18px] text-[#162930]">
                  {heading}
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setIsOpen(false)
                  }}
                >
                  <ModalCloseIcon />
                </div>
              </div>
              <Select
                className="cursor-pointer"
                options={dropdownOption}
                components={{ Option, DropdownIndicator: null }}
                styles={NoSearchDropdownStyles}
                menuIsOpen
                onChange={(e) => {
                  setDropDownValues(e, type)
                  setIsOpen(false)
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default AnimatedDropdown

import { api } from "./AuthService";

export const KycInfoSubmit = async (payload: any) => {
  try {
    const resData = await api.post("/kyc", payload);
    return resData;
  } catch (error: any) {
    return error.response;
  }
};

export const KycInfoSubmitPatch = async (payload: any) => {
  try {
    const finalPayload = getFinalPayload(payload);
    const resData = await api.patch("/kyc", finalPayload);
    return resData;
  } catch (error: any) {
    return error.response;
  }
};

const getFinalPayload = (payload: any) => {
  const urlsArr = [
    "personal_photo_url",
    "identity_document_url",
    "utility_bill_document_url",
    "certificate_of_registration_document_url",
    "cac_document_url",
  ];
  let finalPayload = Object.assign({}, payload);
  for (let cnt = 0; cnt < urlsArr?.length; cnt++) {
    const url = payload[urlsArr[cnt]];
    if (url) {
      const modifiedUrl = getModifiedUrl(url);
      finalPayload[urlsArr[cnt]] = modifiedUrl;
    }
  }
  return finalPayload;
};

const getModifiedUrl = (url: string) => {
  var modifiedUrl = url.split("?")[0];
  return modifiedUrl;
};

import { useLocation } from "react-router";
import LinkExpired from "./LinkExpired";
import NotEligibleError from "./NotEligibleError";

function PaymentError(props: any) {
const {state} = useLocation();
  return (
    <>
      {state?.CHECKOUT_ERROR ? (<LinkExpired />):  <NotEligibleError />}
    </>
  );
}

export default PaymentError;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styles from "./Kyc.module.scss";
import { ReactComponent as GreenLeftArrow } from "../svg/greenLeftArrow.svg";
import {
  validateOnlineLink,
  validateMaritalStatus,
  validateAddress,
  validateLandmark,
  validateNIN,
  validateLga,
} from "../../Utility/ValidationsWithJoi";
import { KycInfoSubmitPatch } from "../../Service/KycInfoSubmit";
import SideBar from "../SideBar/SideBar";
import { useUserContext } from "../../Context/UserContext";
import { ImageUploader } from "../ImageUploader";
import { IdCardOptions, countries } from "../../Utility/DropdownOptions";
import AnimatedDropdown from "./AnimatedDropdown";
import SearchableDropdown from "./SearchableDropdown";
import { ICountries } from "../../interface/profile";

function PersonalInfoPgTwo() {
  const { kycDetails, setKycDetails } = useUserContext();
  const [localGovernments, setLocalGovernments] = useState<string[]>([]);
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);

  const validateOptions = {
    abortEarly: false,
    converse: true,
    stripUnknown: true,
  };
  const rejectedReason = kycDetails?.rejected_reason;
  const [errors, setErrors] = useState({
    onlineLink: rejectedReason?.online_link || "",
    idCardType: rejectedReason?.identity_card_type || "",
    generalErr: "",
    identityDocUrl: rejectedReason?.identity_document_url || "",
    nationality: rejectedReason?.nationality || "",
    nin: rejectedReason?.nin || "",
    address: rejectedReason?.home_address || "",
    landmark: rejectedReason?.landmark || "",
    state: rejectedReason?.state || "",
    lga: rejectedReason?.lga || "",
  });

  const [inputValues, setInputValues] = useState({
    onlineLink: kycDetails?.online_link || "",
    idCardType: kycDetails?.identity_card_type || "",
    kyc_identity_document: kycDetails?.identity_document_url || "",
    nin: kycDetails?.nin || "",
    nationality: kycDetails?.nationality || "",
    landmark: kycDetails?.landmark || "",
    address: kycDetails?.home_address || "",
    state: kycDetails?.state || "",
    lga: kycDetails?.lga || "",
  });

  const handleInput = (e: { target: { name: string; value: any } }) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };
  const setS3Url = (key: string, value: string) => {
    setInputValues({ ...inputValues, [key]: value });
  };
  const validationField = (schema: any, value: any, field: any) => {
    const err = JSON.parse(JSON.stringify(errors));
    const res = schema.validate(value, validateOptions);
    let errorsList: any = {};
    if (res.error) {
      res.error.details.forEach((error: any) => {
        errorsList[field] = error.message;
      });
      setErrors({
        ...errors,
        ...errorsList,
      });
    } else {
      delete err[field];
      setErrors(err);
    }
  };

  const validateInput = (e: { target: { name: string; value: any } }) => {
    switch (e.target.name) {
      case "onlineLink":
        validationField(validateOnlineLink, e.target.value, "onlineLink");
        break;
      case "maritalStatus":
        validationField(validateMaritalStatus, e.target.value, "maritalStatus");
        break;
      case "nin":
        validationField(validateNIN, e.target.value, "nin");
        break;
      case "address":
        validationField(validateAddress, e.target.value, "address");
        break;
      case "lga":
        validationField(validateLga, e.target.value, "lga");
        break;
      case "landmark":
        validationField(validateLandmark, e.target.value, "landmark");
        break;
    }
  };

  const handleNationalityFormatting = () => {
    const array: ICountries[] = countries;
    const filteredData: { value: string; label: string }[] = [];
    array.forEach(({ country }) =>
      filteredData.push({
        label: country,
        value: country,
      })
    );
    return filteredData;
  };

  const handleStateFormatting = () => {
    const filteredData: { label: string; value: string }[] = [];
    localGovernments.forEach((el) =>
      filteredData.push({
        label: el,
        value: el,
      })
    );
    return filteredData;
  };

  const formSubmit = async (e: any) => {
    e.preventDefault();
    const payload = {
      online_link: inputValues?.onlineLink,
      identity_card_type: inputValues?.idCardType,
      identity_document_url: inputValues?.kyc_identity_document,
      nin: inputValues?.nin,
      nationality: inputValues?.nationality,
      home_address: inputValues?.address,
      landmark: inputValues?.landmark,
      state: inputValues?.state,
      lga: inputValues?.lga,
    };

    const finalPayload = {};

    if (
      kycDetails.business_status === "REGISTERED" &&
      kycDetails.business_type === "Limited Liability Company"
    ) {
      delete kycDetails.bn_number;
      delete kycDetails.certificate_of_registration_document_url;
    }

    if (
      kycDetails.business_status === "REGISTERED" &&
      kycDetails.business_type === "Sole Proprietorship"
    ) {
      delete kycDetails.cac_document_url;
      delete kycDetails.business_rc_number;
    }

    if (
      kycDetails.business_status === "UNREGISTERED" ||
      !kycDetails.business_status
    ) {
      delete kycDetails.business_type;
      delete kycDetails.bn_number;
      delete kycDetails.certificate_of_registration_document_url;
      delete kycDetails.business_type;
      delete kycDetails.business_rc_number;
      delete kycDetails.cac_document_url;
    }

    Object.assign(finalPayload, kycDetails, payload);
    const responseData = await KycInfoSubmitPatch(finalPayload);
    if (responseData.status === 200) {
      const resObj = responseData?.data?.data;
      const kycObj = resObj?.kyc;
      setKycDetails(kycObj);
      navigate("/kyc");
    } else {
      const errData = responseData?.data?.errors;

      let generalErrorMsg = "";
      if (Object.keys(errData)?.length === 0) {
        generalErrorMsg = responseData?.data?.message;
      }
      if (errData?.identity_document_url) {
        setShowError(true);
      }

      setErrors({
        onlineLink: errData?.online_link,
        state: errData?.state,
        lga: errData?.lga,
        idCardType: errData?.identity_card_type,
        generalErr: generalErrorMsg,
        identityDocUrl: errData?.identity_document_url,
        nationality: errData?.nationality,
        nin: errData?.nin,
        address: errData?.home_address,
        landmark: errData?.landmark,
      });
    }
  };

  const setDropDownValues = (e: any, name: string) => {
    handleStateFiltering(name, e.value);
    if (name === "state") {
      inputValues.lga = "";
    }

    if (name === "nationality") {
      inputValues.state = "";
      inputValues.lga = "";
    }

    setInputValues({
      ...inputValues,
      [name]: e.value,
    });
    errors[name] = "";
  };

  const handleStateFiltering = (
    name: string,
    value: string,
    dontClearState?: boolean
  ) => {
    if (name === "nationality") {
      if (dontClearState) {
        setInputValues({ ...inputValues });
      } else {
        setInputValues({ ...inputValues, state: "" });
      }
      const array: ICountries[] = countries;
      array.filter(
        ({ country, states }) =>
          country === value && setLocalGovernments(states)
      );
    }
  };

  useEffect(() => {
    if (inputValues.nationality) {
      if (inputValues.nationality === "Nigerian") {
        handleStateFiltering("nationality", "Nigeria", true);
      } else {
        handleStateFiltering("nationality", inputValues.nationality, true);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="md:flex md:items-center md:justify-center relative">
        <div className="hidden md:block z-50">
          <SideBar />
        </div>
        <div
          className={`w-full md:w-4/6 md:my-[50px] md:rounded-[20px] md:ml-[-100px] md:border-[#2db49e80] md:border-[1px] overflow-y-auto ${styles.pageHeight}`}
        >
          <div className="container py-[20px] md:py-[30px] md:pl-[165px] md:pr-[40px] overflow-y-auto md:min-h-[690px] relative z-10 bg-[#2db49e1a] md:bg-transparent">
            <div className="flex justify-between items-center mb-[40px]">
              <div
                className="flex items-center cursor-pointer"
                onClick={() => {
                  navigate("/kyc/personal-information-1");
                }}
              >
                <div>
                  <GreenLeftArrow />
                </div>
                <div className="ml-[20px] font-semibold text-[20px] leading-[25px] text-[#192850]">
                  Personal Information
                </div>
              </div>
              <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                2 of 2
              </div>
            </div>
            <form onSubmit={(e) => formSubmit(e)}>
              <div className="md:grid md:grid-cols-2 md:gap-[20px]">
                <div>
                  <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px]">
                    Nationality
                  </div>
                  <SearchableDropdown
                    setDropDownValues={setDropDownValues}
                    type={"nationality"}
                    selectedValue={inputValues?.nationality}
                    dropdownOptions={handleNationalityFormatting()}
                    heading={"Select your Nationality"}
                    disableField={false}
                  />
                  <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                    {errors.nationality}
                  </div>
                  <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                    State
                  </div>
                  <SearchableDropdown
                    setDropDownValues={setDropDownValues}
                    type={"state"}
                    selectedValue={inputValues?.state}
                    dropdownOptions={handleStateFormatting()}
                    heading={"Select your State"}
                    disableField={false}
                  />
                  <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                    {errors?.state}
                  </div>

                  <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px]">
                    LGA
                  </div>
                  <input
                    className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                    id="address"
                    type="text"
                    name="lga"
                    value={inputValues?.lga}
                    autoComplete="off"
                    disabled={inputValues?.state ? false : true}
                    onChange={(e) => handleInput(e)}
                    onBlur={(e) => validateInput(e)}
                  />
                  <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                    {errors.lga}
                  </div>

                  <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px]">
                    Address
                  </div>
                  <input
                    className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                    id="address"
                    type="text"
                    name="address"
                    value={inputValues?.address}
                    autoComplete="off"
                    onChange={(e) => handleInput(e)}
                    onBlur={(e) => validateInput(e)}
                  />
                  <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                    {errors.address}
                  </div>

                  <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px]">
                    Landmark
                  </div>
                  <input
                    className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                    id="landmark"
                    type="text"
                    name="landmark"
                    value={inputValues?.landmark}
                    autoComplete="off"
                    onChange={(e) => handleInput(e)}
                    onBlur={(e) => validateInput(e)}
                  />
                  <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                    {errors.landmark}
                  </div>
                  <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                    NIN
                  </div>
                  <input
                    className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                    id="nin"
                    type="text"
                    name="nin"
                    maxLength={11}
                    value={inputValues?.nin}
                    autoComplete="off"
                    onChange={(e) => handleInput(e)}
                    onBlur={(e) => validateInput(e)}
                  />
                  <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                    {errors.nin}
                  </div>
                </div>
                <div>
                  <div>
                    <div className="font-light text-[14px] leading-[16px] text-[#192850]">
                      Online link {`(Facebook, Twitter, Website etc)`}
                    </div>
                    <input
                      className="border rounded w-full px-3 h-[40px] leading-tight focus:outline-none mt-[10px]"
                      id="onlineLink"
                      type="text"
                      name="onlineLink"
                      value={inputValues?.onlineLink}
                      autoComplete="off"
                      onChange={(e) => handleInput(e)}
                      onBlur={(e) => validateInput(e)}
                    />
                    <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                      {errors.onlineLink}
                    </div>
                  </div>
                  <div>
                    <div className="font-light text-[14px] leading-[16px] text-[#192850] mt-[5px]">
                      ID Card Type
                    </div>

                    <AnimatedDropdown
                      setDropDownValues={setDropDownValues}
                      dropdownOption={IdCardOptions}
                      type={"idCardType"}
                      selectedValue={inputValues?.idCardType}
                      heading={"Select your ID Card Type"}
                    />
                    <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                      {errors.idCardType}
                    </div>
                  </div>
                  <div>
                    <ImageUploader
                      fileTypeIntent={"kyc_identity_document"}
                      setS3Url={setS3Url}
                      setShowError={setShowError}
                    />
                    <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                      {showError ? `${errors?.identityDocUrl}` : ""}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-[20px]">
                <button
                  className="bg-[#2DB49E] rounded-[5px] font-semibold text-[14px] leading-[16px] text-[#fff] w-full md:w-1/2 py-[12px]"
                  type="submit"
                >
                  Complete
                </button>
              </div>
              <div className="text-[16px] font-bold text-[#FF0000] flex justify-center mt-[10px] min-h-[24px]">
                {errors.generalErr}
              </div>
            </form>
          </div>
        </div>
        <div
          className={`absolute bottom-[160px] md:bottom-[105px] right-[10px] md:right-[-10px] ${styles.duploLogoOpaque} z-[-1]`}
        >
          <img
            src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
            alt="DuploLogo"
          />
        </div>
      </div>
    </>
  );
}

export default PersonalInfoPgTwo;

import styles from './ModalStyle.module.scss'
import { ReactComponent as ModalCloseIcon } from '../../svg//modalCloseIcon.svg'
import { DDMMYYWithTime } from '../../../Utility/DateFormats'
import { CurrencyFormat } from '../../../Utility/CurrencyFormat'

const paymentTypeMap = {
  "LOAN_REPAYMENT" : 'Loan repayment',
  "BUSINESS_PAYMENT" :'Business payment'
}

const TransactionDetailsModal = ({ shown, close, transactionsData }: any) => {
  return shown ? (
    <div
      className={`${styles.modalBackdrop}`}
      onClick={() => {
        close()
      }}
    >
      <div
      className={`${transactionsData?.payment_type === 'LOAN_REPAYMENT' ? styles.loanRepaymentContent : styles.buisnessRepaymentContent} ${styles.modalContent}`}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div>
          <div className="flex items-center justify-between bg-[#F9F9F9] w-full rounded-t-[10px] py-[30px] px-[20px]">
            <div className="font-semibold text-[18px] leading-[18px] text-[#162930]">
              Transaction Details
            </div>
            <button onClick={close}>
              <ModalCloseIcon />
            </button>
          </div>
          <div className="mt-[20px] mb-[40px] mx-[35px] bg-[#F6F6F6] rounded-[5px]">
            <div className="border-b border-[#1629300D] p-[30px] flex items-start justify-between">
              <div className="">
                <div className="font-normal text-[14px] leading-[18px] text-[#5C696E]">
                  Amount
                </div>
                <div className="flex items-center mt-[15px]">
                  <div className="font-medium text-[33px] leading-[18px] text-[#003F34]">
                    {CurrencyFormat(transactionsData.amount)}
                  </div>
                </div>
              </div>
              <div className="font-normal text-[14px] leading-[18px] text-[#5C696E]">
                {transactionsData?.payment_date &&
                  DDMMYYWithTime(transactionsData?.payment_date)}
              </div>
            </div>
            <div className="border-b border-[#1629300D]">
              <div className='px-[30px] flex justify-between py-[25px]'>
                <div className="text-start">
                  <div className="font-normal text-[14px] leading-[18px] text-[#5C696E]">
                    Type
                  </div>
                  <div className="flex items-center mt-[10px]">
                    <div className="font-medium text-[14px] leading-[18px] text-[#003F34]">
                      {paymentTypeMap[transactionsData?.payment_type]}
                    </div>
                  </div>
                </div>
                <div className="text-start">
                  <div className="font-normal text-[14px] leading-[18px] text-[#5C696E]">
                    Status
                  </div>
                  <div className="flex items-center mt-[10px]">
                    <div className="font-medium text-[14px] leading-[18px] text-[#003F34]">
                      {transactionsData?.status}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-b border-[#1629300D] p-[30px]">
              <div className="font-normal text-[14px] leading-[18px] text-[#5C696E]">
              {!(transactionsData?.payment_type === 'LOAN_REPAYMENT') ? 'Bank details' : 'Counterparty'}
              </div>
              <div className="flex items-center mt-[10px]">
                {!(transactionsData?.payment_type === 'LOAN_REPAYMENT') ? (
                  <div className="font-medium text-[14px] leading-[18px] text-[#003F34]">
                    <div>
                      Bank Name:&nbsp;{transactionsData?.counterparty?.bank_name}
                    </div>
                    <div>
                      Bank Account Name:&nbsp;
                      {transactionsData?.counterparty?.account_name}
                    </div>
                    <div>
                      Account Number:&nbsp;
                      {transactionsData?.counterparty?.account_number}
                    </div>
                  </div>
                ) : (
                  <div className="font-bold text-[14px] leading-[18px] text-[#003F34]">
                    {transactionsData?.counterparty?.account_name}
                  </div>
                )}
              </div>
            </div>
            {transactionsData?.payment_type === 'LOAN_REPAYMENT' ? (
              <div className="p-[30px]">
                <div className="font-normal text-[14px] leading-[18px] text-[#5C696E]">
                  Description
                </div>
                <div className="flex items-center mt-[10px]">
                  <div className="font-bold text-[14px] leading-[18px] text-[#003F34]">
                    {transactionsData?.description}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="bg-[#F9F9F9] rounded-b-[10px] pt-[18px] pb-[46px] flex justify-center">
            <button
              className="text-[#FFFFFF] text-[14px] leading-[14px] font-normal bg-[#2DB49E] py-[13px] px-[44px] rounded-[5px]"
              onClick={() => {
                close()
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default TransactionDetailsModal

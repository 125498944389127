import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../Service/AuthService";
import Messages from "../../Utility/Messages";
import { validate } from "../../Utility/Validations";

function ForgotPasswordForm() {
  const navigate = useNavigate();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const business_id = urlParams.get("business_id");
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  function handleInput(e: { target: { name: string; value: any } }) {
    setInputValue(e.target.value);
  }
  const validateNumber = async () => {
    setError("");
    api
      .post(`/phone/validate`, {
        phone_number: inputValue,
      })
      .then((response) => {
        const responseData = response?.data?.data;
        const isPhoneNumberExists = responseData?.exists;
        if (isPhoneNumberExists) {
          getOtp();
        } else {
          setError(Messages.PhoneNumberNotPresent);
        }
      })
      .catch((err) => {
        const responseErrorsData = err?.response?.data?.errors;
        setError(responseErrorsData?.phone_number);
      });
  };

  const getOtp = () => {
    api
      .post(`/phone/get-otp`, {
        phone_number: inputValue,
      })
      .then(() => {
        navigate("/verify-otp", {
          state: { phoneNumber: inputValue, isForgotPassword: true },
        });
      })
      .catch((err) => {
        const responseErrorsData = err?.response?.data?.errors;
        setError(responseErrorsData?.phone_number);
      });
  };

  const validateInput = (e: { target: { name: string; value: any } }) => {
    setError(validate(e.target.name, e.target.value));
  };

  const formSubmit = (e: any) => {
    e.preventDefault();
    validateNumber();
  };

  return (
    <div className="w-full md:w-10/12 relative z-10 bg-transparent">
      <div className="pt-[40px] px-[27px]">
        <div className="flex justify-center text-[24px] leading-[30px] font-semibold text-[#103129] mb-[10px] md:mb-[20px]">
          Forgot Password?
        </div>
        <div className="flex justify-center font-normal text-center text-[12px] text-[#192850] mb-[60px]">
          It's alright we all forget things once in a while. Provide your phone
          number and we will send you a OTP to reset your password.
        </div>
        <div className="formSection">
          <form onSubmit={(e) => formSubmit(e)}>
            <div className="mb-[5px] formGrp">
              <div className="text-[14px] text-[#192850] mb-[10px]">
                {" "}
                Phone Number
              </div>
              <input
                className="border rounded w-full py-2 px-3 h-[40px] leading-tight focus:outline-none"
                id="PhoneNumber"
                type="text"
                placeholder="Phone Number"
                name="number"
                autoComplete="off"
                maxLength={11}
                onChange={(e) => handleInput(e)}
                onBlur={(e) => validateInput(e)}
              />
              <div className="text-[12px] text-[#FF0000] min-h-[20px]">
                {error}
              </div>
            </div>

            <div className="my-[30px]">
              <button
                className="w-full rounded-md font-bold text-[14px] text-[#fff] bg-[#2DB49E] py-[12px]"
                type="submit"
              >
                Get OTP
              </button>
            </div>
          </form>
        </div>
        {window.innerWidth < 768 && (
          <div className="flex justify-center text-[14px]">
            <div className="text-[#192850]">Don't have an account?</div>
            <Link
              to={
                business_id ? `/sign-up?business_id=${business_id}` : "/sign-up"
              }
              className="pl-[10px] text-[#2DB49E] underline underline-offset-4"
            >
              Sign up
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default ForgotPasswordForm;

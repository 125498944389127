import WebBackground from "../Background/WebBackground";
import LoggedOutBottomBar from "../BottomBar/LoggedOutBottomBar";
import styles from "../Background/WebBackground.module.scss";
import { ReactComponent as CreateAccount } from "../svg/stopwatch.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../Service/AuthService";

function ProcessingSalesHistory() {
  const navigate = useNavigate();
  const [countDown, setCountDown] = useState<string>();
  const [isTimerDone, setIsTimerDone] = useState<boolean>(false);
  const [isTimerLoading, setIsTimerLoading] = useState<boolean>(true);
  const startTimer = (duration: number) => {
    let timer = duration,
      minutes,
      seconds;
    let time_value: string = "";
    let timerInterval = setInterval(function () {
      minutes = parseInt((timer / 60).toString(), 10);
      seconds = parseInt((timer % 60).toString(), 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      time_value = `${minutes}m:${seconds}s`;
      setCountDown(time_value);
      if (--timer < 0) {
        clearInterval(timerInterval);
        getUserKycStatus();
      }
    }, 1000);

    return time_value;
  };

  const getUserKycStatus = async () => {
    try {
      const response = await api.get(`/kyc`);
      if (response.data.data.kyc.sales_data_availability_status) {
        setIsTimerDone(true);
      } else {
        navigate("/cant-process-sales-history");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    startTimer(60 * 5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsTimerLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="relative">
      <div
        className="md:container md:py-[40px]"
        style={{ minHeight: "calc(100vh - 55px)" }}
      >
        <WebBackground />
        <div
          className={`${styles.formElement} ${styles.inputField} flex justify-center items-center`}
        >
          <div className="md:px-[7rem] px-[2rem] md:mt-0 mt-10">
            <CreateAccount className="mx-auto" />
            <div className="text-center mt-[8px] font-semibold text-[20px] leading-[25px] text-[#103129]">
              Creating your account
            </div>
            <div className="mt-[20px] font-light text-[15px] leading-[19px] text-[#192850] opacity-60 text-center">
              We are gathering your details to create your account. Please check
              again in 5 minutes or when the timer elapses.
            </div>
            {!isTimerLoading && (
              <>
                {!isTimerDone ? (
                  <p className="underline text-[15px] cursor-pointer text-center mt-5 text-[#2db49e]">
                    Login in {countDown}
                  </p>
                ) : (
                  <p
                    className="underline text-[15px] cursor-pointer text-center mt-5 text-[#2db49e]"
                    onClick={() => {
                      navigate("/sign-in");
                    }}
                  >
                    Back to Login
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 md:container py-[14px] w-full">
        <LoggedOutBottomBar />
      </div>
      <div
        className={`absolute bottom-[135px] md:bottom-[110px] right-[-4px] md:right-[-10px] ${styles.duploLogoOpaque}`}
      >
        <img
          src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
          alt="DuploLogo"
        />
      </div>
    </div>
  );
}

export default ProcessingSalesHistory;

import Messages from "./Messages";

export const validate = function (
  name: string,
  value: any,
  password?: any
): string {
  switch (name) {
    case "number":
      if (!value || value.trim() === "") {
        return "Mobile number is Required.";
      } else if (!value.match(/^0\d{10}$/)) {
        return "Please enter a valid mobile number.";
      } else {
        return "";
      }
    case "email":
      if (!value) {
        return "Email is Required";
      } else if (
        !value.match(
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        )
      ) {
        return "Please enter a valid email address.";
      } else {
        return "";
      }
    case "first_name" || "firstName":
      if (!value) {
        return "First name is Required";
      } else if (value.length < 3) {
        return "First name should be at least 3 characters long";
      } else if (value.length > 15) {
        return "First name should not exceed 15 characters";
      } else if (!value.match(/^[A-Za-z]+$/)) {
        return "Please enter a valid first name.";
      } else {
        return "";
      }
    case "last_name" || "lastName":
      if (!value) {
        return "Last name is required";
      } else if (value.length < 3) {
        return "Last name should be at least 3 characters long";
      } else if (value.length > 15) {
        return "Last name should not exceed 15 characters";
      } else if (!value.match(/^[A-Za-z]+$/)) {
        return "Please enter a valid last name.";
      } else {
        return "";
      }
    case "password":
      if (!value) {
        return "Password is Required";
      } else if (value.length < 8) {
        return Messages.PasswordStrenthMessage;
      } else if (!value.match(/[a-z]/g)) {
        return Messages.PasswordStrenthMessage;
      } else if (!value.match(/[A-Z]/g)) {
        return Messages.PasswordStrenthMessage;
      } else if (!value.match(/[0-9]/g)) {
        return Messages.PasswordStrenthMessage;
      } else if (!value.match(/[!@#$%^&*()_\-+={[}\]\\|:;"'<,>.]/)) {
        return Messages.PasswordStrenthMessage;
      } else {
        return "";
      }
    case "cPassword":
      if (!value) {
        return "Confirm Password Required";
      } else if (value !== password) {
        return "Password and confirm password should be same.";
      } else {
        return "";
      }
    default: {
      return "";
    }
  }
};

export const getInitials = (firstName: string, lastName: string) => {
  let initials = "";

  if (firstName) {
    initials = initials + firstName.charAt(0);
  }

  if (lastName) {
    initials = initials + lastName.charAt(0);
  }

  return initials;
};

import { ReactComponent as Logo } from "../svg/duploWebLogo.svg";
import styles from "./Landing.module.scss";

function WebBackgroundLanding() {
  return (
    <>
      <div className="flex justify-center item-center relative">
        <div className={`${styles.backgroundBox}`}>
          <span className={`${styles.backgroundLogo} grid place-items-center`}>
            <Logo className="w-[10px]" />
          </span>
        </div>
      </div>
    </>
  );
}

export default WebBackgroundLanding;

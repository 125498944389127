import { useNavigate } from "react-router";
import WebBackground from "../Background/WebBackground";
import styles from "../Background/WebBackground.module.scss";

function KycPendingModal() {
  const navigate = useNavigate();
  return (
    <>
      <div className="md:container md:my-[60px] my-0">
        <WebBackground />
        <div
          className={`${styles.formElement} ${styles.inputField} flex justify-center item-center`}
        >
          <div className="bg-[#ffffff] relative container rounded-[20px]">
            <div className="absolute top-[30px] right-[30px] font-normal text-[14px] leading-[17px] text-[#192850] cursor-pointer" onClick={() => {navigate('/home')}}>
              Skip
            </div>
            <div className="flex justify-center">
              <div className="mt-[150px]">
                <div className="flex justify-center">
                  <svg
                    width="57"
                    height="75"
                    viewBox="0 0 57 75"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.36903 11.7291L55.436 10.8561C55.5675 10.8535 55.6982 10.8797 55.8205 10.9332C55.9429 10.9868 56.0543 11.0666 56.1484 11.168C56.2426 11.2694 56.3175 11.3904 56.369 11.524C56.4204 11.6577 56.4473 11.8012 56.4481 11.9464C56.4881 19.1784 56.7151 59.4674 56.83 63.5368C56.9561 68.01 58.1145 73.8644 52.0732 74.7955C46.5807 75.6427 13.9076 72.4241 8.01854 71.8333C7.77041 71.8082 7.53984 71.6813 7.37246 71.4776C7.20507 71.274 7.11304 71.0084 7.11458 70.7335L7.38898 12.8201C7.39069 12.533 7.49422 12.258 7.6774 12.0541C7.86059 11.8502 8.10885 11.7335 8.36903 11.7291Z"
                      fill="#D3F6F3"
                    />
                    <path
                      d="M0.000572333 69.899C0.000572333 69.899 -0.125614 74.1253 3.36936 74.6822C6.86433 75.2391 53.4406 74.6623 53.4406 74.6623C53.4406 74.6623 49.4262 73.7091 48.348 69.2993L0.000572333 69.899Z"
                      fill="#1F323A"
                    />
                    <path
                      d="M24.2031 23.7749H52.6015"
                      stroke="#1F8A78"
                      strokeWidth="1.89988"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                    <path
                      d="M24.2031 25.855H42.6853"
                      stroke="#1F8A78"
                      strokeWidth="1.89988"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                    <path
                      d="M12.4896 25.0975L15.4084 27.3186L20.1392 21.876"
                      fill="#3F5C6C"
                    />
                    <path
                      d="M12.4896 25.0975L15.4084 27.3186L20.1392 21.876"
                      stroke="#455A64"
                      strokeWidth="4.74971"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                    />
                    <path
                      d="M16.1889 32.8823H16.1368C14.1071 32.8823 12.4616 34.698 12.4616 36.9377V36.9952C12.4616 39.235 14.1071 41.0506 16.1368 41.0506H16.1889C18.2187 41.0506 19.8641 39.235 19.8641 36.9952V36.9377C19.8641 34.698 18.2187 32.8823 16.1889 32.8823Z"
                      fill="#3F5C6C"
                    />
                    <path
                      d="M29.1775 32.8823H29.1254C27.0957 32.8823 25.4502 34.698 25.4502 36.9377V36.9952C25.4502 39.235 27.0957 41.0506 29.1254 41.0506H29.1775C31.2073 41.0506 32.8527 39.235 32.8527 36.9952V36.9377C32.8527 34.698 31.2073 32.8823 29.1775 32.8823Z"
                      fill="#3F5C6C"
                    />
                    <path
                      d="M16.1889 45.7791H16.1368C14.1071 45.7791 12.4616 47.5947 12.4616 49.8345V49.8919C12.4616 52.1317 14.1071 53.9474 16.1368 53.9474H16.1889C18.2187 53.9474 19.8641 52.1317 19.8641 49.8919V49.8345C19.8641 47.5947 18.2187 45.7791 16.1889 45.7791Z"
                      fill="#3F5C6C"
                    />
                    <path
                      d="M29.1775 45.7791H29.1254C27.0957 45.7791 25.4502 47.5947 25.4502 49.8345V49.8919C25.4502 52.1317 27.0957 53.9474 29.1254 53.9474H29.1775C31.2073 53.9474 32.8527 52.1317 32.8527 49.8919V49.8345C32.8527 47.5947 31.2073 45.7791 29.1775 45.7791Z"
                      fill="#3F5C6C"
                    />
                    <path
                      d="M16.1889 58.6763H16.1368C14.1071 58.6763 12.4616 60.4919 12.4616 62.7317V62.7892C12.4616 65.0289 14.1071 66.8446 16.1368 66.8446H16.1889C18.2187 66.8446 19.8641 65.0289 19.8641 62.7892V62.7317C19.8641 60.4919 18.2187 58.6763 16.1889 58.6763Z"
                      fill="#3F5C6C"
                    />
                    <path
                      d="M29.1775 58.6763H29.1254C27.0957 58.6763 25.4502 60.4919 25.4502 62.7317V62.7892C25.4502 65.0289 27.0957 66.8446 29.1254 66.8446H29.1775C31.2073 66.8446 32.8527 65.0289 32.8527 62.7892V62.7317C32.8527 60.4919 31.2073 58.6763 29.1775 58.6763Z"
                      fill="#3F5C6C"
                    />
                    <path
                      d="M26.7872 5.47205L35.5219 5.28295C36.911 5.25287 38.2541 5.83295 39.2557 6.89558C40.2572 7.9582 40.8352 9.41632 40.8624 10.9492L40.9043 13.3061L21.6941 13.722L21.6521 11.3651C21.6249 9.83221 22.1506 8.3502 23.1136 7.24505C24.0766 6.13989 25.398 5.50213 26.7872 5.47205V5.47205Z"
                      fill="#00C8B8"
                    />
                    <path
                      d="M35.2181 6.13718C35.6315 3.65996 34.1466 1.28205 31.9016 0.825967C29.6567 0.369883 27.5017 2.00833 27.0884 4.48555C26.675 6.96277 28.1599 9.34068 30.4049 9.79676C32.6498 10.2528 34.8048 8.6144 35.2181 6.13718Z"
                      fill="#00C8B8"
                    />
                    <path
                      d="M27.0203 5.40103C27.0203 5.41723 27.0203 5.43271 27.0203 5.44891L35.2807 5.2699C35.2807 5.25369 35.2807 5.23822 35.2807 5.22128C35.2711 4.72604 35.1884 4.23584 35.0357 3.77002L27.2085 3.93945C27.074 4.41206 27.0104 4.90577 27.0203 5.40103Z"
                      fill="#05897F"
                    />
                  </svg>
                </div>
                <div className="font-medium text-[16px] leading-[18px] text-[#000000] mt-[15px] text-center">
                  KYC information is required
                </div>
                <div className="mt-[18px] text-center text-[#5C696E] text-[14px] leading-[22px] font-light">
                  Set up your profile to be able to apply for a loan. The
                  process takes about 2 minutes.
                </div>
                <div className="flex justify-center mt-[24px]">
                  <button className="bg-[#2DB49E] px-[62px] py-[13px] rounded-[5px] text-[#ffffff]" onClick={() => {navigate('/kyc')}}>
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={`absolute bottom-0 right-0 ${styles.duploLogoOpaque}`}>
          <img
            src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
            alt="DuploLogo"
          />
        </div> */}
      </div>
    </>
  );
}

export default KycPendingModal;

import React from "react";
import { useNavigate } from "react-router";

const KycRejectedWithReasonBanner = () => {
  const navigate = useNavigate();
  return (
    <div
      className={`flex items-center kyc-box-shadow bg-[#ffffff] px-[20px] md:px-[50px] py-[20px] md:py-[30px] mt-[25px] rounded-[20px] cursor-pointer relative z-[3]`}
    >
      <div className="pl-[10px]">
        <div className="text-[14px] font-bold text-[#333333]">
          Update your KYC
        </div>
        <div className="font-normal text-[14px] leading-[18px] text-[#333333] mt-[5px]">
          Oops! There seems to be something wrong with your document(s).
          <span
            onClick={() => {
              navigate("/kyc-rejected-with-reason");
            }}
            className="text-[#2DB49E]"
          >
            &nbsp;Click here to see what’s wrong
          </span>
        </div>
      </div>
    </div>
  );
};

export default KycRejectedWithReasonBanner;

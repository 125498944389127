import { useCallback, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { api } from "../../Service/AuthService";
import { ColorStyles } from "../TransactionHistory/PageNumberDropdown";
import Pagination from "../Pagination/Pagination";
import ActiveLoans from "./ActiveLoans";
import LoanHistoryCell from "./LoanHistoryCell";
import NoTransactionHistory from "../TransactionHistory/NoTransactionHistory";
import Loader from "../Loader";

const options = [
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 40, label: 40 },
  { value: 50, label: 50 },
];

interface LoanTransaction {
  id: string;
}

function LoanHistory() {
  const [perPageRecord, setPerPageRecord] = useState<number>(5);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const dataFetchedRef = useRef<boolean>(false);
  const [loanTransactionsData, setLoanTransactionsData] = useState<
    LoanTransaction[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getLoansinfo = async (loanId: string) => {
    try {
      const response = await api.get(`loan/${loanId}`);
      const loanTransactions = response?.data?.data?.loan_transactions;
      setLoanTransactionsData((loans) => [...loans, ...loanTransactions]);
    } catch (error) {
      console.log("Error fetching loan info:", error);
    } finally {
      setIsLoading(true);
    }
  };

  const getLoansInfoCallback = useCallback((loanId: string) => {
    const data = getLoansinfo(loanId);
    return data;
  }, []);

  const getLoansIds = async () => {
    try {
      const responseData = await api.get("all-loans");
      const loans = responseData?.data?.data?.loans;
      for (let cnt = 0; cnt < loans?.length; cnt++) {
        await getLoansInfoCallback(loans?.[cnt]?.id);
      }
      if (loans?.length === 0) {
        setIsLoading(true);
      }
    } catch (error) {
      console.log("Error fetching loan IDs:", error);
    }
  };

  const handlePaginationChange = ({ selected }: { selected: number }) => {
    setPageNumber(selected + 1);
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getLoansIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLoanHistory = () => {
    if (!isLoading) return <Loader />;
    if (loanTransactionsData.length === 0) {
      return <NoTransactionHistory historyKind={"LOAN_HISTORY"} />;
    }
    const start = (pageNumber - 1) * perPageRecord;
    const end = pageNumber * perPageRecord;
    const slicedData = loanTransactionsData.slice(start, end);

    return (
      <>
        <div className="md:grid-cols-3 gap-[100px] border-[1px] border-[#2db49e80] rounded-[5px] font-bold text-[12px] leading-[15px] text-[#68717F] px-[20px] hidden md:grid mb-[10px]">
          <div className="basis-3/12 py-[18px]">DATE</div>
          <div className="basis-3/12 py-[18px]">DESCRIPTION</div>
          <div className="basis-3/12 py-[18px] min-w-[80px]">AMOUNT (₦)</div>
        </div>
        <LoanHistoryCell loanTransactionsData={slicedData} />
        <div className="flex items-center gap-[80px] mt-[20px]">
          <div className="flex items-center">
            <Select
              options={options}
              defaultValue={options.find(
                (option) => option.value === perPageRecord
              )}
              isSearchable={false}
              onChange={(e: any) => {
                setPerPageRecord(e?.value);
                setPageNumber(1);
              }}
              menuPlacement="top"
              styles={ColorStyles}
            />
            <div className="ml-[7px] font-medium text-[10px] leading-[13px] text-[#5c696e99]">
              per page
            </div>
          </div>
          <Pagination
            pageCount={Math.ceil(loanTransactionsData.length / perPageRecord)}
            handlePaginationChange={handlePaginationChange}
          />
        </div>
      </>
    );
  };

  return (
    <div className="">
      <div className="md:py-[40px] md:pl-[165px] md:pr-[40px] overflow-y-auto md:min-h-[690px] relative md:bg-transparent">
        <div className="md:container">
          <ActiveLoans />
        </div>
        <div className="container">
          <div className="mt-[40px] mb-[24px] font-semibold opacity-60 text-[#001812]">
            Loan History
          </div>
          {renderLoanHistory()}
        </div>
      </div>
    </div>
  );
}

export default LoanHistory;

import {
  AxiosDefaults,
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { api } from ".";

const TokenCache = (() => {
  let _cache = {
    accessToken: "",
    refreshToken: "",
    expiresAt: 0,
  };
  class TokenCache {
    getAccessToken() {
      return _cache.accessToken;
    }
    getRefreshToken() {
      return _cache.refreshToken;
    }
    getExpiresAt() {
      return _cache.expiresAt;
    }
    clearTokens() {
      _cache = {
        accessToken: "",
        refreshToken: "",
        expiresAt: 0,
      };
    }
    setTokens(accessToken: string, refreshToken: string, expiresAt: any) {
      _cache = {
        accessToken: accessToken,
        refreshToken: refreshToken,
        expiresAt: expiresAt,
      };
    }
  }
  return new TokenCache();
})();

export function setAuthorizationHeader(
  request: AxiosDefaults | AxiosRequestConfig | any,
  token: string
) {
  request.headers.dpmlekdno = `${token}`;
}

let isRefreshing = false;

function isTokenExpiring() {
  const expiryTime = TokenCache.getExpiresAt() || 0;
  if (expiryTime === 0) {
    return false;
  }
  const expiration = new Date(expiryTime);
  const now = new Date();
  const fiveMinutes = 1000 * 60 * 5;

  if (expiration.getTime() - now.getTime() < fiveMinutes) {
    return true;
  } else {
    return false;
  }
}

function handleRefreshToken() {
  isRefreshing = true;
  api
    .get("/refresh-token", {
      headers: {
        dpmlekdno: TokenCache.getAccessToken(),
        dprefreshauth: TokenCache.getRefreshToken(),
      },
    })
    .then((response) => {
      const responseData = response?.data?.data;
      const authToken = responseData?.auth_token;
      TokenCache.setTokens(
        authToken?.access_token,
        authToken?.refresh_token,
        authToken?.expires_at
      );

      setAuthorizationHeader(api.defaults, authToken);
    })
    .catch((error) => {
      window.location.href = "/sign-in";
    })
    .finally(() => {
      isRefreshing = false;
    });
}

function onRequest(config: AxiosRequestConfig): AxiosRequestConfig {
  const isExpiring = isTokenExpiring();
  if (isExpiring && !isRefreshing) {
    handleRefreshToken();
  }
  const token = TokenCache.getAccessToken();
  token && setAuthorizationHeader(config, token);
  return config;
}

function onRequestError(error: AxiosError): Promise<AxiosError> {
  return Promise.reject(error);
}
const onResponse = (response: AxiosResponse) => {
  if (response.status === 401) {
    TokenCache.clearTokens();
    window.location.href = "/sign-in";
  }
  if (response && response.config && response.status === 200) {
    const requestUrl = response.config.url;

    if (requestUrl === "/phone/reset-password") {
      handleRefreshToken();
    }

    switch (requestUrl) {
      case "/phone/signup":
      case "/refresh-token":
      case "/phone/login":
        const responseData = response && response.data && response.data.data;
        const authToken = responseData && responseData.auth_token;
        const accToken = authToken && authToken.access_token;
        const refToken = authToken && authToken.refresh_token;
        const expAt = authToken && authToken.expires_at;
        TokenCache.setTokens(accToken, refToken, expAt);
        break;
    }
  }
  return response;
};

const onResponseError = (error: AxiosError) => {
  if (error && error.response && error.response.status === 401) {
    TokenCache.clearTokens();
    window.location.href = "/sign-in";
  }
  throw(error);
};

export function setupInterceptors(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}

import LoggedInBottomBar from "../BottomBar/LoggedInBottomBar";
import SideBar from "../SideBar/SideBar";
import styles from "./Notifications.module.scss";
import NotificationTab from "./NotificationTab";

function Notifications() {
  return (
    <div className="md:flex md:items-center md:justify-center relative">
      <div className="hidden md:block z-[1]">
        <SideBar />
      </div>
      <div
        className={`bg-[#F4F9F5] md:bg-transparent w-full md:w-4/6 md:my-[50px] md:rounded-[20px] md:ml-[-100px] md:border-[#2db49e80] md:border-[1px] ${styles.pageHeight}`}
      >
        <NotificationTab />
      </div>
      <div className="md:hidden w-full">
        <LoggedInBottomBar />
      </div>
      <div
        className={`absolute bottom-[160px] md:bottom-[105px] right-[10px] md:right-[-10px] ${styles.duploLogoOpaque} z-[-1]`}
      >
        <img
          src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
          alt="DuploLogo"
        />
      </div>
    </div>
  );
}

export default Notifications;

import WebBackground from "../Background/WebBackground";
import styles from "../Background/WebBackground.module.scss";
import style from "./ResetPassword.module.scss";
import LoggedInBottomBar from "../BottomBar/LoggedInBottomBar";
import SideBar from "../SideBar/SideBar";
import ResetPasswordForm from "./ResetPasswordForm";
import LoggedOutBottomBar from "../BottomBar/LoggedOutBottomBar";

function ResetPassword(props: any) {
  return (
    <>
      {window.innerWidth < 768 ? (
        <div className="relative">
          <div
            className="md:container md:py-[40px]"
            style={{ minHeight: "calc(100vh - 55px)" }}
          >
            <WebBackground />
            <div
              className={`${styles.formElement} ${styles.inputField} flex justify-center item-center`}
            >
              <ResetPasswordForm />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 md:container py-[14px] w-full">
            <LoggedOutBottomBar />
          </div>
          <div
            className={`absolute bottom-[155px] md:bottom-0 right-[10px] ${styles.duploLogoOpaque}`}
          >
            <img
              src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
              alt="DuploLogo"
            />
          </div>
        </div>
      ) : (
        <div className="md:flex md:items-center md:justify-center relative">
          <div className="hidden md:block z-50">
            <SideBar />
          </div>
          <div
            className={`w-full md:w-4/6 md:my-[50px] md:rounded-[20px] md:ml-[-100px] md:border-[#2db49e80] md:border-[1px] ${styles.pageHeight}`}
          >
            <div className="container py-[20px] md:py-[40px] md:pl-[165px] md:pr-[40px] md:mt-[60px] max-w-[952px]">
              <div className={style.formBackground}>
                <ResetPasswordForm />
              </div>
            </div>
          </div>
          <div className="md:hidden w-full">
            <LoggedInBottomBar />
          </div>
          <div
            className={`absolute bottom-[160px] md:bottom-[105px] right-[10px] md:right-[-10px] ${style.duploLogoOpaque}`}
          >
            <img
              src="https://d1f8g8i172sxu4.cloudfront.net/images/DuploOpaqueLogo.png"
              alt="DuploLogo"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default ResetPassword;

import { ReactComponent as DuploLogo } from "../../svg/duploLogo.svg";
import { ReactComponent as BnplLogo } from "../../svg/bnplLogo.svg";
import { ReactComponent as GreenRightArrow } from "../../svg/greenRightArrow.svg";
import LoanOption from "../LoanOptions";
import { useEffect, useState } from "react";
import { api } from "../../../Service/AuthService";
import { useCheckoutContext } from "../../../Context/CheckoutRef";
import NotEligibleError from "../PaymentError/NotEligibleError";
import { useNavigate } from "react-router";
import { CurrencyFormat } from "../../../Utility/CurrencyFormat";
import LafModal from "../../LafModal";
import axios from "axios";
import config from "../../../config";

function PaymentOption() {
  const navigate = useNavigate();
  const { checkoutDetails, checkoutRef } = useCheckoutContext();
  const [creditLimit, setCreditLimit] = useState(0);
  const [eligibilityStatus, setEligibilityStatus] = useState(false);
  const [showLoanOptions, setShowLoanOptions] = useState(false);
  const [modalShown, toggleModal] = useState<Boolean>(false);
  const [isLoading, setIsLoading] = useState<Boolean>(false);

  const getCreditLimit = async () => {
    await api
      .get("/credit-limit")
      .then((response) => {
        const responseData = response?.data?.data;
        const creditLimitAmount = responseData?.credit_limit;
        setCreditLimit(creditLimitAmount?.credit_limit);
        if (creditLimitAmount?.credit_limit < checkoutDetails?.amount) {
          setEligibilityStatus(true);
        } else {
          setShowLoanOptions(true);
        }
      })
      .catch(() => {
        setEligibilityStatus(true);
      });
  };
  const confirmLoan = async () => {
    setIsLoading(true);
    await api
      .post("/confirm-order", {
        checkout_ref: checkoutRef,
      })
      .then((response) => {
        if (response?.data?.success) {
          navigate("/checkout/bnpl/success", { state: response?.data?.data });
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const cancelLoan = async () => {
    const options: any = {
      dpmlekdno: process.env.REACT_APP_DUPLO_KEY,
      "Content-type": "application/json",
    };

    await axios({
      method: "patch",
      url: `${config.API_ENDPOINT}/checkout/cancel/${checkoutRef}`,
      headers: options,
    })
      .then((res) => {
        if (res?.status === 200) {
          navigate("/checkout/bnpl/reject-offer");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onTriggerLafModal = () => {
    toggleModal(true);
  };

  useEffect(() => {
    getCreditLimit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (eligibilityStatus) {
    return <NotEligibleError />;
  } else if (showLoanOptions) {
    return (
      <>
        <div
          className="container md:px-[30px]"
          style={{ minHeight: "calc(100vh - 55px)" }}
        >
          <div className="flex items-start justify-between mt-[20px] md:pb-[20px] md:border-b-[1px] md:border-b-[#0000001a]">
            <div className="flex items-center">
              <span>
                <DuploLogo />
              </span>
              <span className="ml-[9px] text-[20px] leading-[25px] font-medium text-[#5C696E]">
                Duplo
              </span>
            </div>
            <div className="text-right">
              <div className="text-[20px] font-medium leading-[25px] text-[#003F34] text-right">
                {CurrencyFormat(checkoutDetails?.amount)}
              </div>
              <div className="text-end font-light text-[14px] leading-[22px] text-[#5C696E] mt-[6px]">
                {checkoutDetails?.email}
              </div>
            </div>
          </div>

          <div className="mx-[-15px] bg-[#103129] flex md:hidden items-center justify-between px-[15px] py-[17px] mt-[45px]">
            <div className="flex items-center">
              <span>
                <BnplLogo />
              </span>
              <span className="font-normal text-[#fff] text-[14px] leading-[14px] ml-[14px]">
                Buy Now Pay Later
              </span>
            </div>
            <div className="flex items-center">
              <span className="font-normal text-[14px] leading-[22px] text-[#5C696E] mr-[6px]">
                Change payment method
              </span>
              <span>
                <GreenRightArrow />
              </span>
            </div>
          </div>
          {creditLimit ? (
            <>
              <div className="font-light text-[10px] leading-[16px] text-[#5C696E] mt-[40px]">
                Your Credit limit
              </div>
              <div className="font-medium text-[30px] leading-[38px] text-[#003F34] mt-[10px]">
                {CurrencyFormat(creditLimit)}
              </div>
              <div className="font-light text-[12px] leading-[15px] text-[#5C696E] mt-[10px]">
                You are eligible for this loan offer. Please <br /> confirm your
                repayment details below:
              </div>
            </>
          ) : (
            <></>
          )}
          {/* <div className="mt-[48px] bg-[#2db49e14] mx-[-15px] md:mx-0 py-[19px] px-[22px] md:rounded-[10px] md:flex md:items-center md:justify-between">
        <div className={`flex items-center cursor-pointer`}>
          <input type="radio" id="halfYear" name="paymetMethod" />
          <label
            htmlFor="halfYear"
            className={`py-[8px] px-[16px] ml-[12px] ${styles.boxChecked}`}
          >
            <div className="text-[#003F34] font-medium text-[18px] leading-[23px]">
              ₦{amountPayable}
            </div>
            <div className="font-light text-[10px] leading-[16px] text-[#5C696E] mt-[2px]">
              Repay in 6months | ₦7000 monthly
            </div>
          </label>
        </div>
        <div className={`flex items-center mt-[24px] md:mt-0 cursor-pointer`}>
          <input type="radio" id="quarterYear" name="paymetMethod" />
          <label
            className={`py-[8px] px-[16px] ml-[12px] ${styles.boxChecked}`}
            htmlFor="quarterYear"
          >
            <div className="text-[#003F34] font-medium text-[18px] leading-[23px]">
              ₦{amountPayable}
            </div>
            <div className="font-light text-[10px] leading-[16px] text-[#5C696E] mt-[2px]">
              Repay in 3months | ₦14,000 monthly
            </div>
          </label>
        </div>
      </div> */}

          <LoanOption />

          <div className="font-light text-[10px] leading-[16px] text-[#5C696E]">
            * repayment is locked for 30 days
          </div>
          <div className="flex justify-center my-[44px]">
            <button
              className="px-[62px] py-[13px] text-[#ffffff] font-medium text-[14px] leading-[14px] rounded-[5px] bg-[#2DB49E]"
              onClick={() => {
                onTriggerLafModal();
              }}
            >
              Confirm
            </button>
          </div>
        </div>

        <LafModal
          shown={modalShown}
          onAcceptingTerms={confirmLoan}
          onRejectTerms={cancelLoan}
          isloading={isLoading}
          close={() => {
            toggleModal(false);
          }}
        />
      </>
    );
  } else {
    return <></>;
  }
}

export default PaymentOption;
